<div class="container p-3">
  <div class="addbtn p-3">
    <!-- <button mat-icon-button color="primary" class="btn btn-success btn-lg float-right" (click)="openAddForm()"> 
            </button> -->
    <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openAddForm()">
      Add User
    </button>

    <!-- <label>Add</label> -->
    <!-- <mat-icon>add cutomer</mat-icon> -->
  </div>

  <div class="row pt-3 pb-3 justify-content-md-center">
    <div class="col">
      <input type="search" (input)="handleInput($event)" class="form-control" placeholder="Filter"
        aria-label="Search" />
    </div>

  </div>

  <mat-table [dataSource]="dataSource" matSort>
    <!-- Position Column -->

    <!-- Name Column -->
    <ng-container matColumnDef="FirstName">
      <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.firstName }}</mat-cell>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="LastName">
      <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
      <!-- <td mat-cell *matCellDef="let element" (click)="cellClicked(element)"> {{element.lastName}} </td> -->
      <mat-cell *matCellDef="let element">{{ element.lastName }}</mat-cell>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="User Type">
      <mat-header-cell *matHeaderCellDef>User Type</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.userType }}
        <!-- <mat-icon (click)="getUserRoles()">info</mat-icon> -->
      </mat-cell>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="EMail">
      <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.emailId }}</mat-cell>
    </ng-container>

    <!-- Symbol Column -->

    <!-- Symbol Column -->
    <ng-container matColumnDef="City">
      <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.city }}</mat-cell>
    </ng-container>

    <!-- Symbol Column -->

    <!-- Symbol Column -->
    <ng-container matColumnDef="Mobile">
      <mat-header-cell *matHeaderCellDef>Mobile</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.mobilePhone }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element" [style.color]="element.inactive == true ? 'gray' : null">
        {{ element.inactive ? "In Active" : "Active" }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button style="color:#8e0101;" (click)="openEditForm(row)" matTooltip="Edit Form"
          matTooltipPosition="below">
          <mat-icon>edit</mat-icon>
        </button>
        <!-- <button mat-icon-button color="primary" (click)="openAddRolesForm()">
                    <mat-icon>add</mat-icon>
                  </button> -->
        <!-- <button mat-icon-button color="primary" (click)="openAddRolesForm()">
                    <mat-icon>add</mat-icon>
                  </button> -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="managerole">
      <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>Manage
        Role</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button style="color:#8e0101;" (click)="getUserRoles(row.id)" matTooltip="Manage Roles"
          matTooltipPosition="below">
          <mat-icon>info</mat-icon>
        </button>
        <!-- <button mat-icon-button color="primary" (click)="openAddRolesForm()">
                    <mat-icon>add</mat-icon>
                  </button> -->
        <!-- <button mat-icon-button color="primary" (click)="openAddRolesForm()">
                    <mat-icon>add</mat-icon>
                  </button> -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="checkPrivileges">
      <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>
        Check Privileges
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button style="color:#8e0101;" (click)="openCheckPrivileges(row.id)"
          matTooltip="View Privileges" matTooltipPosition="below">
          <mat-icon>check_circle_outline</mat-icon>
        </button>
        <!-- <button mat-icon-button color="primary" (click)="openAddRolesForm()">
                    <mat-icon>add</mat-icon>
                  </button> -->
        <!-- <button mat-icon-button color="primary" (click)="openAddRolesForm()">
                    <mat-icon>add</mat-icon>
                  </button> -->
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="manageReportee">
      <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>
        Manage Reportee
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button style="color:#8e0101;" (click)="openManageReportee(row.id)" matTooltip="Manage Reportee"
          matTooltipPosition="below">
          <mat-icon>person_add</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="manageOrg">
      <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>
        Manage User Org
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button style="color:#8e0101;" (click)="openManageUserOrg(row)" matTooltip="Manage User Org"
          matTooltipPosition="below">
          <mat-icon>business</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator #paginator [length]="dataSource.data.length" [pageSizeOptions]="[20,30,50,100,200]"   showFirstLastButtons></mat-paginator>
  <!-- <div class="demo-options ">
    <div class="demo-toggles">
      <br>
      <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="length"
        [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
    </div>
  </div> -->
  <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
    {{ message }}
  </div>
</div>