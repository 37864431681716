import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first, take } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/authService';
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-add-kyp',
  templateUrl: './add-kyp.component.html',
  styleUrls: ['./add-kyp.component.scss']
})
export class AddKypComponent implements AfterViewInit {
  @Input() src:any;
  kypForm: FormGroup;
  commentsForm: FormGroup;
  memberForm: FormGroup;
  sublimitinformationform: FormGroup;
  InsuredwaitingperiodForm: FormGroup;
  userorg: any
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  message: any;
  selectedType: any;
  error: any;
  orgs: any;
  partnerTypes: any;
  selectedOrgsTypeId: any;
  selectedProductTypeId: any;
  selectedPartnerTypeId: any;
  user: any;
  progress = 0;
  selectedIndex = 0;
  results:any;
  healthFiles:any;
  selectedValue:any;
  currentFile?: File;
  selectedFiles?: FileList;
  // uploadedPolicy = new FormControl('', [Validators.required]);
  uploadForm = new FormGroup({
    uploadedPolicy: new FormControl(null, Validators.required)
  });


  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private toastrService: ToastrService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    


    private _dialogRef: MatDialogRef<AddKypComponent>,

  ) {
   
    console.log(" selected user id", data.data);
    console.log("Policy file:"+JSON.stringify(data.policyFile));
    this.kypForm = this._fb.group({
      // id: '',
      policynumber: ['', Validators.required],
     // policyname: ['', [Validators.required,Validators.pattern(/^(([A-Za-z]+)(\s[A-Za-z]+)?)$/gm) ]],
      policyname: ['', [Validators.required,Validators.maxLength(300)]],
      policytype: ['', Validators.required],
      insurancename: ['', [Validators.required, Validators.maxLength(300)]],
      //insurancename: ['', [Validators.required,Validators.pattern(/^(([A-Za-z]+)(\s[A-Za-z]+)?)$/gm)]],
      membername: ['', Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],
      insurancecompany: ['', Validators.required],
      // orgId:'',
      //suminsured: ['', Validators.required],
      renewalinformation: ['', Validators.required],
      ported: ['', Validators.required],



    });

    this.commentsForm = this._fb.group({
      // name: ['', Validators.required],
      // comment :['',Validators.required],
      submitcomment: ['', Validators.required],
      additionalcomment: ['', Validators.required],
      expertcomments: ['', Validators.required],
      coveragecomments: ['', Validators.required],
      waitingperiodcomments: ['', Validators.required],
    });

    this.memberForm = this._fb.group({
      // name: ['', Validators.required],
      // comment :['',Validators.required],
      insuredname: ['', Validators.required],
      suminsured: ['', Validators.required],

      superbonus: ['', Validators.required],
      bonus: ['', Validators.required],
      restore: ['', Validators.required],
      effectivecoverage: ['', Validators.required],
      dob: ['', Validators.required],

    });
    this.InsuredwaitingperiodForm = this._fb.group({
      // name: ['', Validators.required],
      // comment :['',Validators.required],
      insuredname: ['', Validators.required],
      prehospitalisation: ['', Validators.required],
      posthospitalisation: ['', Validators.required],
      waitingperiod: ['', Validators.required],
    });

    this.sublimitinformationform = this._fb.group({
      insuredname: ['', Validators.required],
      //sublimit : ['', Validators.required],
      roomrent: ['', Validators.required],
      icuICCU: ['', Validators.required]


      //sublimit: this._fb.array([this.sublimitFrom()])
      // sublimit: this._fb.group({

      // })

    })


  }

  ngAfterViewInit() {
    // Optional: Check if tabGroup is initialized here for safety
    // if (this.tabGroup) {
    //   // Perform actions using tabGroup after it's available
    // }
  }

  sublimitFrom() {
    return this._fb.group(
      {
        roomrent: ['', Validators.required],
        icu_ICCU: ['', Validators.required],
      }
    );
  }

  get sublimit() {
    return this.sublimitinformationform.get("sublimit") as FormArray;
  }


  ngOnInit(): void {
    this.user = this.authService.userValue;
    //this.getHealthCloudFilesByUser(this.data.data);

  }

  uploadHealthCloudFiles(): void {
    this.progress = 0;
    
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
     // this.uploadForm.patchValue(this.selectedFiles?.item(0));

      if (file) {
        this.currentFile = file;

        this.customerService
          .uploadHealthFilesForUser(
            this.currentFile,
            this.user.id,
            'POLICY',
            'POLICY'
          )
          .subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);

              } else if (event instanceof HttpResponse) {
                this.message = event.body.message;
                console.log(event.body.filePath);
                //this.uploadForm.patchValue(event.body.filePath);

                this.uploadForm.get('uploadedPolicy')?.setValue(event.body.filePath)
               // window.location.reload();
              }
             
              // if(this.progress = 100){
              
              // console.log(event.body.filePath);
              // }
              
              // this.uploadForm.get('uploadedPolicy')?.setValue()
            },
            error: (err: any) => {
              console.log(err);
              this.progress = 0;
              if (err.error && err.error.error) {
                this.error = err.error.error;
              } else {
                err = "Could not upload the file!";
              }

              this.currentFile = undefined;
            },
           
          }
        );
      }

      this.selectedFiles = undefined;
    }
    //console.log("here", this.selectedCategory);
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  
  changeTab(tabIndex: number) {
    if(this.uploadForm.valid){
      this.selectedIndex=0;
      // console.log(this.uploadedPolicy.value);
      this.selectedIndex = tabIndex;
      this.changeDetectorRef.detectChanges(); 
      console.log(this.uploadForm.value.uploadedPolicy);
      // this.selectedIndex=0;
    }else{
      this.toastrService.error("Please select or upload a policy ")
    }
   
  }

  onTabChange(event: any) {
    // this.selectedIndex=0;
   // console.log(event);
    this.selectedIndex=event;
    
  }
  
 
  async getHealthCloudFilesByUser(userId:any) {
    console.log(userId);
   
    this.customerService.getHealthFilesByUserIdForAgent(userId).pipe(take(4))
      .subscribe({
        next:  async (data: any) => {
          this.healthFiles = data;
          this.results = data;
          this.error = undefined;
          console.log("data", this.results.healthCloudModelFiles);
          
        },
        error: async error => {
          this.error = error.error;
          console.log(error);
          // await this.loader.hideLoader();
        }
      });
  }

kyp_data:any
  onFormSubmit() {
    console.log(this.sublimitinformationform.getRawValue());
    console.log(this.sublimitinformationform.value.sublimit);
    console.log(this.uploadForm.value.uploadedPolicy);
    
    var unprocessedPolicyFile = this.data.fileMetaData;
    console.log(unprocessedPolicyFile)
    if(this.data.fileType == 'Processing Failed'){
      console.log("Processing Failed");
     this. kyp_data =
      {
  
        "id": unprocessedPolicyFile?.id || 0,
  
        user_id: this.data.userId,
        "policyPath":unprocessedPolicyFile.policyPath ,
        "processed":true,
  
        "member_name": this.kypForm.value.membername,
  
        "ekypData": {
  
          "start_date": this.datePipe.transform(this.kypForm.value.startdate, 'MMM dd yyyy'),
          //"start_date":this.kypForm.value.startdate,
  
  
          "end_date": this.datePipe.transform(this.kypForm.value.enddate, 'MMM dd yyyy'),
  
          "insurance_company": this.kypForm.value.insurancecompany,
  
          "insurance_name": this.kypForm.value.insurancename,
  
          "ported": this.kypForm.value.ported,
  
          "policy_type": this.kypForm.value.policytype,
  
          "policy_name": this.kypForm.value.policyname,
  
          "policy_number": this.kypForm.value.policynumber,
  
          "coverage_information": [
  
            {
  
              "insured_name": this.memberForm.value.insuredname,
  
              "sum_insured": this.memberForm.value.sumInsured,
  
              "super_bonus": this.memberForm.value.superbonus,
  
              "bonus": this.memberForm.value.bonus,
  
              "restore": this.memberForm.value.restore,
  
              "effective_coverage": this.memberForm.value.effectivecoverage,
  
              "dob": this.memberForm.value.dob?this.datePipe.transform(this.memberForm.value.dob, 'MMM dd yyyy'):'',
  
            }
  
          ],
  
          "waiting_period_information": [
  
            {
  
              "insured_name": this.InsuredwaitingperiodForm.value.insuredname,
  
              "pre_hospitalisation": this.InsuredwaitingperiodForm.value.prehospitalisation,
  
              "post_hospitalisation": this.InsuredwaitingperiodForm.value.posthospitalisation,
  
              "waiting_period": this.InsuredwaitingperiodForm.value.waitingperiod
  
            }
  
          ],
  
          "sublimit_information": [
  
            {
  
              "insured_name": this.sublimitinformationform.value.insuredname,
  
              "sublimit": [
  
                {
  
                  "name": "Room Rent",
  
                  "comment": this.sublimitinformationform.value.roomrent
  
                },
  
                {
  
                  "name": "ICU/ICCU",
  
                  "comment": this.sublimitinformationform.value.icuICCU
                }
  
              ]
  
            }
  
          ],
  
          "renewal_information": this.kypForm.value.renewalinformation,
  
          "comments": [
  
            {
  
              "name": "Submit Comment",
  
              "comment": this.commentsForm.value.submitcomment
  
            },
  
            {
  
              "name": "Additional Comment",
  
              "comment": this.commentsForm.value.additionalcomment
  
            },
  
            {
  
              "name": "Expert Comments",
  
              "comment": this.commentsForm.value.expertcomments
  
            },
  
            {
  
              "name": "Coverage Comments",
  
              "comment": this.commentsForm.value.coveragecomments
  
            },
  
            {
  
              "name": "Waiting Period Comments",
  
              "comment": this.commentsForm.value.waitingperiodcomments
  
            }
  
          ],
         
  
        }
  
      }
      console.log(this.kyp_data);
    }else if(this.data.policyFile =='CASHLESS'){
      console.log(this.data.data.userId);
      console.log('yes');
      this. kyp_data =
      {
  
        "id": 0,
  
        user_id: this.data.data.userId ,
  
        "policyPath":'' ,
       
        "processed":true,
        "ekypData": {
  
          "start_date": this.datePipe.transform(this.kypForm.value.startdate, 'MMM dd yyyy'),
          //"start_date":this.kypForm.value.startdate,
  
  
          "end_date": this.datePipe.transform(this.kypForm.value.enddate, 'MMM dd yyyy'),
  
          "insurance_company": this.kypForm.value.insurancecompany,
  
          "insurance_name": this.kypForm.value.insurancename,
  
          "ported": this.kypForm.value.ported,
  
          "policy_type": this.kypForm.value.policytype,
  
          "policy_name": this.kypForm.value.policyname,
  
          "policy_number": this.kypForm.value.policynumber,
  
          "coverage_information": [
  
            {
  
              "insured_name": this.memberForm.value.insuredname,
  
              "sum_insured": this.memberForm.value.sumInsured,
  
              "super_bonus": this.memberForm.value.superbonus,
  
              "bonus": this.memberForm.value.bonus,
  
              "restore": this.memberForm.value.restore,
  
              "effective_coverage": this.memberForm.value.effectivecoverage,
  
              "dob": this.memberForm.value.dob?this.datePipe.transform(this.memberForm.value.dob, 'MMM dd yyyy'):'',
  
            }
  
          ],
  
          "waiting_period_information": [
  
            {
  
              "insured_name": this.InsuredwaitingperiodForm.value.insuredname,
  
              "pre_hospitalisation": this.InsuredwaitingperiodForm.value.prehospitalisation,
  
              "post_hospitalisation": this.InsuredwaitingperiodForm.value.posthospitalisation,
  
              "waiting_period": this.InsuredwaitingperiodForm.value.waitingperiod
  
            }
  
          ],
  
          "sublimit_information": [
  
            {
  
              "insured_name": this.sublimitinformationform.value.insuredname,
  
              "sublimit": [
  
                {
  
                  "name": "Room Rent",
  
                  "comment": this.sublimitinformationform.value.roomrent
  
                },
  
                {
  
                  "name": "ICU/ICCU",
  
                  "comment": this.sublimitinformationform.value.icuICCU
                }
  
              ]
  
            }
  
          ],
  
          "renewal_information": this.kypForm.value.renewalinformation,
  
          "comments": [
  
            {
  
              "name": "Submit Comment",
  
              "comment": this.commentsForm.value.submitcomment
  
            },
  
            {
  
              "name": "Additional Comment",
  
              "comment": this.commentsForm.value.additionalcomment
  
            },
  
            {
  
              "name": "Expert Comments",
  
              "comment": this.commentsForm.value.expertcomments
  
            },
  
            {
  
              "name": "Coverage Comments",
  
              "comment": this.commentsForm.value.coveragecomments
  
            },
  
            {
  
              "name": "Waiting Period Comments",
  
              "comment": this.commentsForm.value.waitingperiodcomments
  
            }
  
          ],
         
  
        }
  
      }
    }
    else if(this.data.fileType == 'Reimbursement'){
      console.log(this.data);
      this. kyp_data =
      {
  
        "id": this.data.data.id,
  
        user_id: this.data.data.userId ,
  
        "policyPath":this.data.data.policyPath ,
       
        "processed":true,
        "ekypData": {
  
          "start_date": this.datePipe.transform(this.kypForm.value.startdate, 'MMM dd yyyy'),
          //"start_date":this.kypForm.value.startdate,
  
  
          "end_date": this.datePipe.transform(this.kypForm.value.enddate, 'MMM dd yyyy'),
  
          "insurance_company": this.kypForm.value.insurancecompany,
  
          "insurance_name": this.kypForm.value.insurancename,
  
          "ported": this.kypForm.value.ported,
  
          "policy_type": this.kypForm.value.policytype,
  
          "policy_name": this.kypForm.value.policyname,
  
          "policy_number": this.kypForm.value.policynumber,
  
          "coverage_information": [
  
            {
  
              "insured_name": this.memberForm.value.insuredname,
  
              "sum_insured": this.memberForm.value.sumInsured,
  
              "super_bonus": this.memberForm.value.superbonus,
  
              "bonus": this.memberForm.value.bonus,
  
              "restore": this.memberForm.value.restore,
  
              "effective_coverage": this.memberForm.value.effectivecoverage,
  
              "dob": this.memberForm.value.dob?this.datePipe.transform(this.memberForm.value.dob, 'MMM dd yyyy'):'',
  
            }
  
          ],
  
          "waiting_period_information": [
  
            {
  
              "insured_name": this.InsuredwaitingperiodForm.value.insuredname,
  
              "pre_hospitalisation": this.InsuredwaitingperiodForm.value.prehospitalisation,
  
              "post_hospitalisation": this.InsuredwaitingperiodForm.value.posthospitalisation,
  
              "waiting_period": this.InsuredwaitingperiodForm.value.waitingperiod
  
            }
  
          ],
  
          "sublimit_information": [
  
            {
  
              "insured_name": this.sublimitinformationform.value.insuredname,
  
              "sublimit": [
  
                {
  
                  "name": "Room Rent",
  
                  "comment": this.sublimitinformationform.value.roomrent
  
                },
  
                {
  
                  "name": "ICU/ICCU",
  
                  "comment": this.sublimitinformationform.value.icuICCU
                }
  
              ]
  
            }
  
          ],
  
          "renewal_information": this.kypForm.value.renewalinformation,
  
          "comments": [
  
            {
  
              "name": "Submit Comment",
  
              "comment": this.commentsForm.value.submitcomment
  
            },
  
            {
  
              "name": "Additional Comment",
  
              "comment": this.commentsForm.value.additionalcomment
  
            },
  
            {
  
              "name": "Expert Comments",
  
              "comment": this.commentsForm.value.expertcomments
  
            },
  
            {
  
              "name": "Coverage Comments",
  
              "comment": this.commentsForm.value.coveragecomments
  
            },
  
            {
  
              "name": "Waiting Period Comments",
  
              "comment": this.commentsForm.value.waitingperiodcomments
  
            }
  
          ],
         
  
        }
  
      }
    }
    else{
      console.log("src data:"+JSON.stringify(this.src));
      this. kyp_data =
      {
  
        "id": 0,
  
        user_id: this.src.userId ,
  
        "policyPath":this.src.fileMetaData.policyPath ,
       
        "processed":true,
        "ekypData": {
  
          "start_date": this.datePipe.transform(this.kypForm.value.startdate, 'MMM dd yyyy'),
          //"start_date":this.kypForm.value.startdate,
  
  
          "end_date": this.datePipe.transform(this.kypForm.value.enddate, 'MMM dd yyyy'),
  
          "insurance_company": this.kypForm.value.insurancecompany,
  
          "insurance_name": this.kypForm.value.insurancename,
  
          "ported": this.kypForm.value.ported,
  
          "policy_type": this.kypForm.value.policytype,
  
          "policy_name": this.kypForm.value.policyname,
  
          "policy_number": this.kypForm.value.policynumber,
  
          "coverage_information": [
  
            {
  
              "insured_name": this.memberForm.value.insuredname,
  
              "sum_insured": this.memberForm.value.sumInsured,
  
              "super_bonus": this.memberForm.value.superbonus,
  
              "bonus": this.memberForm.value.bonus,
  
              "restore": this.memberForm.value.restore,
  
              "effective_coverage": this.memberForm.value.effectivecoverage,
  
              "dob": this.memberForm.value.dob?this.datePipe.transform(this.memberForm.value.dob, 'MMM dd yyyy'):'',
  
            }
  
          ],
  
          "waiting_period_information": [
  
            {
  
              "insured_name": this.InsuredwaitingperiodForm.value.insuredname,
  
              "pre_hospitalisation": this.InsuredwaitingperiodForm.value.prehospitalisation,
  
              "post_hospitalisation": this.InsuredwaitingperiodForm.value.posthospitalisation,
  
              "waiting_period": this.InsuredwaitingperiodForm.value.waitingperiod
  
            }
  
          ],
  
          "sublimit_information": [
  
            {
  
              "insured_name": this.sublimitinformationform.value.insuredname,
  
              "sublimit": [
  
                {
  
                  "name": "Room Rent",
  
                  "comment": this.sublimitinformationform.value.roomrent
  
                },
  
                {
  
                  "name": "ICU/ICCU",
  
                  "comment": this.sublimitinformationform.value.icuICCU
                }
  
              ]
  
            }
  
          ],
  
          "renewal_information": this.kypForm.value.renewalinformation,
  
          "comments": [
  
            {
  
              "name": "Submit Comment",
  
              "comment": this.commentsForm.value.submitcomment
  
            },
  
            {
  
              "name": "Additional Comment",
  
              "comment": this.commentsForm.value.additionalcomment
  
            },
  
            {
  
              "name": "Expert Comments",
  
              "comment": this.commentsForm.value.expertcomments
  
            },
  
            {
  
              "name": "Coverage Comments",
  
              "comment": this.commentsForm.value.coveragecomments
  
            },
  
            {
  
              "name": "Waiting Period Comments",
  
              "comment": this.commentsForm.value.waitingperiodcomments
  
            }
  
          ],
         
  
        }
  
      }
    }
   

    this.customerService.addkyp(this.kyp_data).subscribe({
      next: (val: any) => {
        console.log("POlicy returned :"+JSON.stringify(val));
        this.toastrService.success("kyp has been added successfully");
        this._dialogRef.close(val);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));


      },
    });

  }
}
