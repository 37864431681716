import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  faCoffee,
  faScrewdriver,
  faUser,
  faLink,
  faPhoneVolume,
  faScrewdriverWrench,
  faVolumeLow,
  faLineChart,
  faMicrochip,
  faHouseMedicalCircleExclamation,
  faShareNodes,
  faNotesMedical,
  faUserShield,
  faHome,
  faHomeUser,
  faUserPlus,
  faUserMinus,
  faMagnifyingGlassChart,
  faClock,
  faFlagCheckered,
  faFilterCircleXmark,

} from "@fortawesome/free-solid-svg-icons";

import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { AuthService } from 'src/app/services/authService';
import { CashflowService } from 'src/app/services/cashflow.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { UserModalComponent } from '../modal/user-modal/user-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AgChartOptions } from 'ag-charts-community';

@Component({
  selector: 'app-business-dashboard',
  templateUrl: './business-dashboard.component.html',
  styleUrl: './business-dashboard.component.scss'
})
export class BusinessDashboardComponent implements OnInit  {
  date2 : any = new Date();
  dateForm2!: FormGroup;
  selectedHospital_BusinessDashboard:any;
  faUser = faUser;
  faLink = faLink;
  faScrewdriver = faScrewdriver;
  faPhoneVolume = faPhoneVolume;
  faScrewdriverWrench = faScrewdriverWrench;
  faVolumeLow =faVolumeLow;
  faLineChart =faLineChart;
  faMicrochip = faMicrochip;
  faHouseMedicalCircleExclamation =faHouseMedicalCircleExclamation;
  faShareNodes =faShareNodes;
  faNotesMedical = faNotesMedical;
  faUserShield = faUserShield;
  faHome=faHome;
  faHomeUser=faHomeUser;
  faUserPlus=faUserPlus;
  faUserMinus=faUserMinus;
  user:any;
  org:any;
  hospitalOrgId: any;
  hospitalOrgId_BusinessDashboard: any;
  dashboardData: any = [];
  hospitalDashboardData : any =[];
  error: any;
  message: any;
  loading: any;
  hospitals: any = [];
  public options: AgChartOptions;
  faMagnifyingGlassChart = faMagnifyingGlassChart;
  faClock = faClock;
  faFlagCheckered = faFlagCheckered;
  faFilterCircleXmark = faFilterCircleXmark;
  
  constructor(
    private cashflowService: CashflowService,
    private dashboardService: DashboardService,
    private toastrsService: ToastrService,
    private authService: AuthService,
    public dialog: MatDialog,
    private _fb: FormBuilder,
  ){
    this.date2.setMonth(this.date2.getMonth() - 3);
  
    this.dateForm2 = this._fb.group({

      fromDate: [this.date2, Validators.required],
    });
    this.options = {
      data: [{ asset: "data", amount: 3 }],
      series: [
        {
          type: 'pie',
          angleKey: 'amount',
          calloutLabelKey: 'asset',
          sectorLabelKey: 'amount',
          sectorLabel: {
            color: 'white',
            fontWeight: 'bold',
          },
        },
      ],
    };

    this.authService.user.subscribe((x: any) => {
      this.user = x;
      //console.log(this.user);
      if (this.user && this.isPrivileged(['ADMIN', 'SUPERADMIN',"IS_HOSPITAL_USER"])) {
       
        this.org = this.user.orgId;
      }
    });

  }

  ngOnInit(): void {
    this.getOnboardedHospitals();
    this.getBusinessDashboard(this.date2.toISOString(), "");
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  onChangeEvent_BusinessDashboard(event: { value: any; }) {
    console.log(event);
    this.date2 = new Date(event.value);
    if(this.hospitalOrgId_BusinessDashboard == undefined || this.hospitalOrgId_BusinessDashboard == 'ALL'){
      this.toastrsService.error("Please select the hospital to view the details")
    }else{
      this.getBusinessDashboard(this.date2.toISOString(), this.hospitalOrgId_BusinessDashboard);
    }
   
    // if (this.hospitalOrgId != "ALL" ) {
     
    // } else  {
    //   this.getDashboard(this.dt.toISOString(), "");
    // }

  }

  hospitalSelected_BusinessDashboard(event: MatSelectChange) {
    console.log(event.value);
    this.hospitalOrgId_BusinessDashboard = event.value;
    if(this.hospitalOrgId_BusinessDashboard == "ALL"){
      this.getBusinessDashboard(this.date2.toISOString(), "");
    }else{
      this.getBusinessDashboard(this.date2.toISOString(), this.hospitalOrgId_BusinessDashboard);
    }
    
  }

  getOnboardedHospitals() {
    this.cashflowService
      .getOnboardedHospitals()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          this.hospitals = data;
          console.log(JSON.stringify(data));
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }


  getBusinessDashboard(dt: any, hospitalOrgId: any) {
    this.dashboardService
      .getDashboard("management", "BG", dt, hospitalOrgId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          this.dashboardData = data.processInstanceData;
          //console.log(JSON.stringify(data));
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }


  calculateAvgDuration(completedInstances: any[]) {
    let totalDuration = 0;
    for (var x = 0; x < completedInstances.length; x++) {
      totalDuration += completedInstances[x].duration;
    }
    if (totalDuration > 0) {
      return (totalDuration / (1000 * completedInstances.length * 60));
    } else {
      return 0;
    }
  }

  performance: any;

  analyze(processName: any) {
    this.dashboardService
      .getUserPerformance(processName, this.date2.toISOString())
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          this.performance = data;
          console.log("Performance " + JSON.stringify(data));
          this.openDialog()
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  openDialog() {
    this.dialog.open(UserModalComponent, {
      disableClose: true,
      data: this.performance,
      width: '80%',
      height: '80%'
    });
  }




}
