import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';


@Component({
  selector: 'app-update-hospital-details',
  templateUrl: './update-hospital-details.component.html',
  styleUrl: './update-hospital-details.component.scss'
})
export class UpdateHospitalDetailsComponent implements OnInit{

  form!: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef : MatDialogRef<UpdateHospitalDetailsComponent>,
    private hospitalRegistrationService: HospitalRegistrationService,
    private toastrService: ToastrService,
  ){
    this.form = this.formBuilder.group({
      emailId:['',Validators.required],
      contactDetail:['',[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      preAuthFilled:['',Validators.required],
      fileDispatchVerification:['',Validators.required],
      claimThroughEmail:['',Validators.required],
      medicalScrutinyApproval:['',Validators.required],
      zone:['']
    });
    this.form.patchValue(this.data);
  }
  ngOnInit(): void {
    
  }

  updateDetails(){
    // let formData = this.form.value;
    // formData = {
    //   ...formData,
    //   hospitalUniqueId : this.data.hospitalUniqueId,
    //   flagged : this.data.flagged,
    //   currentOutstanding: this.data.currentOutstanding
    // }

    let formData = this.data;
    formData.emailId = this.form.value.emailId;
    formData.contactDetail = this.form.value.contactDetail;
    formData.preAuthFilled = this.form.value.preAuthFilled;
    formData.fileDispatchVerification = this.form.value.fileDispatchVerification;
    formData.claimThroughEmail = this.form.value.claimThroughEmail;
    formData.medicalScrutinyApproval = this.form.value.medicalScrutinyApproval;

    console.log(formData);
    this.hospitalRegistrationService
    .updateHospitalDetails(this.data.hospitalUniqueId,formData)
    .subscribe({
      next: (data: any) => {
        this.toastrService.success('Hosital Details have been updated!')
        console.log("Data updated", data);
        this.dialogRef.close(data);
      },
      error: (error) => {
        console.log("Error", error);
      }
    });
  }

  

}
