import { DataSource } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { AuthService } from 'src/app/services/authService';
import { CashflowService } from 'src/app/services/cashflow.service';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'app-manage-reimbursement-cases',
  templateUrl: './manage-reimbursement-cases.component.html',
  styleUrl: './manage-reimbursement-cases.component.scss'
})
export class ManageReimbursementCasesComponent implements OnInit, AfterViewInit{

  
  user:any;
  
  caseList:any = [];
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  dataSource = new MatTableDataSource<any>(this.caseList);
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  displayedColumns = ['processInstanceId','productName','customerName','transactionDate','action'];
  constructor(
    public cashlessService:CashflowService,
    public authService:AuthService,
    private workflowService:WorkflowService,
    private toastrService: ToastrService
  ){
    this.authService.user.subscribe((x: any) => {
      this.user = x;
      
    });
   

  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    
    this.getCaseList('','','');
  }

  searchCases(){
  
    console.log(this.dateRange?.value.start);
    console.log(this.dateRange?.value.end);
    if(this.dateRange.value.start == null ){
      this.getCaseList('','','');
    }else{
     this.getCaseList('',this.dateRange?.value.start.toISOString(),this.dateRange?.value.end.toISOString());
    }
    
  }

  clear(){
    this.dateRange.reset();
    this.getCaseList('','','');
  }

  reinitiateProcess(element:any){
    this.workflowService.reinitiateReimbursementProcess(element.process_inst_id) 
    .subscribe({
      next:(data:any)=>{
        console.log("Reinitiated process : "+JSON.stringify(data));
        this.toastrService.success(data.message);
        this.getCaseList('','','');
      },
      error:(error:any)=>{
        this.toastrService.error(JSON.stringify(error));
      }
    })
   }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    return dd;
  }
  

  getCaseList(hospitalOrgId:any, startDate:any, endDate:any){
    this.cashlessService.getCaseList('reimbursementProcessHobnob',hospitalOrgId,startDate,endDate)
    .pipe(first())
    .subscribe({
      next:(cases:any)=>{
        console.log("cases " + JSON.stringify(cases));
        this.caseList = cases;
        this.dataSource.data = this.caseList;
       
      },
      error:(error:any)=>{
        console.log("error " + JSON.stringify(error));
      }
     })
  }
  

}
