import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { ShopService } from 'src/app/services/shop.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { EditStoreProductComponent } from './edit-store-product/edit-store-product.component';
import { MatDialog } from "@angular/material/dialog";
import { AddStoreProductComponent } from './add-store-product/add-store-product.component';
import Swal from "sweetalert2";


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements AfterViewInit {
  [x: string]: any;
  displayedColumns: string[] = ['image', 'name', 'type', 'description', 'short_description', 'regular_price', 'sale_price','date_on_sale_from', 'date_on_sale_to','stock_quantity', 'action',    "inactivate",
  ];
  couponForm: FormGroup;
  change: any;
  categories: any;
  tags: any;


  ngAfterViewInit() {
  }
  constructor(private shopService: ShopService, private _fb: FormBuilder, private toastrService: ToastrService, private adminService: AdminService, private _dialog: MatDialog,


  ) {
    this.couponForm = this._fb.group({
      // id: '',
      productName: ['', Validators.required],
      discountCode: ['', [Validators.required, Validators.maxLength(10)]],
      discountPercent: ['', Validators.required],
      discountNote: ['', [Validators.required, Validators.maxLength(300)]],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      maxCount: ['', Validators.required],
      partnerTypeId: ['', Validators.required],
      // orgId:'',
      orgId: ['', Validators.required],
    });
    this.getProducts();
  }
  ngOnInit(): void {
    // this.couponForm.patchValue(this.data);
    // this.getcategories();
    // this.gettages();

  }

  length = 100;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 50, 100];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  loading = false;
  message = undefined;
  error = undefined;
  submitted = false;
  products: any = [];

  pageEvent: PageEvent = new PageEvent;
  dataSource = new MatTableDataSource<any>(this.products);
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getProducts();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }


  getProducts() {
    this.loading = true;
    this.shopService.getProducts(this.pageIndex, this.length).pipe(first())
      .subscribe({
        next: (data: any) => {

          this.loading = false;
          //console.log(data);
          this.products = data;
          this.error = undefined;
          this.submitted = false;
          this.couponForm.patchValue(this.data);

        },
        error: error => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        }
      });
  }


  getcategories() {
    this.loading = true;
    this.shopService.getCategory(this.pageIndex, this.length).pipe(first())
      .subscribe({
        next: (data: any) => {

          this.loading = false;
          //console.log("cat",data);
          this.categories = data;
          this.error = undefined;

        },
        error: error => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        }
      });
  }


  gettages() {
    this.loading = true;
    this.shopService.getTags(this.pageIndex, this.length).pipe(first())
      .subscribe({
        next: (data: any) => {

          this.loading = false;
          //console.log("tags",data);
          this.tags = data;
          this.error = undefined;
          this.submitted = false;

        },
        error: error => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        }
      });
  }

  
  handleInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.products = query === ""? this.products : this.products.filter((item:any) =>
      item.name.toLowerCase().includes(query.toLowerCase()));
    } 
    else {    
      this.getProducts();
      }    
     
  }


  deleteProduct(data: any) {
      console.log("fg", data);
  
      Swal.fire({
        title: "Are you sure want to delete this Product?",
        //text: 'You will not be able to recover this imaginary file!',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result:any) => {
        if (result.isConfirmed) {
          this.shopService
            .deleteProduct(data.id)
            .pipe(first())
            .subscribe({
              next: (data: any) => {
                this.toastrService.success(
                  "Product has been deleted successfully"
                );
  
                //this.loading = false;
                this.getProducts();
                //console.log("roles",data);
                // this.roles = data;
              },
              error: (error) => {
                console.log("error " + JSON.stringify(error));
                // this.toastrService.error("Error");
                this.toastrService.error(JSON.stringify(error.error.error));
  
                this.message = error;
  
                //sthis.loading = false;
              },
            });
  
          // console.log('Clicked Yes, File deleted!');
        } else if (result.isDismissed) {
          //console.log('Clicked No, File is safe!');
        }
      });
    }


  openEditForm(data: any) {
    //console.log(data);
    const dialogRef = this._dialog.open(EditStoreProductComponent, {
      disableClose:true,
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
          this.getProducts();
        }
      },
    });
  }
  // onFormSubmit() {
  //   // {
  //   //   "id": "string",
  //   //   "productId": "string",
  //   //   "discountCode": "string",
  //   //   "discountPercent": 0,
  //   //   "discountNote": "string",
  //   //   "startDate": "2023-11-01T10:04:54.794Z",
  //   //   "endDate": "2023-11-01T10:04:54.795Z",
  //   //   "maxCount": 0,
  //   //   "partnerTypeId": 0,
  //   //   "orgId": "string",
  //   //   "dateCreated": "2023-11-01T10:04:54.795Z",
  //   //   "createdBy": "string",
  //   //   "inactive": true,
  //   //   "currentCount": 0
  //   // }
  //   // var orgId: "741cdabe-4c9b-4892-9326-e12b424bafb5";
  //   //console.log(this.datePipe.transform(this.couponForm.value.startDate,"yyyy-MM-dd")); //output : 2018-02-13
  //   var data =

  //   {
  //     //"id": this.couponForm.value.id,
  //     //"productId": this.selectedProductTypeId,
  //     "discountCode": this.couponForm.value.discountCode,
  //     "discountPercent": this.couponForm.value.discountPercent,
  //     "discountNote": this.couponForm.value.discountNote,
  //      "startDate": this.couponForm.value.startDate,
  //      "endDate": this.couponForm.value.endDate,
  //     //"startDate": "2023-11-01T10:04:54.794Z",
  //     //"endDate": "2023-11-01T10:04:54.795Z",
  //     // "startDate": this.datePipe.transform(this.couponForm.value.startDate, "yyyy-MM-dd"),
  //     // "endDate": this.datePipe.transform(this.couponForm.value.endDate, "yyyy-MM-dd"),
  //     //"startDate": this.datePipe.transform(this.couponForm.value.startDate, "yyyy-MM-dd"),
  //     //"endDate": this.datePipe.transform(this.couponForm.value.endDate, "yyyy-MM-dd"),
  //     "maxCount": this.couponForm.value.maxCount,
  //     "partnerTypeId": this.couponForm.value.partnerTypeId,
  //     "orgId": this.couponForm.value.orgId,
  //     "dateCreated": "2023-11-01T10:04:54.795Z",
  //     "createdBy": "",
  //     "inactive": true,
  //     "currentCount": 0


  //   }
  //   this.adminService.addDiscountCoupon(data).subscribe({
  //     next: (val: any) => {
  //       this.toastrService.success("Coupon has been added successfully");
  //      // this._dialogRef.close(true);
  //     },
  //     error: (err: any) => {
  //       console.error(err);
  //       this.toastrService.error("Error");

  //     },
  //   });


  // }

  test(a: { index: any; }) {
    //console.log(a.index)
    this.change = a.index

  }

  openAddForm() {
    const dialogRef = this._dialog.open(AddStoreProductComponent,{disableClose:true,});
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }
}
