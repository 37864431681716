<mat-dialog-content class="mat-typography">
    <div class="container py-1">
      <div mat-dialog-title>
        <div class="row m-2 justify-content-md-center">
          <div class="col p-3">
            <div class="card">
              <div class="card-header text-center bg-light">Add Recipients</div>
              <div class="card-body">
                <div class="row m-2 justify-content-md-center">
                    <button mat-flat-button type="button" style="color: white; background: #8e0101; "  (click)="getRecipientTemplate()">Download Template</button>
                </div>

                <div class="row m-2 justify-content-md-center">
                    <label class="btn btn-default p-0">
                      <input
                      class="form-control"
                        type="file"
                        accept=".csv"
                        [(ngModel)]="selectedFiles"
                        (change)="selectFile($event)"
                      />
                    </label>
                </div>
                <div class="row pt-2">
                    <div class="col text-end">
                      <button
                        class="btn btn-success btn-sm"
                        [disabled]="selectedFiles == null"
                        (click)="uploadFile()"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="accent" mat-button (click)="close()" >Close</button>
  </mat-dialog-actions>
  