import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

const USER_KEY = "user";
@Injectable({ providedIn: 'root' })
export class ShopService {


  constructor(
    private router: Router,
    private http: HttpClient
  ) {

  }
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  requestOptions = { headers: this.headers };

  getRetailOrders(rowNum: number, size: number) {
    return this.http.get<any>(`${environment.API_URL}/store/vendorOrders?row=` + rowNum + `&perPage=` + size, this.requestOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  getProducts(page:number, perPage:number){
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http.get<any>(`${environment.API_URL}/store/products?row=`+page+`&perPage=`+perPage, this.requestOptions)
    .pipe(
      catchError(this.errorHandle)
    );
  }

  getCategory(page:number, perPage:number){
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http.get<any>(`${environment.API_URL}/store/categories?page=`+page+`&perPage=`+perPage, this.requestOptions)
    .pipe(
      catchError(this.errorHandle)
    );
  }


  getTags(page:number, perPage:number){
    //https://stage.bimagarage.comtcat/bg-server/rest/auth/users
    return this.http.get<any>(`${environment.API_URL}/store/tags?page=`+page+`&perPage=`+perPage, this.requestOptions)
    .pipe(
      catchError(this.errorHandle)
    );
  }
  updateProduct(id: number, data: any) {
    return this.http.put<any>(`${environment.API_URL}/store/products/`+id, data)
      .pipe(map(data => {
        return data;
      }));
  }

  addProduct(data: any) {
    return this.http.post<any>(`${environment.API_URL}/store/products`, data)
      .pipe(map(data => {
        return data;
      }));
  }

  deleteProduct(Id: string) {
    return this.http
      .delete<any>(`${environment.API_URL}/store/products/` + Id)
      .pipe(catchError(this.errorHandle));
  }

  errorHandle(error: any) {
    let errorMessage = '';
    if (error.error.error instanceof ErrorEvent) {
      errorMessage = error.error.error;
    } else {
      errorMessage = error.error;
    }
    
    return throwError(() => {
      return errorMessage;
    });
  }
}