import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AdminService } from "src/app/services/admin.service";
import { ToastrService } from "ngx-toastr";
import { ShopService } from "src/app/services/shop.service";
import { first } from "rxjs";

@Component({
  selector: "app-add-store-product",
  templateUrl: "./add-store-product.component.html",
  styleUrls: ["./add-store-product.component.scss"],
})
export class AddStoreProductComponent {
  prductForm: FormGroup;
  ProductId: any;
  user: any;
  categories: any;
  length = 100;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 50, 100];
  selectedType: any;
  selectedCategory: any;
  sale_price:any;
  // img =  '../../../../assets/img/claims-service-small.jpg'
  //img =  'claims-service-small.jpg'
  todayDate:Date = new Date();

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private shopService: ShopService,
    private _dialogRef: MatDialogRef<AddStoreProductComponent>
  ) // @Inject(MAT_DIALOG_DATA) public orgId: any,

  //private _coreService: CoreService
  {
    this.prductForm = this._fb.group({
      id: "",
      name: ["", Validators.required],
      type: ["", Validators.required],
      description: ["", Validators.required],
      short_description: ["", Validators.required],
      skuId: ["", Validators.required],
      regular_price: ["", [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      sale_price: [""],
      stock_quantity: ["", [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      images: ["", Validators.required],
      categories: ["", Validators.required],
      tags: ["", Validators.required],
      date_on_sale_from: [],
      date_on_sale_to: [],
      manage_stock: [],
      category: [],

      // "images": [
      //   {
      //     "src": "string"
      //   }
    });
  }

  ngOnInit(): void {
    // this.prductForm.patchValue(this.data);
    //this.ProductId = this.data.id;
    this.getcategories();

    this.prductForm.get('sale_price')?.valueChanges.subscribe(value => {
      this.sale_price = value;
      this.checkCondition(this.sale_price); // Call condition checking method
    });
  }

  checkCondition(sale_price: string) {
    if (sale_price == "0") {
      this.prductForm.get('date_on_sale_from')?.disable();
      this.prductForm.get('date_on_sale_to')?.disable();
    } 
    else
     {
      this.prductForm.get('date_on_sale_from')?.enable();
      this.prductForm.get('date_on_sale_to')?.enable();
    }
  }

  getcategories() {
    this.shopService
      .getCategory(this.pageIndex, this.length)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log("cat", data);
          this.categories = data;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  onFormSubmit() {
    // var data = {

    //     "name": "Wireless Heart Health Monitoring",
    //     "type": "simple",
    //     "description": "Frontier X2 Smart Heart Monitor with Chest Strap, Wireless Heart Health Monitoring Device with Heart Strain, Breathing Rate, Heart Rhythm and Fitness Tracking with Continuous Heart Rate Monitoring",
    //     "short_description": "Frontier X2 Smart Heart Monitor with Chest Strap",
    //     "categories": [
    //       {
    //         "id": 34
    //       }
    //     ],
    //     "images": [
    //       {
    //         "src": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_6_front.jpg"
    //       }
    //     ],
    //     "regular_price": "39000",
    //     "sale_price": "29000",
    //     "stock_quantity": 10,
    //     "sku": "p004"

    // }

    var inputImages: any = [];
    var split = this.prductForm.value.images.split(",");
    for (var i = 0; i < split.length; i++) {
      inputImages.push({
        "src": split[i],
      });
    }
    console.log("Image url is :"+JSON.stringify(inputImages));
    console.log("Image url is :"+this.prductForm.value.images);

    var data = {
      "name": this.prductForm.value.name,
      "type": "simple",
      "description": this.prductForm.value.description,
      "short_description": this.prductForm.value.short_description,
      "categories": [
        {
          "id": this.selectedCategory,
          "name": "string",
          "parent": 0
        }
      ],
     "images": inputImages,
      // "images": [
      //         {
      //           "src": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_6_front.jpg"
      //         }
      //       ],
      "tags": [
        {
          "id": 0,
          "name": "string",
          "slug": "string"
        }
      ],
      // "id": 0,
      "regular_price": this.prductForm.value.regular_price,
      "sale_price": this.prductForm.value.sale_price,
      "date_on_sale_from": this.prductForm.value.date_on_sale_from,
      "date_on_sale_to": this.prductForm.value.date_on_sale_to,
      "stock_quantity": this.prductForm.value.stock_quantity,
      "sku":this.prductForm.value.skuId, // this is unique
      "manage_stock": true
    };
    
    

    this.shopService
      // .addProduct(this.prductForm.value)
      .addProduct(data)

      .subscribe({
        next: (val: any) => {
          this.toastrService.success("Product has been added successfully");
          this._dialogRef.close(true);
        },
        error: (err: any) => {
          console.error(err);
          //this.toastrService.error("Error");
          this.toastrService.error(JSON.stringify(err.error));
        },
      });
  }
}
