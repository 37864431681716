import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { CashflowService } from 'src/app/services/cashflow.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ViewUploadFileComponent } from 'src/app/file-upload/view-upload-file/view-upload-file.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit{
  @Input() caseId:any;
  caseDetails:any=[];
  dataSource = new MatTableDataSource<any>(this.caseDetails);
  processId:any;
  constructor(
    private cashlessService: CashflowService,
    private _dialog: MatDialog, 
   
  ) { 
    // this.processId = this.caseId.task.processInstanceId;
    // console.log(this.processId);
    console.log(this.caseId);
  }
  
  ngOnInit(): void {
    console.log(this.caseId);
     this.processId = this.caseId.processInstanceId;
    console.log(this.processId);
    this.getCaseDetails(this.processId); 

    
  }

  
  getCaseDetails(id:any){
    console.log("data "+JSON.stringify(id));
    this.cashlessService.getCaseDetails('cashlessProcess',id)
    .pipe(first())
    .subscribe({
      next:(data:any)=>{
        console.log("data:"+JSON.stringify(data));
        this.caseDetails = data.reverse();
        console.log(this.caseDetails);
        this.dataSource.data = this.caseDetails;
        
      }
    });
     
  }

  toSentenceCase(str: string) {
    return str.replace(/\b\w+\b/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  openViewFileComponent(){
    var input = {
      task: {
        caseVariables:
        {
           hospitalId:this.caseId.caseVariables.hospitalId
        },
        processInstanceId:this.processId
      }
      
    }
    const dialogRef = this._dialog.open(ViewUploadFileComponent, {
      disableClose:true,
      data: input
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        console.log("after closed:" + val);

      },
    });
  }


}
