<div class="container py-3 px-2">
    <div class="row justify-content-md-center">
        
        <div class="col-lg-4">
            <mat-form-field>
                <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
                    <input matStartDate formControlName="start" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div class="col-lg-2 mt-2">
            <div class="d-flex flex-row">
                <button mat-flat-button style="color:white;background:#8e0101" (click)="searchCases()">
                    Search
                </button>
                <button mat-flat-button style="color:white;background:#8e0101" (click)="clear()">
                    Clear
                </button>
            </div>

        </div>
    </div>

    <table mat-table  [dataSource]="dataSource" id="excel-table">
        <ng-container matColumnDef="processInstanceId">
            <th mat-header-cell *matHeaderCellDef>Case Id<th>
            <td mat-cell *matCellDef="let element">{{element.process_inst_id}}</td>
        </ng-container>

        <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef>Product Name</th>
            <td mat-cell *matCellDef="let element">{{element.process_data.product.name}}</td>
        </ng-container>

        <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef>Customer Name</th>
            <td mat-cell *matCellDef="let element">{{element.process_data.customer.firstName}} {{element.process_data.customer.lastName}}</td>
        </ng-container>

        <ng-container matColumnDef="transactionDate">
            <th mat-header-cell *matHeaderCellDef>Transaction Date</th>
            <td mat-cell *matCellDef="let element">{{element.process_data.order.transactionDate | date}}</td>
        </ng-container>

        <!-- <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef>End Date</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.process_data.order.transactionDate | date}}</mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
                <button type="button" class="btn btn-custom" style="color:white;background:#8e0101 ; ;margin-left: 2px;" (click)="reinitiateProcess(element)">
                    Re-initiate
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator #paginator [length]="dataSource.data.length" [pageSizeOptions]="[10,25,50,100]" showFirstLastButtons></mat-paginator>


</div>