<div class="row p-3">
    <form [formGroup]="ordersForm" class="form-class">
        <mat-form-field appearance="fill">
            <mat-label>From date</mat-label>
            <input matInput [matDatepicker]="pickerFrom" formControlName="fromDate" 
                (dateChange)="onFromDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>To date</mat-label>
            <input matInput [matDatepicker]="pickerTo" formControlName="toDate" (dateChange)="onToDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Has Coupons</mat-label>
            <mat-select formControlName="hasCoupon" (valueChange)="onSelectionChange($event)">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Partner Type</mat-label>
            <mat-select formControlName="partnerType" (valueChange)="onPartnerTypeChange($event)">
                <mat-option value="agent">Agent</mat-option>
                <mat-option value="hospital">Hospital</mat-option>
                <mat-option value="doctor">Doctor</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Partner Id</mat-label>
            <mat-select formControlName="partnerId" (valueChange)="onUserSelected($event)">
                <mat-option *ngFor="let user of userList" [value]="user.id">
                    {{ user.firstName }} {{ user.lastName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Org Id</mat-label>
            <mat-select formControlName="orgId" (valueChange)="onHospitalSelected($event)">
                <mat-option *ngFor="let hosp of activeHospitals" [value]="hosp.hospitalOrgId">{{hosp.name}}</mat-option>
            </mat-select>
        </mat-form-field>

    </form>
</div>
<div class="container py-3 px-2">
    <div class="card px-3 py-4 justify-content-md-center ">
    <mat-table #table [dataSource]="dataSource" *ngIf="dataSource.data.length>0">

        <ng-container matColumnDef="transactionDate">
            <mat-header-cell *matHeaderCellDef>Transaction Date</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.transactionDate | date}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="couponCode">
            <mat-header-cell *matHeaderCellDef>Coupon Code</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.couponCode}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="discountPercent">
            <mat-header-cell *matHeaderCellDef>Discount Percent</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.discountPercent}}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="orderSource">
            <mat-header-cell *matHeaderCellDef>Order Source</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.orderSource}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.amount |currency:'INR':'symbol-narrow':'4.2-2' }}</mat-cell>

        </ng-container>

        <ng-container matColumnDef="productName">
            <mat-header-cell *matHeaderCellDef>Product Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.hobnobProduct.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="memberName">
            <mat-header-cell *matHeaderCellDef>Member Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.memberName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="hospitalName">
            <mat-header-cell *matHeaderCellDef> {{hospitalHeading}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.couponOrg?.name || element.agentName || '-' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="processStatus">
            <mat-header-cell *matHeaderCellDef>Process Status</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.processStatus}}</mat-cell>
        </ng-container>

        
        <ng-container matColumnDef="transactionStatus">
            <mat-header-cell *matHeaderCellDef>Transaction Status</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.transactionStatus}}</mat-cell>
        </ng-container>

        

        <ng-container matColumnDef="agentName">
            <mat-header-cell *matHeaderCellDef>Agent Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.agentName || ''}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

       
    </mat-table>
    <mat-paginator 
    #paginator
    [hidden]="dataSource.data.length === 0"
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 20]"
    [length]="dataSource.data.length"
    [showFirstLastButtons]="true"
    aria-label="Select page of Tasks!">
</mat-paginator>



    <div class="alert alert-info mt-2 pt-1" role="alert" *ngIf="dataSource.data.length == 0">
       No Orders Found
    </div>
    </div>
</div>