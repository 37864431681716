import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { CashflowService } from 'src/app/services/cashflow.service';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrl: './daily-report.component.scss'
})
export class DailyReportComponent implements OnInit {
  payorReport!: FormGroup;
  hospitalList:any=[];
  list:any =[];
  dataSource = new MatTableDataSource<any>;
  displayedColumns =['count','payorType','payorName','caseIDs']
  constructor(
    private hospitalRegistrationService: HospitalRegistrationService,
    private formBuilder: FormBuilder,
    private cashlessService: CashflowService
  ){
    this.payorReport = this.formBuilder.group({
      hospitalOrgId:['', Validators.required],
      startDate:[''],
      endDate:[''],
    });
  }
  ngOnInit(): void {
    this.getHospitalList();
  }

  

  getDailyReport(hospitalOrgId:any, startDate:any, endDate:any){
   // console.log(this.payorReport.value.hospitalOrgId)
    this.hospitalRegistrationService.getDailyReport(hospitalOrgId, startDate, endDate)
    .subscribe({
      next: (data: any) => {
        this.list = data;
        this.dataSource.data = this.list;
      },
      error: (error) => {
        console.log(error);
      }
    })
  }


  getHospitalList() {
    this.cashlessService.getActiveHospitals()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.hospitalList = data;
        },
        error: (error) => {
          
          console.log(error);
        }
      })
  }

  onFromDateChange(event:any){
    const startDate = event.target.value;
    console.log(startDate);
    this.getDailyReport(this.payorReport.value.hospitalOrgId,startDate.toISOString(),'');
  }

  onToDateChange(event:any){
    const endDate = event.target.value;
    console.log(endDate);
    this.getDailyReport(this.payorReport.value.hospitalOrgId,endDate.toISOString(),'');
  }

  onHospitalSelected(event:any){
    const hospitalOrgId = event.value;
    console.log(event.value);
    this.getDailyReport(hospitalOrgId, '','');
  }

}
