import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from 'src/app/services/customer.service';



@Component({
  selector: 'app-view-ekyp',
  templateUrl: './view-ekyp.component.html',
  styleUrl: './view-ekyp.component.scss'
})
export class ViewEkypComponent implements OnInit {
  imgData:any;
  pdfUrl:any;
  policyForm! : FormGroup;
  waiting_period_information: any[] =[];
  coverage_information : any[] =[];
  sublimit_information :any[] =[];
  remarks:any;
  waiting_period = ['memberName','postHospitalisation','preHospitalisation','waitingPeriod_Specific','waitingPeriod_PED3Years','waitingPeriod_30Days'];
  coverage =[ 'insuredName','sumInsured','recharge','dob','superBonus','effectiveCoverage'];
  sublimit =[ 'insuredName','roomRent','icuRent','ambulanceCover','ayushTreatment','hospitalDailyCash'];
  

  constructor(
   @Inject(MAT_DIALOG_DATA) public ekypData: any,
   private formBuilder : FormBuilder,
   private customerService : CustomerService,
   private dialogRef :MatDialogRef<ViewEkypComponent>,
  ){
   this.createForm();
   if(this.ekypData){
    this.patchForm(this.ekypData);
   }
  }

  ngOnInit(): void {
    const data = JSON.parse(this.ekypData);
    //this.getPolicyFile(data.id);
  }
  async getPolicyFile(policyId:string){
    await this.customerService
      .getPolicyFile(policyId)
      .subscribe({
        next: (data: Uint8Array) => {

          let file = new Blob([data], { type: "application/pdf" });
          this.pdfUrl = URL.createObjectURL(file);
        },
        error: async (err: any) => {
          console.log("error");
          console.log(err);
        },
      });
  }


  createForm(){
    this.policyForm = this.formBuilder.group({
      policyNumber:[''],
      policyType:[''],
      startDate:[''],
      endDate:[''],
      insuranceCompany:[''],
      ported:[''],
      // waiting_period_information: this.formBuilder.array([]),
      //coverage_information: this.formBuilder.array([]),
      //sublimit_information : this.formBuilder.array([])
    })
  }

  patchForm(incomingData:any){
    console.log(incomingData);
    const data = JSON.parse(incomingData);
    this.policyForm.patchValue({
      policyNumber:data.policyNumber,
      policyType:data.policyType,
      startDate:new Date(data.startDate),
      endDate:new Date(data.endDate),
      insuranceCompany:data.insuranceCompany,
      ported:data.portedPolicy,
      
    });
    this.waiting_period_information = data?.ekypData?.waiting_period_information;
    
    this.coverage_information = data?.ekypData?.coverage_information;
    this.sublimit_information = data?.ekypData?.sublimit_information;
    this.remarks = data?.ekypData?.benefits?.remark;
    // this.addArrayItems(data.ekypData.waiting_period_information,'waiting_period_information');
    //this.addArrayItems(data.ekypData.coverage_information,'coverage_information');
    //this.addArrayItems(data.ekypData.sublimit_information, 'sublimit_information');
  }

  addArrayItems(items: any[],arrayName: string): void {
    const formArray = this.policyForm.get(arrayName) as FormArray;
    items.forEach(item => {
      formArray.push(this.formBuilder.group(item))
    });
  }

  // get waiting_period_information(): FormArray{
  //   return this.policyForm.get('waiting_period_information') as FormArray;
  // }

  // get coverage_information(): FormArray{
  //   return this.policyForm.get('coverage_information') as FormArray;
  // }
  
  
  // get sublimit_information(): FormArray{
  //   return this.policyForm.get('sublimit_information') as FormArray;
  // }

  
  
 
}
