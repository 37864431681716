import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDateRangeInput } from "@angular/material/datepicker";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { CashflowService } from "src/app/services/cashflow.service";
import { Router, NavigationExtras } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ManageHospitalDetailComponent } from "./manage-hospital-detail/manage-hospital-detail.component";
import { SearchService } from "src/app/services/search.service";
import { ViewManageHospitalTechnicalInsuranceComponent } from "./view/view-manage-hospital-technical-insurance/view-manage-hospital-technical-insurance.component";
import { AddHospitalComponent } from "./add/add-hospital/add-hospital.component";

export interface DateRange {
  startDate: Date;
  endDate: Date;
}
@Component({
  selector: "app-manage-hospitals",
  templateUrl: "./manage-hospitals.component.html",
  styleUrls: ["./manage-hospitals.component.scss"],
})
export class ManageHospitalsComponent {
  hospitalList: any = [];
  manageHospitalList: any = [];
  selectedHospital: any;
  selectedDateRange: any;
  caseList: any = [];
  dataSource = new MatTableDataSource<any>(this.manageHospitalList);
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  displayedColumns = [
    "name",
    "contactDetail",
    "emailId",
    "dateCreated",
    "statusOfRegistration",
    "action",
  ];
  pageEvent: PageEvent = new PageEvent();
  length = 100;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  message: any;

  constructor(
    private cashlessService: CashflowService,
    private router: Router,
    private _dialog: MatDialog,
    private adminService: AdminService,
    private searchService:SearchService
  ) {}
  ngOnInit(): void {
    // this.getOnboardedHospitals();
    this.manageHospital();
    // this.getCaseList(null, null, null);
  }

  manageHospital() {
    //var filter=""
    var filter = this.selectedHospital ? this.selectedHospital : "";
    this.adminService
      .manageHospital(filter)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.manageHospitalList = data;
          // console.log("manageHospitalList", this.manageHospitalList);
          this.dataSource.data = this.manageHospitalList;
          this.dataSource.paginator = this.paginator;
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          //sthis.loading = false;
        },
      });
  }

  handleUInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "" && query.length > 3) {
      
        this.search(query);
    } else {
      // console.log("else");
      this.manageHospital();
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.manageHospital();
  }

  acceptTask(data: any): void {
    // console.log("here", data);
    const navigationExtras: NavigationExtras = {
      state: {
        data: data,
      },
    };
    this.router.navigate(
      ["/manage-hospital/" + data.hospitalUniqueId],
      navigationExtras
    );
  }

  openView(metaData: any) {
    console.log(metaData);
    const dialogRef = this._dialog.open(ViewManageHospitalTechnicalInsuranceComponent, {
      disableClose:true,
      data: {
        data: metaData,
      },
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
      // height:"70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
        }
      },
    });
  }

  search(searchTerm:any){
    this
      .searchService.searchHospital(searchTerm)
      .subscribe({
        next: (data: any) => {
          
          this.dataSource.data = data;
        },
        error: (err: any) => {
          console.error(err);
          //this.toastrService.error("Error");
        },
      });
  }

  addHospital(){
    const dialogComponent = this._dialog.open(AddHospitalComponent,{
      disableClose:true,
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%',
      
    });
    dialogComponent.afterClosed().subscribe((result: any) => {

        this.manageHospital();
      
    });
  }
}
