export class Order {
    amount?: number;
    productId?: string;
    transactionStatus?: string;
    userId?: string;
    policyNumber?:string;
    orderSource?:string;
    memberName?:string;
    agreementAccepted?:boolean;
    couponId?:any;
    discountPercent?:any;
    processStatus?:string;
    processed?:boolean;
}