import { Component, EventEmitter, OnInit, Output, ViewChild , OnDestroy, Input, OnChanges} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router, NavigationExtras } from "@angular/router";
import { first, map, tap } from "rxjs/operators";
import { timer , takeUntil , switchMap , Subject, interval} from "rxjs";
import { AddAssignTaskComponent } from "src/app/modal/add-assign-task/add-assign-task.component";
import { CashflowService } from "src/app/services/cashflow.service";
import { CustomerService } from "src/app/services/customer.service";
import { FormComponent } from "../case/form/form.component"
import { AuthService } from "src/app/services/authService";
import { Overlay, ToastrService } from "ngx-toastr";
import { DataServiceService } from "src/app/services/data-service.service";
import { WorkflowService } from "src/app/services/workflow.service";
import { CreatecaseComponent } from "../createcase/createcase.component";
import { DataShareModel } from "src/app/model/dataShareModel";
import { CurrentStatusComponent } from "../timeline/current-status/current-status.component";
import { AdminService } from "src/app/services/admin.service";
import { CashlessFilter } from "src/app/model/cashlessFilter";
import { ClaimsComponent } from "src/app/portal/bg/claims/claims.component";

@Component({
  selector: 'app-new-inbox',
  templateUrl: './new-inbox.component.html',
  styleUrls: ['./new-inbox.component.scss']
})
export class NewInboxComponent implements OnInit, OnDestroy, OnChanges{

  allTasks:any[]=[];
  dateVal = new Date();
  createCase!: FormGroup;
  selectedoption = null;
  DoYouhaveFamilyPhysician = null;
  newTasks: any[] = [];
  loading: any = false;
  filteredData:any = [];
  taskId:any;
  error: any;
  message: any;
  form: FormGroup = this.formBuilder.group({});
  displayedColumns: string[] = [
    "currenttask",
    "orderDate",
    "orderId",
    "customer",
    "hospital",
    "assignTo",
    "action",
  ];
  dataSource = new MatTableDataSource<any>(this.allTasks);
  public dynamicFormGroup!: FormGroup;
  public fields: any = [];
  private unsubscribe = new Subject<void>();
  policyMembers: any;
  user: any;
  assignedTask: any[] = [];
  private refreshInterval = 30000;
  private refreshIntervalInitial = 10000;
  @Input() isActive!: boolean;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  constructor(
    private createCaseService: CreatecaseComponent,
    private dataService: DataServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private customerService: CustomerService,
    private authService: AuthService,
    private _dialog: MatDialog,
    private workflowService: WorkflowService,
    private toastrService: ToastrService,
    private overlay: Overlay,
  ) {
    this.dataService.allTasksData$.subscribe((data: any) => {
      if (data != null && data.getProcessName() == "cashlessProcess") {
        this.allTasks = data.tasks;
        this.dataSource.data =this.allTasks;
        console.log(this.allTasks);
        
      }
    });
    //this.startInitialRefreshing();
  }

  ngOnInit(): void {
    this.authService.user.subscribe(x => {
      this.user = x;
      console.log(this.user);

    });
    this.getDeployedprocessByProcessDefTasks("cashlessProcess");
    this.startRefreshing();
    
    this._dialog.afterOpened.subscribe(() => {
      console.log('Dialog opened: stopping refresh.');
      this.stopRefreshing();  // Stop refresh when dialog is opened
    });

    this._dialog.afterAllClosed.subscribe(() => {
      console.log('All dialogs closed: resuming refresh.');
      this.getAllTasks();  // Resume refresh when all dialogs are closed
    });

   

  }
  ngOnChanges(): void{
    if(this.isActive){
      this.startRefreshing();
    }
    else{
      this.stopRefreshing();  
    }
    
  }
  
  startRefreshing() {
    this.stopRefreshing();
    this.unsubscribe = new Subject<void>();
  
 
    interval(this.refreshInterval)
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(() => 
          this.workflowService.getAllTask("cashlessProcess") 
        ),tap((data: any) => {
 
          this.allTasks = data;
  
          const dataShare = new DataShareModel("cashlessProcess", data);
          this.dataService.updateAllTasks(dataShare);
          this.getDeployedprocessByProcessDefTasks("cashlessProcess");
          console.log("1");
        }),
        map((data: any) => {
         
          if (this.taskId && this.taskId !== "ALL") {
            return data.filter((task: any) => task.taskDefKey === this.taskId);
          }
          console.log("2");
          return data;
          
        })
      )
      .subscribe({
        next: (filteredData: any) => {
        
          this.dataSource.data = filteredData;
          console.log("Filtered Tasks: ", JSON.stringify(filteredData));
          this.loading = false; 
        },
        error: (error:any) => {
          
          this.error = error.error;
           console.log(error);
          this.loading = false;
        },
      });
  }
  

  
  stopRefreshing(){
    if (this.unsubscribe) {
      this.unsubscribe.next(); // Signal to stop the interval
      this.unsubscribe.complete();
    }
  }

  ngAfterViewInit(): void {
   // this.getAllTasks();
    this.dataSource.paginator = this.paginator;
    
  }

  ngOnDestroy(): void {
   this.stopRefreshing();
  }

  assignTask(data: any) {
    const dialogRef = this._dialog.open(AddAssignTaskComponent, {
      disableClose:true,
      data: { data: data, roleName: ['ADMIN', 'CASHLESS'] },
      width: '60%',

    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val == "ASSIGNED") {
          console.log("ASSIGNED");
          this.getAllTasks();
        }
      },
    });

  }


  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;
    console.log(this.user.roles);
    var dd = roles.some((x: string) => role.includes(x));
    //console.log("roles "+ dd);
    return dd;
  }

  applyFilter(event: any) {
      const filterValue = (event.target as HTMLInputElement).value;

      if(filterValue){
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    else {
      this.dataSource.filter = "";
    }
  }

  selectedTask: any;
  hasPriviledge:boolean = true;
  acceptTask(task: any) {
    this.selectedTask = task;
    console.log(this.selectedTask)
    
    if(this.selectedTask.taskDefKey == 'preauth')
    {
      console.log(this.isPrivileged(['IS_HOSPITAL_USER']));
      console.log(this.selectedTask.caseVariables.hospital.preAuthFilled);
      if(this.isPrivileged(['IS_HOSPITAL_USER']) && this.selectedTask.caseVariables.hospital.preAuthFilled == true ){
       this.hasPriviledge = true;
      }
      else if (this.isPrivileged(['IS_HOSPITAL_USER']) && this.selectedTask.caseVariables.hospital.preAuthFilled == false){
        this.hasPriviledge = false;
        this.toastrService.info("This tasks needs to be completed by the hospital authorities!");
      }
      else{
        this.hasPriviledge = true;
       
      }
    }
    if(this.hasPriviledge ){
    this.workflowService.assignTask(this.selectedTask.id, this.user.id, new Date().toISOString())
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.getAllTasks();
          this.viewTask(task);
          // this.createCaseService.updateSelectedTab(2);
        },
        error: error => {
          console.error('Error', error);
        }

      });
  }
    
  }



  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  // getAllTasks() {
  //   this.workflowService
  //     .getAllTask("cashlessProcess")
  //     .pipe(first())
  //     .subscribe({
  //       next: (data: any) => {
  //         this.allTasks = data;
  //         console.log("All Tasks " + JSON.stringify(this.newTasks));
         
  //         const dataShare = new DataShareModel("cashlessProcess",data);
          
  //         this.dataService.updateAllTasks(dataShare);
  //         this.getDeployedprocessByProcessDefTasks("cashlessProcess");
  //         this.loading = false;
  //         if(this.taskId){
  //           this.filterPriority(this.taskId)
  //         }
  //       },
  //       error: (error) => {
  //         this.error = error.error;
  //         this.message = undefined;
  //         console.log(error);
  //         this.loading = false;
  //       },
  //     });
  // }
  getAllTasks() {
    this.loading = true; // Start loading state
  
    this.workflowService
      .getAllTask("cashlessProcess")
      .pipe(
        first(),
        tap((data: any) => {
          // Always store the full dataset in `allTasks`
          this.allTasks = data;
  
          // Share the full dataset
          const dataShare = new DataShareModel("cashlessProcess", data);
          this.dataService.updateAllTasks(dataShare);
          this.getDeployedprocessByProcessDefTasks("cashlessProcess");
        }),
        map((data: any) => {
          // Derive the filtered data for the UI based on taskId
          if (this.taskId && this.taskId !== "ALL") {
            return data.filter((task: any) => task.taskDefKey === this.taskId);
          }
          return data; // Return all data if no taskId filter is applied
        })
      )
      .subscribe({
        next: (filteredData: any) => {
          // Update only the UI-related variables
          this.dataSource.data = filteredData;
          console.log("Filtered Tasks: ", JSON.stringify(filteredData));
          this.loading = false; // End loading state
        },
        error: (error) => {
          // Handle errors
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }
  

  
  
  viewTask(task: any) {
   
    const dialogRef = this._dialog.open(FormComponent, {
      disableClose:true,
      data: { task: task },
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
       
      },
    });
  }

  status(task: any) {
    console.log(JSON.stringify(task))
    const dialogRef = this._dialog.open(CurrentStatusComponent, {
      disableClose:true,
      data: task,
      width: '90%',
      height: '90%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        this.getAllTasks();
      },
    });
  }
filter(){

}

filterData:any[]=[];
deployedprocessByProcessDefTasks:any=[];
  selectedKey:any;
  getDeployedprocessByProcessDefTasks(processKey : any) {
    this.selectedKey = processKey;
    this.filterData = [];
    this.adminService
      .getDeployedprocessByProcessDefKey(processKey )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.deployedprocessByProcessDefTasks = data;
          console.log("Deployed tasks ", this.deployedprocessByProcessDefTasks);

          for(let x=0;x<data.length;x++){
            const cf = new CashlessFilter();
            cf.taskId = data[x].taskId;
            cf.taskName = data[x].taskName;
            cf.count = this.allTasks.filter((task:any) => task.taskDefKey === cf.taskId).length;
            this.filterData.push(cf);
          }
          this.filterData.sort((a:any, b:any) => b.count - a.count);
          console.log(JSON.stringify(this.filterData));
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
        },
      });
  }

  
  filterPriority(taskId:any){
    console.log(taskId);
    if(taskId == "ALL"){
      this.taskId = "ALL";
      //this.dataSource.data = this.allTasks;
      console.log(this.allTasks);
      this.dataSource.filter = '';
      this.dataSource.data =  this.allTasks;
    }else{
      this.taskId = taskId;
      this.dataSource.filter = '';
      this.dataSource.data =  this.allTasks;
      // this.filteredData = this.allTasks.filter((data:any) => data.taskDefKey == taskId);
      // this.dataSource.data = this.filteredData;
      this.dataSource.filter = taskId.trim();
    }
    
  }
  viewClaimStatus(element:any) {
    console.log(element);
    const dialog = this._dialog.open(ClaimsComponent, {
      data:element,
      disableClose: true,
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
    });

    dialog.afterClosed().subscribe(() => {

    });
  }


}
