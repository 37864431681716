<div class="container  p-3">
  <div class="p-3 text-center">
    <div class="addbtn">
      <button
      type="button"
      mat-flat-button style="color:white;background:#8e0101"
      (click)="openAddForm()" >
      Add BG Product
    </button>

    </div>

    <div class="spinner-border text-secondary" *ngIf="loading" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>

    <div class="row px-3 py-4 justify-content-md-center">
      <input
        type="search"
        (input)="handleUInput($event)"
        class="form-control"
        placeholder="Filter"
        aria-label="Search"
      />
    </div>

    <mat-table class="" [dataSource]="dataSource">
      <!-- <ng-container matColumnDef="image">
                      <th mat-header-cell *matHeaderCellDef> Image </th>
                      <td mat-cell *matCellDef="let element" >
                        <img style="width: 20vh; height: 20vh" [src]="element.images[0].src" />
                        
    
                         </td>
                    </ng-container> -->

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef> Price </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.price| currency:'INR':'symbol-narrow':'4.2-2' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Desc </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.description }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          [style.color]="element.inactive == true ? 'gray' : null"
        >
          {{ element.inactive ? "In Active" : "Active" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell  style="background:rgb(241, 241, 241);" *matHeaderCellDef> Update </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            style="color:#8e0101;"
            (click)="openEditForm(element)"
            matTooltip="Edit Form"
            matTooltipPosition="below"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="delete">
        <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef> Delete </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
          mat-icon-button
          style="color:#8e0101;"
          (click)="deleteProduct(element)"
          matTooltip="Edit Form"
          matTooltipPosition="below"
        >
        <mat-icon>check_circle_outline</mat-icon>
      </button>
        </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="delete">
        <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell *matCellDef="let element"
        >
          <button   [disabled]="element.inactive == true"
          mat-icon-button
          [style.color]="element.inactive == true ? 'gray' : '#8e0101'"
          (click)="deleteProduct(element)"
          matTooltip="Edit Form"
          matTooltipPosition="below"
        >
        <mat-icon>delete</mat-icon>
      </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator #paginator [length]="dataSource.data.length" [pageSizeOptions]="[10,20,50]"  showFirstLastButtons></mat-paginator>

    <!-- <div class="demo-options p-4">
      <div class="demo-toggles">
        <mat-paginator
          #paginator
          class="demo-paginator"
          (page)="handlePageEvent($event)"
          [length]="length"
          [pageSize]="pageSize"
          [disabled]="disabled"
          [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
          [hidePageSize]="hidePageSize"
          [pageIndex]="pageIndex"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div> -->
  </div>
</div>
