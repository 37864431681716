import { AfterViewInit, Component, DoCheck, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterEvent,Event } from "@angular/router";
import { AuthService } from "./services/authService";
import {
  faCoffee,
  faScrewdriver,
  faUser,
  faLink,
  faPhoneVolume,
  faScrewdriverWrench,
  faVolumeLow,
  faLineChart,
  faMicrochip,
  faHouseMedicalCircleExclamation,
  faShareNodes,
  faNotesMedical,
  faUserShield,
  faHome,
  faHomeUser,
} from "@fortawesome/free-solid-svg-icons";
import { AdminService } from "./services/admin.service";
import { BehaviorSubject, filter } from "rxjs";
import { ClaimsComponent } from "./portal/bg/claims/claims.component";
import { MatDialog } from "@angular/material/dialog";
import { ViewManageHospitalTechnicalInsuranceComponent } from "./admin/manage-hospitals/view/view-manage-hospital-technical-insurance/view-manage-hospital-technical-insurance.component";
import { HospitalRegistrationService } from "./services/hospitalregistration.service";

const USER_KEY = "user";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements  OnInit, AfterViewInit {
  pathUrl:any;
  label:any;
  title = "BG Enterprise Portal";
  user: any;
  img: any;
  faUser = faUser;
  faLink = faLink;
  faScrewdriver = faScrewdriver;
  faPhoneVolume = faPhoneVolume;
  faScrewdriverWrench = faScrewdriverWrench;
  faVolumeLow =faVolumeLow;
  faLineChart =faLineChart;
  faMicrochip = faMicrochip;
  faHouseMedicalCircleExclamation =faHouseMedicalCircleExclamation;
  faShareNodes =faShareNodes;
  faNotesMedical = faNotesMedical;
  faUserShield = faUserShield;
  faHome=faHome;
  faHomeUser=faHomeUser;
  hospitalDetails:any;
  constructor(
    public adminService: AdminService,
    public hospitalRegistrationService:HospitalRegistrationService,
    public authService: AuthService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private _dialog:MatDialog
  ) {

    this.authService.user.subscribe((x) => (this.user = x));
    this.authService.imgData.subscribe((x) => {
      this.img = x;
    });
    this.router.events.pipe(
      filter((e:any): e is RouterEvent => e instanceof NavigationEnd)
   ).subscribe((e: RouterEvent) => {
    let route =  activatedRoute.root.firstChild;
    
    let label = route?.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route?.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
//console.log("Label "+label+" Path "+path);
    this.label = label;
     this.pathUrl = e.url;
   });
  }
  ngAfterViewInit(): void {
   
  }
  currentUrl:any;

  ngOnInit() {
   
    this.router.events.pipe(
      filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)
   ).subscribe((e: RouterEvent) => {
     //console.log(e.url)
     this.currentUrl = e.url;
   });

    if (this.user != undefined) {
      this.adminService.getProfilePic().subscribe({
        next: (data: any) => {
          //this.imgData = data;
          //console.log(data)
          //this.imgData = "data:image/png;base64," + data;
          const reader = new FileReader();
          reader.onload = (e: any) => {
            //this.img =e.target.result;
            this.authService.imgData.next(e.target.result);
          }
          reader.readAsDataURL(new Blob([data]));
        },
        error: (err: any) => {
          console.log(err);
        },
      });
    }

    if(this.isPrivileged(['IS_HOSPITAL_USER'])){
      this.hospitalRegistrationService.getHospitalByOrgId(this.user.orgId)
      .subscribe({
        next:(data:any)=>{
          this.hospitalDetails = data;
        },
        error:(err:any)=>{
          console.log(err);
        }
      })
    }
  }
  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }

  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;
    var dd = roles.some((x: string) => role.includes(x));
    //console.log("roles "+ this.user.roles);
    return dd;
  }

  logout() {
    this.img = undefined;
    this.authService.logout();
    this.router.navigate(["/login"]);
    // window.location.reload()
  }
  setLandingPage(){
    this.adminService.updateLandingPage(this.currentUrl).subscribe({
      next: (data: any) => {
        console.log("Data "+JSON.stringify(data));
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  viewClaimStatus(){
    const dialog = this._dialog.open(ClaimsComponent,{
      data: {},
      disableClose:true,
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
    });

    dialog.afterClosed().subscribe(() => {
      
    });
  }

  
  viewHospitalDetails() {
    console.log(this.hospitalDetails);
    const dialogRef = this._dialog.open(ViewManageHospitalTechnicalInsuranceComponent, {
      disableClose:true,
      data: {
        data: this.hospitalDetails,
      },
      width: '100vw',  // Sets the width to 100% of the viewport width
      maxWidth: '100vw',  // Ensures the max width doesn't override the width
      panelClass: 'full-width-dialog',  // Custom CSS class for additional styling
      height: '100%'
      // height:"70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) {
        }
      },
    });
  }

}
