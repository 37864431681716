import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AdminService } from "src/app/services/admin.service";
import { ToastrService } from "ngx-toastr";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { filter, first } from "rxjs";
import { CashflowService } from "src/app/services/cashflow.service";

@Component({
  selector: "app-add-mail-parser-confs",
  templateUrl: "./add-mail-parser-confs.component.html",
  styleUrl: "./add-mail-parser-confs.component.scss",
})
export class AddMailParserConfsComponent {
  configForm: FormGroup;
  orgId: any;
  user: any;
  status: any;
  automated: any;
  inhouse: any;
  emailConfig: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  selectedType: any;
  message: any;
  hospitalOrgData: any;
  selectedHospitalOrgId: any;
  selectedPayorType:any;
  categoryList: any;
  // data:any;

  constructor(
    private _fb: FormBuilder,
    private toastrService: ToastrService,
    private HospitalRegistrationService: HospitalRegistrationService,
    private adminService: AdminService,
    private _dialogRef: MatDialogRef<AddMailParserConfsComponent>,
    private cashlessService: CashflowService,
    @Inject(MAT_DIALOG_DATA) public data: any // @Inject(MAT_DIALOG_DATA) public orgId: any, //private _coreService: CoreService
  ) {

    console.log(data);
    // this.data = data.insurance;
    // console.log(this.data);
    this.configForm = this._fb.group({
    
      payorType: [""],
      email:[""],
      receivingEmailHost: ["", Validators.required],
      receivingEmailId: ["",Validators.required],
      receivingEmailStoreType: ["",Validators.required],
      receivingEmailPassword: ["",Validators.required],
      receivingEmailPort: ["",Validators.required],
    });
  }
  ngOnInit(): void {
    // this.data = this.data.insurance;
    // console.log(this.data);
   // this.getEmailCong(this.data.hospitalOrgId);//TODO
    this.manageHospital(); 
    this.getCategory();
  }

  getEmailCong(hospitalOrgId:any) {
    this.HospitalRegistrationService.getEmailConfig(
      this.data.insurance.hospitalOrgId
    )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          //console.log(data);
          this.emailConfig = data;
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
          // this.message = error;

          //this.loading = false;
        },
      });
  }

  onFormSubmit() {
    if (this.configForm.valid && this.data.type == 'receiver') {
      var receiverData = {
        // id: this.data.data.id,
        hospitalOrgId:this.data.insurance,
        //payorType: this.configForm.value.payorType,
        receivingEmailHost: this.configForm.value.receivingEmailHost,
        receivingEmailId: this.configForm.value.receivingEmailId,
        receivingEmailPassword: this.configForm.value.receivingEmailPassword,
        receivingEmailPort: this.configForm.value.receivingEmailPort,
        receivingEmailStoreType: this.configForm.value.receivingEmailStoreType,
        inactive: true,
       // insuranceTpaId: this.data.insurance.insuranceId,
      };
      if (this.data) {
        this.HospitalRegistrationService.addReceiverEmailConfig(receiverData)
        .subscribe({
          next: (val: any) => {
            this.toastrService.success(
              "Email config  has been updated successfully"
            );

            this._dialogRef.close(true);
          },
          error: (err: any) => {
            console.error(err);
            //this.toastrService.error("Error");
            this.toastrService.error(JSON.stringify(err.error));
          },
        });
      }
    }else if(this.configForm.valid && this.data.type == 'sender'){
      var senderData = {
        // id: this.data.data.id,
        hospitalOrgId: this.data.insurance.hospitalOrgId,
      payorType: this.configForm.value.payorType,
        senderEmailHost: this.configForm.value.receivingEmailHost,
        senderEmailId: this.configForm.value.receivingEmailId,
        senderEmailPassword: this.configForm.value.receivingEmailPassword,
        senderEmailPort: this.configForm.value.receivingEmailPort,
        senderEmailStoreType: this.configForm.value.receivingEmailStoreType,
        inactive: true,
        insuranceTpaId: this.data.insurance.insuranceId,
      };
      if (this.data) {
        this.HospitalRegistrationService.addSenderEmailConfig(senderData)
        .subscribe({
          next: (val: any) => {
            this.toastrService.success(
              "Email config  has been updated successfully"
            );

            this._dialogRef.close(true);
          },
          error: (err: any) => {
            console.error(err);
            //this.toastrService.error("Error");
            this.toastrService.error(JSON.stringify(err.error));
          },
        });
      }
    }else{

    }
  }

  selectedEmail:any;

  updateDetails(event:any){
    this.selectedEmail = event.value;
    console.log(event.value);
    if(this.data.type == "sender"){
      if(this.selectedEmail == 'gmail'){
        this.configForm.patchValue({
          'receivingEmailHost': 'smtp.gmail.com',
          'receivingEmailPort': 465,
          'receivingEmailStoreType':'smtp'
        })
      }else if(this.selectedEmail == 'yahoo'){
        this.configForm.patchValue({
          'receivingEmailHost': 'smtp.mail.yahoo.com',
          'receivingEmailPort': 465,
          'receivingEmailStoreType':'smtp'
        })
      }
    }else if(this.data.type == "receiver"){
      if(this.selectedEmail == 'gmail'){
        this.configForm.patchValue({
          'receivingEmailHost': 'imap.gmail.com',
          'receivingEmailPort': 993,
          'receivingEmailStoreType':'imaps'
        })
      }else if(this.selectedEmail == 'yahoo'){
        this.configForm.patchValue({
          'receivingEmailHost': 'imap.mail.yahoo.com',
          'receivingEmailPort': 993,
          'receivingEmailStoreType':'imaps'
        })
      }
    }
   
  }

  manageHospital() {
    var filter = "";
    this.adminService
      .manageHospital(filter)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log("data " + JSON.stringify(data));

           this.hospitalOrgData = data;
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          //sthis.loading = false;
        },
      });
  }

  getCategory() {
    var tag = "insurance";
    this.cashlessService
      .getCategoryByTypeAndEntityType(tag)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.categoryList = data;  
        },
      });
  }
  payload:any;
  test(){
    if(this.data.type =="receiver"){
      this.payload = {
      
        "hospitalOrgId": this.data.insurance.hospitalOrgId,
        "receivingEmailId": this.configForm.value.receivingEmailId,
        "receivingEmailPassword":this.configForm.value.receivingEmailPassword,
        "receivingEmailHost": this.configForm.value.receivingEmailHost,
        "receivingEmailPort":  this.configForm.value.receivingEmailPort,
        "receivingEmailStoreType": this.configForm.value.receivingEmailStoreType,
        "inactive": false,
        "insuranceTpaId": this.data.insurance.insuranceId,
        "payorType":  this.configForm.value.payorType
      
      }
      this.testReceiverEmail(this.payload);
    }else{

      this.payload={
        
   
    "hospitalOrgId": this.data.insurance.hospitalOrgId,
    "senderEmailId": this.configForm.value.receivingEmailId,
    "senderEmailPassword": this.configForm.value.receivingEmailPassword,
    "senderEmailHost": this.configForm.value.receivingEmailHost,
    "senderEmailPort":  this.configForm.value.receivingEmailPort,
    "senderEmailStoreType": this.configForm.value.receivingEmailStoreType,
    "inactive": false,
    "insuranceTpaId": this.data.insurance.insuranceId,
    "payorType": this.configForm.value.payorType
      }
      this.testSenderEmail(this.payload);
    }
  }

  testReceiverEmail(data:any){
    this.HospitalRegistrationService.testReceiverConfig(data)
    .subscribe({
      next: (val: any) => {
        this.toastrService.success(
          "Email test was  successful"
        );
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error("Please check your configuration details!");
      },
    })
  }

  testSenderEmail(data:any){
    this.HospitalRegistrationService.testSenderConfig(data)
    .subscribe({
      next: (val: any) => {
        this.toastrService.success(
          "Email test was  successful"
        );
      },
      error: async(err: {error:{error:any}}) => {
        console.log(err);
        //this.toastrService.error("Error");
        this.toastrService.error("Please check your configuration details!");
      },
    })
  }
}
