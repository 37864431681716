<div mat-dialog-title>
    <h1>Update Product</h1>
</div>
<form [formGroup]="prductForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <!-- <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="id" readonly >
            </mat-form-field>
        </div> -->
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Product Name</mat-label>
                <input matInput type="text" placeholder="Product id" formControlName="name" >
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Type</mat-label>
                <input matInput placeholder="Type" formControlName="type">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Descriptiont</mat-label>
                <input matInput type="text" placeholder="Descriptiont" formControlName="description" >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Short Description</mat-label>
                <input matInput type="email" placeholder="Short Description" formControlName="short_description" >
            </mat-form-field>
        </div>
        
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Regular Price</mat-label>
                <input matInput type="text" placeholder="Regular Price" formControlName="regular_price" >
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Sale Price</mat-label>
                <input matInput type="text" placeholder="Sale Price" formControlName="sale_price" >
            </mat-form-field>
        </div>

        <!-- <mat-form-field appearance="outline">
            <mat-label>Date on sale from</mat-label>
            <input matInput type="text" placeholder="Start date" formControlName="date_on_sale_from" readonly>
        </mat-form-field>
    
    
        <mat-form-field appearance="outline">
            <mat-label>Date on sale to</mat-label>
            <input matInput type="text" placeholder="End date" formControlName="date_on_sale_to" readonly>
        </mat-form-field> -->
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Date on sale from</mat-label>
                <input
                  matInput
                  [min]="todayDate"
                  [matDatepicker]="picker"
                  placeholder="Start date"
                  formControlName="date_on_sale_from"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
    
              <mat-form-field appearance="outline">
                <mat-label>Date on sale to</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker1"
                  placeholder="End date"
                  formControlName="date_on_sale_to"
                  [min]="startDate" 
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Stock_Quantity</mat-label>
                <input matInput type="text" placeholder="stock_quantity" formControlName="stock_quantity" >
            </mat-form-field>
        </div>
       
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button>
    </div>
</form>