import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';

@Component({
  selector: 'app-add-receiving-email',
  templateUrl: './add-receiving-email.component.html',
  styleUrl: './add-receiving-email.component.scss'
})
export class AddReceivingEmailComponent implements OnInit {

  detailForm!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public hospitalOrgId :any,
    public formBuilder: FormBuilder,
    private hospitalRegistrationsService: HospitalRegistrationService,
    private toastrService: ToastrService,
    private matDialogRef: MatDialogRef<AddReceivingEmailComponent>

  ){

  }

  ngOnInit(): void {
    this.detailForm = this.formBuilder.group({
      info: ['', Validators.required],
      // fieldType: ['', Validators.required],
      fieldValue: ['',Validators.required],
      // fieldName: ['', Validators.required],
      // fieldCategory: ['',Validators.required],
     
    });
    
  }

  onFormSubmit(){
    const data ={
      'info': this.detailForm.value.info,
      'fieldType':'string',
      'fieldValue':this.detailForm.value.fieldValue,
      'fieldName':'email',
      'fieldCategory':'IC_EMAIL_COMMUNICATION'
    }

    this.hospitalRegistrationsService.addDetails(this.hospitalOrgId,data)
    .subscribe({
      next:(data:any)=>{
        console.log(data);
        this.toastrService.success('Details added successfully');
        this.close();
      }
    })
  }

  close(){
    this.matDialogRef.close();
  }
}
