import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AdminService } from "src/app/services/admin.service";
import { ToastrService } from "ngx-toastr";
import { HospitalRegistrationService } from "src/app/services/hospitalregistration.service";
import { first } from "rxjs";


@Component({
  selector: "app-update-mail-parser-confs",
  //standalone: true,
  //imports: [],
  templateUrl: "./update-mail-parser-confs.component.html",
  styleUrl: "./update-mail-parser-confs.component.scss",
})
export class UpdateMailParserConfsComponent {
  insuranceForm: FormGroup;
  orgId: any;
  user: any;
  status: any;
  automated: any;
  inhouse: any;
  emailConfig: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  selectedType: any;

  displayedColumns: string[] = [
    "id",
    "nameReg",
    "nameShort",
    "claimSupportAddr",
    "claimSupportEmail",
    "claimSupportNumber",
    "action",
    "delete",
  ];
  receivingEmailPassword:any='';

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private HospitalRegistrationService: HospitalRegistrationService,
    private _dialogRef: MatDialogRef<UpdateMailParserConfsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) // @Inject(MAT_DIALOG_DATA) public orgId: any,

  //private _coreService: CoreService
  {
    this.insuranceForm = this._fb.group({
      
      receivingEmailHost: [""],
      receivingEmailId: [""],
      receivingEmailStoreType: [""],
      inactive:[""],
      receivingEmailPassword:[""]
    });
  }
  ngOnInit(): void {
    console.log("dsf", this.data.config);
    console.log("dsf", this.data.insurancedata);

    //this.getEmailCong(this.data.config.hospitalOrgId);//TODO
    if(this.data.type == "receiver"){
      this.insuranceForm.patchValue({
        hospitalOrgId: this.data.config.hospitalOrgId,
       // payorType: this.data.config.payorType,
        receivingEmailHost: this.data.config.receivingEmailHost,
        receivingEmailId: this.data.config.receivingEmailId,
        receivingEmailStoreType: this.data.config.receivingEmailStoreType,
        inactive : this.data.config.inactive
       

      });
    }else{
      this.insuranceForm.patchValue({
        hospitalOrgId: this.data.config.hospitalOrgId,
        payorType: this.data.config.payorType,
        receivingEmailHost: this.data.config.senderEmailHost,
        receivingEmailId: this.data.config.senderEmailId,
        receivingEmailStoreType: this.data.config.senderEmailStoreType,
        inactive : this.data.config.inactive
      });
    }
   
  }

  getEmailCong(hospitalOrgId:any) {
    this.HospitalRegistrationService
      .getEmailConfig(hospitalOrgId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          //console.log(data);
          this.emailConfig = data;
        },
        error: (error: any) => {
          console.log("error " + JSON.stringify(error));
       
        },
      });
  }

 


payload:any;
onFormSubmit() {
  if (this.insuranceForm.valid && this.data.type =="receiver") {
 const data={
  
    "id": this.data.config.id,
    "hospitalOrgId": this.data.config.hospitalOrgId,
    "receivingEmailId": this.insuranceForm.value.receivingEmailId,
    "receivingEmailPassword": this.data.config.receivingEmailPassword,
    "receivingEmailHost": this.insuranceForm.value.receivingEmailHost,
    "receivingEmailPort": this.data.config.receivingEmailPort,
    "receivingEmailStoreType": this.insuranceForm.value.receivingEmailStoreType,
    "inactive": false,
    "insuranceTpaId": this.data.config.insuranceTpaId,
    "payorType": this.data.config.payorType
  
 }
    if (this.data) {
      this.HospitalRegistrationService
        // .updateMasterEmailConfig(this.insuranceForm.value)
        .updateReceiverEmailConfig(data)

        .subscribe({
          next: (val: any) => {
            this.toastrService.success(
              "Email config  has been updated successfully"
            );
            //this.testReceiverEmail(data);
            this._dialogRef.close(true);
          },
          error: (err: any) => {
            console.error(err);
            //this.toastrService.error("Error");
            this.toastrService.error(JSON.stringify(err));
          },
        });
    }
  }else{
   const data={
  
      "id": this.data.config.id,
      "hospitalOrgId": this.data.config.hospitalOrgId,
      "senderEmailId": this.insuranceForm.value.receivingEmailId,
      "senderEmailPassword": this.data.config.senderEmailPassword,
      "senderEmailHost": this.insuranceForm.value.receivingEmailHost,
      "senderEmailPort": this.data.config.senderEmailPort,
      "senderEmailStoreType": this.insuranceForm.value.receivingEmailStoreType,
      "inactive": false,
      "insuranceTpaId": this.data.config.insuranceTpaId,
      "payorType":this.data.config.payorType
    
   }
      if (this.data) {
        this.HospitalRegistrationService
          // .updateMasterEmailConfig(this.insuranceForm.value)
          .updateSenderEmailConfig(data)
  
          .subscribe({
            next: (val: any) => {
              this.toastrService.success(
                "Email config  has been updated successfully"
              );
             // this.testSenderEmail(senderData);
              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              //this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err));
            },
          });
      }
  }
}
    testReceiverEmail(data:any){
      this.HospitalRegistrationService.testReceiverConfig(data)
      .subscribe({
        next: (val: any) => {
          this.toastrService.success(
            "Email test was  successful"
          );
        },
        error: (err: any) => {
          console.error(err);
          //this.toastrService.error("Error");
          this.toastrService.error("Please check your configuration details!");
        },
      })
    }

    testSenderEmail(data:any){
      this.HospitalRegistrationService.testSenderConfig(data)
      .subscribe({
        next: (val: any) => {
          this.toastrService.success(
            "Email test was  successful"
          );
        },
        error: async(err: {error:{error:any}}) => {
          console.log(err);
          //this.toastrService.error("Error");
          this.toastrService.error("Please check your configuration details!");
        },
      })
    }

    test(){
      if(this.data.type =="receiver"){
        this.payload = {
          "id": this.data.config.id,
          "hospitalOrgId": this.data.config.hospitalOrgId,
          "receivingEmailId": this.insuranceForm.value.receivingEmailId,
          "receivingEmailPassword":this.insuranceForm.value.receivingEmailPassword,
          "receivingEmailHost": this.insuranceForm.value.receivingEmailHost,
          "receivingEmailPort": this.data.config.receivingEmailPort,
          "receivingEmailStoreType": this.insuranceForm.value.receivingEmailStoreType,
          "inactive": false,
          "insuranceTpaId": this.data.config.insuranceTpaId,
          "payorType": this.data.config.payorType
        
        }
        this.testReceiverEmail(this.payload);
      }else{

        this.payload={
          
      "id": this.data.config.id,
      "hospitalOrgId": this.data.config.hospitalOrgId,
      "senderEmailId": this.insuranceForm.value.receivingEmailId,
      "senderEmailPassword": this.insuranceForm.value.receivingEmailPassword,
      "senderEmailHost": this.insuranceForm.value.receivingEmailHost,
      "senderEmailPort": this.data.config.senderEmailPort,
      "senderEmailStoreType": this.insuranceForm.value.receivingEmailStoreType,
      "inactive": false,
      "insuranceTpaId": this.data.config.insuranceTpaId,
      "payorType":this.data.config.payorType
        }
        this.testSenderEmail(this.payload);
      }
    }
}