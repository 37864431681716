import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';
import { AddReceivingEmailComponent } from './add-receiving-email/add-receiving-email.component';
import { UpdateReceivingEmailComponent } from './update-receiving-email/update-receiving-email.component';

@Component({
  selector: 'app-view-receiving-email',

  templateUrl: './view-receiving-email.component.html',
  styleUrl: './view-receiving-email.component.scss'
})
export class ViewReceivingEmailComponent implements OnInit {

  @Input() hospitalOrgId: any;
  displayedColumns: string[] = ['id','info','fieldType','fieldValue','fieldName','fieldCategory','update'];
  detailList:any =[];
  message:any;
  dataSource = new MatTableDataSource(this.detailList);
  constructor(
    private hospitalRegistrationService: HospitalRegistrationService,
    public dialog: MatDialog,
  ){
   
  }

  ngOnInit(): void {
    this.getDetails(this.hospitalOrgId);
  }

  getDetails(hospitalOrgId:any){
  this.hospitalRegistrationService.getDetails(hospitalOrgId)
  .subscribe({
    next:(data:any)=>{
      this.detailList = data;
      this.dataSource.data = this.detailList;
    },
    error:(error:any)=>{
      console.log(error);
    }
  })
  }

  add(hospitalOrgId:any){
    let addReceivingEmail = this.dialog.open(AddReceivingEmailComponent,{
      disableClose:true,
      data:hospitalOrgId,
      width: '700px',
      height: '700px',
    });

    addReceivingEmail.afterClosed().subscribe({
      next:(val:any)=>{
        console.log(val);
          this.getDetails(hospitalOrgId);
      }
    });
  }

  update(row:any){
    console.log(row);
    let updateReceivingEmail = this.dialog.open(UpdateReceivingEmailComponent,{
      disableClose:true,
      data:row,
      width: '700px',
      height: '700px',
    });

    updateReceivingEmail.afterClosed().subscribe({
      next:(val:any)=>{
        console.log(val);
          this.getDetails(this.hospitalOrgId);
      }
    });
  }


}
