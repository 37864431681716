import { Component } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { elementAt, first } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/authService';
import { CustomerService } from 'src/app/services/customer.service';
import { ClaimOrdersDetailsComponent } from './claim-orders-details/claim-orders-details.component';
import { DomSanitizer } from "@angular/platform-browser";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { DatePipe , DecimalPipe } from '@angular/common';





@Component({
  selector: 'app-claim-orders',
  templateUrl: './claim-orders.component.html',
  styleUrls: ['./claim-orders.component.scss'],
  providers: [DecimalPipe]
})
export class ClaimOrdersComponent {



  claimOrders: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  pageSizeOptions = [5, 10, 25];

  message: any;
  coupons: any;
  hpcMessage: any;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  user: any;
  downloadJsonHref: any;
  pageEvent: PageEvent = new PageEvent;
  transactionDate:any;



  dataSource = new MatTableDataSource<any>(this.claimOrders);
  displayedColumns: string[] = ['Name', 'Amount', 'productName','TransactionDate', 'TransactionStatus', 'Gst', 'processStatus','Action', 'Download'];
  loading: boolean = false;
  constructor(private adminService: AdminService, private _dialog: MatDialog,    
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private authService: AuthService, private customerService: CustomerService, private sanitizer: DomSanitizer) {
    this.authService.user.subscribe(x => this.user = x);
    console.log("user",this.user);

  }


  ngOnInit(): void {
    this.getClaimOrders();
    //this.generatePdf();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getClaimOrders();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  getClaimOrders() {
    this.customerService.getClaimOrders(this.pageIndex * this.pageSize, this.pageSize).pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.claimOrders = data;
          //console.log("orders data", this.claimOrders);
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          this.message = error;

          //sthis.loading = false;
        }
      });
  }




  openDetails(data: any) {
    const dialogRef = this._dialog.open(ClaimOrdersDetailsComponent, {
      disableClose:true,
      width: '80%',
      //height: '60%',
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getClaimOrders();
        }
      },
    });
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }


  handleInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query != "") {
      this.claimOrders = query === "" ? this.claimOrders : this.claimOrders.filter((item: any) =>
        item?.memberName?.toLowerCase().includes(query.toLowerCase()));
    } else {
      this.getClaimOrders();
    }

  }
  // generateDownloadJsonUri(data: any) {
  //   //console.log("ty", data);
  //   let info: any[][] = [];
  //   let dt = JSON.stringify(data);
  //   let tt = JSON.parse(dt);
  //   //  tt.forEach((element: any,index: any,array: any)=>{
  //   //  info.push([element.name,element.amount,element.gst])
  //   //  })
  //   info.push([tt.memberName, tt.amount, tt.couponId, tt.discountPercent, tt.orderSource, tt.productId, tt.transactionDate, tt.transactionStatus, tt.gst])
  //   const doc = new jsPDF();
  //   // const doc = new jsPDF({
  //   //   orientation: "landscape",
  //   //   unit: "in",
  //   //   format: [4, 2]
  //   // });
  //   autoTable(doc, {
  //     head: [['Member Name', 'Amount', 'Coupon Id', 'Discount Percent', 'Order Source', 'productId', 'Transaction Date', 'Transaction Status', 'Gst']],
  //     // body: [info,[{colSpan: 2, rowSpan: 2, styles: { halign: 'center' } }]],

  //     body: info
  //     // body: [
  //     //   [{ content: 'Text', colSpan: 2, rowSpan: 2, styles: { halign: 'center' } }],
  //     // ],
  //   })

  //   doc.save('orders.pdf')
  // }






  generateDownloadJsonUri(data: any) {
    const hobnobData = {
      Name: 'BG Networks Private Limited',
      Title: '(Formerly known as HGDS Ventures Private Limited)',
      Address: '503, 5th Floor, Odyssey IT Park',
      Addressline1:
        'Plot No.A-124 & A-299, Wagle Industrial Estate, Thane West',
      City: 'Thane',
      State: 'Maharashtra | 400604',
      Gst: 'GST: 27AAECH5776MZX',
    };
 
    const customerData = {
      Name: this.user.firstName + this.user.lastName,
      //Name1: '(RUCKMANI DEVI)',
      Title: 'Customer Details',
      Email: this.user.emailId,
      Number: this.user.mobilePhone,
      City: this.user.city,
    };
 
    // Sample items data
    const itemsData = [
      { 
        //itemName: 'Water Tanks',
        itemName: data.memberName?data.memberName:"NA",
       // quantity: '',
        //uom: 'Liters',
        uom: data.policyNumber,
        // unitPrice: '1200',
        //total: (15 * 1200).toString(),
        //total: (data.amount * 1200).toString(),
        total:this.decimalPipe.transform(data.hobnobProduct.price,'1.2-2')!.toString(),

      },
      {
        itemName: '',
        //quantity: '',
        uom: 'IGST 18%',
        // unitPrice: '1200',
        // total: (15 * 1200).toString(),
        //total: (data.amount * 18).toString(),
        total: ((data.hobnobProduct.price * 18)/100).toString(),



      },
      {
        itemName: 'Total Amount with 18% GST ',
        //quantity: '',
        uom: '',
        // unitPrice: '1200',
        //total: (15 * 1200).toString(),
        total: this.decimalPipe.transform(data.amount,'1.2-2')!.toString()
      },
      {
        itemName :'Transaction Id',
        uom : data.transactionId,
        total: data.transactionStatus,
      }
 
      // Add other items here...
    ];
 
    // Your PDF generation logic here
    const pdf = new jsPDF();
 
    // Set document properties
    pdf.setProperties({
      title: 'Request For Quotation',
    });
 
    // Add images and text to the PDF
    const imageUrl =
      //'https://images.unsplash.com/photo-1505533321630-975218a5f66f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
      '../../../../../assets/img/bg_logo_final-1-300x73.png';
      //'../../../../../assets/img/bg-logo.png';
 
    pdf.setFontSize(16);
    pdf.setFont('Roboto', 'bold');
   // pdf.text('Tax Invoice: # 000852', 16, 25);
    pdf.setFontSize(14);
    pdf.setFont('Roboto', 'light');
    //pdf.text('Date: # 000852', 16, 32);
   this.transactionDate =  this.datePipe.transform(data.transactionDate, 'dd-MM-yyyy');
   console.log("trnasdate",this.transactionDate);
  // pdf.text('Date:',this.transactionDate, 16);
   pdf.text("Date:" + this.transactionDate , 16,32)

       // this.startDate =  this.datePipe.transform(this.data.startDate, 'dd-MM-yyyy');

    pdf.addImage(imageUrl, 'JPEG', 150, 20, 40, 12);
    // Line width in units (you can adjust this)
    pdf.setLineWidth(0.2);
    pdf.setDrawColor(200, 200, 200);
 
    // customer data
     pdf.line(10, 37, 200, 37);
    pdf.setFontSize(12);
    pdf.text(`${customerData.Title}`, 16, 52);
    pdf.setFont('Roboto', 'normal');
    pdf.text(`${customerData.Name}`, 16, 62);
   // pdf.text(`${customerData.Name1}`, 16, 67);
    pdf.text(`${customerData.Email}`, 16, 68);
    pdf.text(`${customerData.Number}`, 16, 74);
    pdf.text(`${customerData.City}`, 16, 82);
 
    // hobnob data
    pdf.setFontSize(11);
    pdf.setFont('Roboto', 'normal');
    pdf.text(`${hobnobData.Name}`, 145 , 62);
    pdf.text(`${hobnobData.Title}`, 104, 68);
    pdf.text(`${hobnobData.Address}`, 142, 74);
    pdf.text(`${hobnobData.Addressline1}`, 95, 80);
    pdf.text(`${hobnobData.City}`, 182, 86);
    pdf.text(`${hobnobData.State}`, 160, 92);
    pdf.text(`${hobnobData.Gst}`, 150, 98);
 
    pdf.setFont('Roboto', 'normal');
    pdf.setFontSize(10);
 
    // Generate AutoTable for item details
    const itemDetailsRows = itemsData?.map((item, index) => [
      // (index + 1).toString(),
      item.itemName.toString(),//yu//
      //item.quantity?.toString(),
      item.uom?.toString(),
      item.total?.toLocaleString(),
    ]);
    const itemDetailsHeaders = [
      // 'S.No',
      'Name',
      //'Insurance Type',
      'Policy Number',
      'Amount',
    ];
    const columnWidths = [60, 90, 40]; // Adjust column widths as needed
    // Define table styles
    const headerStyles = {
      fillColor: [240, 240, 240],
      textColor: [0],
      fontFamily: 'Roboto',
      fontStyle: 'bold',
    };
 
    pdf.setFont('Roboto');
    const itemDetailsYStart = 110;
    (pdf as any).autoTable({
      head: [itemDetailsHeaders],
      body: itemDetailsRows,
      startY: itemDetailsYStart, // Adjust the Y position as needed
      headStyles: {
        fillColor: headerStyles.fillColor,
        textColor: headerStyles.textColor,
        fontStyle: headerStyles.fontStyle,
        fontSize: 10, // Adjust the font size as needed
        font: 'Newsreader', // Set the font family
        halign: 'left',
      },
      columnStyles: {
        0: { cellWidth: columnWidths[0] }, // Adjust column widths as needed
        1: { cellWidth: columnWidths[1] },
        2: { cellWidth: columnWidths[2] },
        3: { cellWidth: columnWidths[3] },
        //4: { cellWidth: columnWidths[4] },
      },
      alternateRowStyles: { fillColor: [255, 255, 255] },
      bodyStyles: {
        fontSize: 10, // Adjust the font size for the body
        font: 'Newsreader', // Set the font family for the body
        cellPadding: { top: 1, right: 5, bottom: 1, left: 2 }, // Adjust cell padding
        textColor: [0, 0, 0], // Set text color for the body
        rowPageBreak: 'avoid', // Avoid row page breaks
      },
      margin: { top: 10, left: 13 },
    });
 
    // hobnob data
    // pdf.setLineWidth(0.2);
    // pdf.setDrawColor(200, 200, 200);
    // pdf.line(10, 80, 200, 80);
    // pdf.setFont('Roboto', 'normal');
    // pdf.setFontSize(9);
    // pdf.text(`${hobnobData.Name}`, 80, 85);
    // pdf.setFontSize(8);
    // pdf.text(`${hobnobData.Title}`, 65, 92);
    // pdf.text(`${hobnobData.Address}`, 80, 95);
    // pdf.text(`${hobnobData.Addressline1}`, 64, 100);
    // pdf.text(`${hobnobData.City}`,95, 105);
    // pdf.text(`${hobnobData.State}`, 85, 109);
    // pdf.text('GST: 27AAECH5776MZX | CIN: U74999MH2018PTC310945', 64,112);
    // pdf.text('+91 8448444158 | Email: support@bimagarage.com', 67,116);
    
    pdf.setLineWidth(0.2);
    pdf.setDrawColor(200, 200, 200);
    pdf.line(10, 140, 200, 140);
    pdf.setFont('Roboto', 'strong');
    pdf.setFontSize(10);
    pdf.text(`${hobnobData.Name}`, 78, 146);
    pdf.setFont('Roboto', 'normal');
    pdf.setFontSize(8);
    pdf.text(`${hobnobData.Title}`, 67, 152);
    pdf.text(`${hobnobData.Address}`, 80, 157);
    pdf.text(`${hobnobData.Addressline1}`, 64, 161);
    pdf.text(`${hobnobData.City}`,93, 164);
    pdf.text(`${hobnobData.State}`, 85, 167);
    pdf.text('GST: 27AAECH5776MZX | CIN: U74999MH2018PTC310945', 64,173);
    pdf.text('+91 8448444158 | Email: support@bimagarage.com', 67,178);
    pdf.save('order.pdf');
  }

}
