<div class="container p-3">
    <div mat-dialog-title>
        <div class="row">
            <!-- <div class="col-8 d-flex justify-content-start">
          <h1>View Mail Conf</h1>
        </div> -->
            <div class="d-flex justify-content-end">
                <button style="color:white;background:#8e0101;" (click)="add(hospitalOrgId)"
                    mat-mini-fab><mat-icon>add</mat-icon></button>

                &nbsp;<button mat-dialog-close style="color:white;background:#8e0101;"
                    mat-mini-fab><mat-icon>cancel</mat-icon></button>

            </div>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="info">
            <th mat-header-cell *matHeaderCellDef>Info </th>
            <td *matCellDef="let element"> {{ element.info }}</td>
        </ng-container>


        <ng-container matColumnDef="fieldType">
            <th mat-header-cell *matHeaderCellDef> Field Type</th>
            <td *matCellDef="let element">{{ element.fieldType }}</td>
        </ng-container>

        <ng-container matColumnDef="fieldValue">
            <th mat-header-cell *matHeaderCellDef> Field Value</th>
            <td *matCellDef="let element">{{ element.fieldValue }}</td>
        </ng-container>

        <ng-container matColumnDef="fieldName">
            <th mat-header-cell *matHeaderCellDef>Field Name</th>
            <td *matCellDef="let element">{{ element.fieldName }}</td>
        </ng-container>

        <ng-container matColumnDef="fieldCategory">
            <th mat-header-cell *matHeaderCellDef>Field Category</th>
            <td *matCellDef="let element">{{ element.fieldCategory }}</td>
        </ng-container>

        <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Update</th>
            <td *matCellDef="let row">
                <button mat-icon-button style="color: #8e0101" (click)="update(row)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" style="text-align: center;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator #pagintor [length]="dataSource.data.length" [pageSizeOptions]="[5,10,20]"
        showFirstLastButtons></mat-paginator>
    <br />

    <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
        {{ message }}
    </div>
</div>