<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-center">
        <h1>Add Hospital</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="container">
        <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
            <mat-step [stepControl]="firstFormGroup">
              <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Add a Hospital</ng-template>
                <div class="p-2">
                  <mat-form-field>
                    <mat-label>Partner Type</mat-label>
                    <mat-select formControlName="firstCtrl" [(ngModel)]="selectedType" name="partner">
                      <mat-option *ngFor="let partnerType of partnerTypes" [value]="partnerType.id">
                        {{ partnerType.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="p-2 text-end">
                  <button mat-button matStepperNext>Next</button>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="form" label="Organisation details">
              <form [formGroup]="form">
                <div class="p-2">
        
                  <div class="col">
                    <mat-form-field appearance="fill">
                      <mat-label>Hospital Name (Type in the first three letters to check if we have your details.)
        
                        <span class="spinner-grow spinner-grow-sm" *ngIf="isLoading" aria-hidden="true"></span>
        
        
                      </mat-label>
                      <input matInput [formControl]="searchControl" [matAutocomplete]="auto" placeholder="Your Org name"
                        required />
        
        
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayHospitalName"
                        (optionSelected)="onOptionSelected($event)">
                        <mat-option *ngFor="let hospital of hospitals$ | async" [value]="hospital">
                          {{ hospital.name }} {{hospital.address}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <mat-label>Rohini ID</mat-label>
                      <input matInput formControlName="hospitalUniqueId" placeholder="Rohini ID" required />
                    </mat-form-field>
                  </div>
                </div>
        
                <div class="row p-2">
                  <div class="col">
                    <mat-form-field>
                      <mat-label>First Name</mat-label>
                      <input matInput formControlName="firstName" placeholder="Contact person's first name" required />
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <mat-label>Second Name</mat-label>
                      <input matInput formControlName="lastName" placeholder="Contact person's last name" required />
                    </mat-form-field>
                  </div>
        
                  <div class="col">
                    <mat-form-field>
                      <mat-label>Password</mat-label>
                      <input matInput formControlName="passwd" type="password" placeholder="Password" required />
                      <mat-error *ngIf="form.get('passwd').hasError('required')">
                        Password is required
                      </mat-error>
                      <mat-error *ngIf="form.get('passwd').hasError('maxlength')">
                        Password must be not more than 10 characters long
                      </mat-error>
                      <mat-error *ngIf="form.get('passwd').hasError('pattern')">
                        Password must contain at least one uppercase letter and one special character
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <mat-label>Confirm Password</mat-label>
                      <input matInput placeholder="Confirm Password" type="password" required
                        formControlName="confirmPassword" />
        
                      <mat-error *ngIf="form.hasError('mismatch') || form.get('confirmPassword').touched">
                        Passwords do not match
                      </mat-error>
                    </mat-form-field>
        
                  </div>
                </div>
        
                <div class="row p-2">
                  <div class="col">
                    <mat-form-field>
                      <mat-label>Hospital Email</mat-label>
                      <input matInput formControlName="email" placeholder="Hospital email" required />
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <mat-label>Mobile</mat-label>
                      <input matInput formControlName="mobileNumber" placeholder="Contact person's mobile number" required />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row p-2">
                  <mat-form-field>
                    <mat-label>Website</mat-label>
                    <input matInput formControlName="website" placeholder="Your organization's web address" />
                  </mat-form-field>
                </div>
        
                <div class="row p-2">
                  <div class="col">
                    <mat-form-field>
                      <mat-label>Address</mat-label>
                      <input matInput formControlName="addLine1" placeholder="Address" required />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row p-2">
        
                  <div class="col">
                    <mat-form-field>
                      <mat-label>City</mat-label>
                      <input matInput formControlName="city" placeholder="City" required />
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <mat-label>State</mat-label>
                      <input matInput formControlName="state" placeholder="State" required />
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <mat-label>Country</mat-label>
                      <input matInput formControlName="country" placeholder="Country" required />
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field>
                      <mat-label>Pin</mat-label>
                      <input matInput formControlName="zipcode" placeholder="Pin code" required />
                    </mat-form-field>
                  </div>
                </div>
        
                <div class="p-2 text-end">
                  <button mat-button matStepperPrevious>Back</button>
                  <button mat-button matStepperNext [disabled]="!form.valid">Next</button>
                </div>
              </form>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Done</ng-template>
              <!-- <p>You are now done. Please click on the register button.</p> -->
              <div class="p-2 text-end">
                <!-- <button mat-button matStepperPrevious>Back</button> -->
                <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
                <button type="button" class="btn btn-danger" [disabled]="clicked" (click)="register();clicked = true;">
                  Submit
                </button>
              </div>
            </mat-step>
          </mat-stepper>
    </div>  
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="accent" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

