<div class="container p-3">
    <div class=" p-3" style="text-align:right">
      <button mat-flat-button type="button" style="color: white; background: #8e0101;" (click)="addRecipients()">Add </button>
    </div>
    <div class="row pt-3 pb-3 justify-content-md-center">
      <div class="col">
        <input type="search"  (input)="filterByRecipientGroup($event)" class="form-control" placeholder="Filter by recipient group" aria-label="Search" />
      </div>
      
    </div>
    
</div>
<div class="container p-3">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef>Date Created </th>
            <td mat-cell *matCellDef="let element">{{element.dateCreated | date}}</td>
        </ng-container>

         <ng-container matColumnDef="contactNumber">
            <th mat-header-cell *matHeaderCellDef>Contact Number </th>
            <td mat-cell *matCellDef="let element">{{element.contactNumber}}</td>
        </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email </th>
            <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>


        <ng-container matColumnDef="recipientGroup">
            <th mat-header-cell *matHeaderCellDef>Recipient Group</th>
            <td mat-cell *matCellDef="let element">{{element.recipientGroup}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns : displayedColumns;"></tr>
        
    </table>
    <mat-paginator #paginator [length]="dataSource.data.length" [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
</div>