<!-- <div class="container py-1 p-5   p-4" > -->
<!-- <div class="container mh-800 card overflow-y-scroll" > -->
<section>

    <div class="container">


        <!-- Old -->
        <div class="container">
            <div mat-dialog-title>
                <div class="row">
                    <div class="col-8 d-flex justify-content-start">
                        <h1>View Reimbursement </h1>
                    </div>
                    <div class="col-4 d-flex justify-content-end " mat-dialog-close>
                        <h4><i class="px-3" style="color:lightgray">* marked fields are mandatory </i></h4>
                        <mat-icon color="accent">cancel</mat-icon>
                    </div>
                </div>
            </div>

            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">widgets</mat-icon>
                        Workflow
                    </ng-template>
                    <div class="" *ngIf="diagram != undefined">
                        <img [src]="diagram" />
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">list</mat-icon>
                        Task Details
                    </ng-template>

                    <div class="card-body">
                        <ul>
                            <li>
                                <b>SOP:</b>
                                <div [innerHTML]="selectedTask?.description"></div>
                            </li>
                            <li *ngIf="selectedTask?.dueDate != null">
                                <b>TAT:</b>
                                <span style="color: red">
                                    {{ selectedTask?.dueDate | date : "long" }}</span>
                            </li>
                            <li style="color: #c00000" *ngIf="selectedTask?.caseVariables?.policy == null">
                                <b>Note:</b> The policy hasn't been uploaded!
                            </li>
                            <li style="color: #c00000" *ngIf="selectedTask?.caseVariables?.order?.memberName == ''">
                                <b>Note:</b> The member name has not been provided!
                            </li>
                        </ul>
                        <hr />
                        <div>
                            <ul>
                                <li>
                                    <b>CUSTOMER:</b>
                                    {{ selectedTask?.caseVariables?.customer?.firstName }}
                                    {{ selectedTask?.caseVariables?.customer?.firstName }}
                                </li>
                                <li>
                                    <b>Email:</b>
                                    {{ selectedTask?.caseVariables?.customer?.emailId }}
                                </li>
                                <li>
                                    <b>Mobile:</b>
                                    {{
                                    selectedTask?.caseVariables?.customer?.mobilePhone
                                    }}
                                </li>
                            </ul>

                            <ul class="collapse" id="collapse-Example">
                                <li>
                                    <b>ORDER:</b>
                                    {{ selectedTask?.caseVariables?.order?.id }}
                                </li>
                                <li>
                                    <b>ORDER Date:</b>
                                    {{
                                    selectedTask?.caseVariables?.order?.transactionDate
                                    | date
                                    }}
                                </li>
                                <li>
                                    <b>Product:</b>
                                    {{ selectedTask?.caseVariables?.product?.name }}
                                </li>
                                <li>
                                    <b>Payment Status:</b>
                                    {{
                                    selectedTask?.caseVariables?.order?.transactionStatus
                                    | json
                                    }}
                                </li>
                                <li *ngIf="selectedTask?.caseVariables?.order?.memberName != '' ">
                                    <b>Member Name:</b>
                                    {{ selectedTask?.caseVariables?.order?.memberName }}
                                </li>
                            </ul>

                            <hr />
                            <ul *ngIf="selectedTask?.caseVariables?.policy != null">
                                <li>
                                    <b>POLICY:</b>
                                    {{ selectedTask?.caseVariables?.policy?.policyNumber }}
                                </li>
                                <li>
                                    <b>Policy Link:</b>
                                    {{ selectedTask?.caseVariables?.policy?.policyPath }}
                                </li>
                                <li>
                                    <b>eKYP</b>

                                    <div class="row">
                                        <div class="col">
                                            Insurance Company :
                                            {{
                                            selectedTask?.caseVariables?.policy?.ekypData?.insurance_company
                                            }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            Insurance Product :
                                            {{
                                            selectedTask?.caseVariables?.policy?.ekypData?.insurance_name
                                            }}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            Policy Start :
                                            {{
                                            selectedTask?.caseVariables?.policy?.ekypData?.start_date
                                            }}
                                        </div>
                                        <div class="col">
                                            Policy End :
                                            {{
                                            selectedTask?.caseVariables?.policy?.ekypData?.end_date
                                            }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            Policy Type :
                                            {{
                                            selectedTask?.caseVariables?.policy?.ekypData?.policy_type
                                            }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            Ported Policy :
                                            {{
                                            selectedTask?.caseVariables?.policy?.ekypData?.portedPolicy
                                            }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            Sum Insured :
                                            {{
                                            selectedTask?.caseVariables?.policy?.sumInsured
                                            }}
                                        </div>
                                    </div>
                                    <hr />
                                </li>
                            </ul>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">cloud_upload</mat-icon>
                        Upload Files
                    </ng-template>
                    <div class="p-3">
                        <div class="card-body text-center">
                            <div class="row">
                                <div class="col-2">
                                    <strong>Attachments</strong>
                                </div>
                                <div class="col-10">
                                    <div class="horizontal-scroller">
                                        <div class="scroll-content">
                                          <div *ngFor="let item of healthFiles" class="button-container">
                                            <button 
                                              mat-flat-button 
                                              (click)="viewHealthFile(item)"
                                              class="health-file-button">
                                              <span>{{ item.category }}</span>
                                            </button>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr>
                            
                              
                              
                            <!-- <app-attachment-scroller [data]="healthFiles"
                                (fileSelected)="getExternalMediaById($event)"></app-attachment-scroller> -->
                            <div class="row pt-2">

                                <select id="category" class="form-select" aria-label="Default select example"
                                    required="true" (change)="selectFileCategory($event)">
                                    <option value="" selected disabled>Select a category</option>
                                    <option value="PRESCRIPTION">Prescription</option>
                                    <option value="REPORT">Report</option>
                                    <option value="SUMMARY">Summary</option>
                                    <option value="policy">Policy</option>
                                    <option value="OTHERS">Others</option>
                                </select>

                            </div>
                            <div class="row pt-2">

                                <label class="btn btn-default p-0">
                                    <input class="form-control" type="file"
                                        accept="application/pdf,image/png,image/jpeg,image/jpg"
                                        (change)="selectHealthCloudFile($event)" />
                                </label>

                            </div>
                            <div class="row pt-2">
                                <div class="col text-end">
                                    <button class="btn btn-success btn-sm"
                                        [disabled]="selectedFile == undefined || selectedCategory == undefined"
                                        (click)="uploadHealthCloudFilesForReimbursement()">
                                        Upload
                                    </button>
                                </div>
                            </div>


                            <div *ngIf="currentFile" class="progress my-3">
                                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                    attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                                    [ngStyle]="{ width: progress + '%' }">
                                    {{ progress }}%
                                </div>
                            </div>
                        </div>
                    </div>


                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">attach_file</mat-icon>
                        eKYP
                    </ng-template>
                    <div class=" p-2 m-2" *ngIf="selectedTask">
                        <div class="card-body">
                            <h3 class="card-title">KYP</h3>

                            <ng-container *ngIf="memberName == null">
                                <form [formGroup]="secondFormGroup">
                                    <ng-template matStepLabel>Select a policy</ng-template>
                                    <mat-form-field>
                                        <mat-label>Policy</mat-label>
                                        <mat-select (selectionChange)="onMemberChange($event)"
                                            formControlName="memberName">
                                            <mat-option *ngFor="let file of policyFiles" [value]="file">
                                                PDF - {{parseId(file.policyPath)}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </form>
                                <div *ngIf="secondFormGroup.invalid" class="alert alert-primary" role="alert">
                                    Please select a policy to view or add EKYP
                                </div> 
                            </ng-container>
                            <div class="addbtn">
                                <button
                                    *ngIf="memberName?.ekypData == null && selectedTask.caseVariables.ekypData == null"
                                    type="button" class="btn btn-outline-primary" (click)="
                            openAddForm(memberName)
                          ">
                                    Add
                                </button>


                                <button *ngIf="memberName?.ekypData != null && addKYP != true" type="button"
                                    class="btn btn-outline-primary" (click)="
                            openEditForm(
                             memberName,
                              selectedTask.caseVariables.order.id,
                              selectedTask.id
                            )
                          ">
                                    View
                                </button>
                                <button *ngIf="memberName?.ekypData != null && addKYP == true" type="button"
                                    class="btn btn-outline-primary" (click)="
                          openEditForm(
                           memberName.ekypData,
                            selectedTask.caseVariables.order.id,
                            selectedTask.id
                          )
                        ">
                                    Update
                                </button>

                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf=" policyFiles !== undefined && policyFiles.length > 0"
                        class=" p-4 m-2 overflow-auto w-10 custom-scrollbar">
                        <div class="row">
                            <div class="col-12 boder-bottom" *ngFor="let file of policyFiles">
                                <div (click)="openViewFile(file, 'POLICY')" class="your-item-style">
                                    <div class="col-1">
                                        <mat-icon>assignment</mat-icon>
                                    </div>
                                    <div class="col-11">
                                        <strong>Policy</strong>
                                        <p>
                                          
                                            {{ file.dateCreated | date }}<br />
                                            PDF
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">thumb_up</mat-icon>
                        Case details
                    </ng-template>
                    <div class="card-body">
                        <div *ngIf="selectedTask?.caseVariables?.WelcomeSOPChecked" class="m-2">
                            <th>Welcome SOP Checked:</th>
                            <td>
                                {{ selectedTask?.caseVariables?.WelcomeSOPChecked }}
                            </td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.caseStatus" class="m-2">
                            <th>Case Status:</th>
                            <td>{{ selectedTask?.caseVariables?.caseStatus }}</td>
                        </div>

                        <div *ngIf="selectedTask?.caseVariables?.claimIntimationNumber" class="m-2">
                            <th>Claim Intimation Number :</th>
                            <td>
                                {{
                                selectedTask?.caseVariables?.claimIntimationNumber
                                }}
                            </td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.pickupStatus" class="m-2">
                            <th>PickUp Status :</th>
                            <td>{{ selectedTask?.caseVariables?.pickupStatus }}</td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.pickupDate" class="m-2">
                            <th>Pickup Date:</th>
                            <td>
                                {{
                                selectedTask?.caseVariables?.pickupDate
                                | date : "short"
                                }}
                            </td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.pickupTime" class="m-2">
                            <th>Pickup Time:</th>
                            <td>{{ selectedTask?.caseVariables?.pickupTime }}</td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.pickupStatus" class="m-2">
                            <th>PickUp Status :</th>
                            <td>{{ selectedTask?.caseVariables?.pickupStatus }}</td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.contactName" class="m-2">
                            <th>Contact Name :</th>
                            <td>{{ selectedTask?.caseVariables?.contactName }}</td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.contactAddress" class="m-2">
                            <th>Contact Address :</th>
                            <td>
                                {{ selectedTask?.caseVariables?.contactAddress }}
                            </td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.contactNumber" class="m-2">
                            <th>Contact Number :</th>
                            <td>
                                {{ selectedTask?.caseVariables?.contactNumber }}
                            </td>
                        </div>

                        <div *ngIf="selectedTask?.caseVariables?.pickedup" class="m-2">
                            <th>Pick Up Status:</th>
                            <td>{{ selectedTask?.caseVariables?.pickedup }}</td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.documentsReceived" class="m-2">
                            <th>Documents Received Status:</th>
                            <td>
                                {{ selectedTask?.caseVariables?.documentsReceived }}
                            </td>
                        </div>
                        <div *ngIf="
                  selectedTask?.caseVariables?.noOfDocumentsCollected
                " class="m-2">
                            <th>No of documents:</th>
                            <td>
                                {{
                                selectedTask?.caseVariables?.noOfDocumentsCollected
                                }}
                            </td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.delivered" class="m-2">
                            <th>Documents Delivery Status:</th>
                            <td>{{ selectedTask?.caseVariables?.delivered }}</td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.kycCompleted" class="m-2">
                            <th>KYC Status :</th>
                            <td>{{ selectedTask?.caseVariables?.kycCompleted }}</td>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.medicalVerified" class="m-2">
                            <th>Medical Verification Status :</th>
                            <td>
                                {{ selectedTask?.caseVariables?.medicalVerified }}
                            </td>
                        </div>
                        <div *ngIf="
                  selectedTask?.caseVariables?.claimProcessingStatus
                " class="m-2">
                            <th>Claim Processing Status :</th>
                            <td>
                                {{
                                selectedTask?.caseVariables?.claimProcessingStatus
                                }}
                            </td>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <hr>
            <div class="row">
                <div class="col">
                    <div *ngIf="selectedTask != undefined && selectedTask.name !='Process eKYP'">
                        <!-- CLaim check to be used later using the Machine learning modules-->
                        <!--<mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <strong>Claim Check</strong>
                                </mat-expansion-panel-header>
                                <div>
                                    <div class="col-12 boder-bottom">
                                        <p>Select Summary</p>
                                        <mat-form-field>
                                            <mat-label>Choose an option</mat-label>
                                            <mat-select>
                                                <mat-option *ngFor="
                                      let category of results.healthCloudModelFiles
                                    " [value]="category.id" (onSelectionChange)="selectedSummary(category)">
                                                    {{
                                                    category.docPath.split("_").pop()
                                                    }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 boder-bottom">
                                        <p>Select Policy</p>
                                        <mat-form-field>
                                            <mat-label>Choose an option</mat-label>
                                            <mat-select>
                                                <mat-option *ngFor="let file of results.healthCloudModelFiles"
                                                    [value]="file.id" (onSelectionChange)="selectedPolicy(file)">
                                                    {{ file.docPath.split("_").pop() }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="text-end">
                                        <button type="button" class="btn btn-danger" (click)="submitForClaimCheck()"
                                            [disabled]="claimCheck" style="disabled: opacity 0.5em">
                                            Check Status
                                        </button>
                                        <span *ngIf="claimCheck === true"
                                            class="spinner-border spinner-border-sm mx-3"></span>
                                    </div>
                                </div>
                                <br />
                                
                                <table *ngIf="databody">
                                    <thead>
                                        <tr>
                                            <th>Parameters</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Patient Name</td>
                                            <td>{{ databody.patientName }}</td>
                                        </tr>
                                        <tr>
                                            <td>History From Discharge Summary</td>
                                            <td>{{ databody.historyFromDischargeSummary }}</td>
                                        </tr>
                                        <tr>
                                            <td>PED From Summary</td>
                                            <td>{{ databody.pedFromPolicy }}</td>
                                        </tr>
                                        <tr>
                                            <td>Disease</td>
                                            <td>{{ databody.disease }}</td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td [style.color]="
                                    databody.status === 'SUCCESS' ? 'green' : 'red'
                                  ">
                                                <strong>{{ databody.status }}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-expansion-panel>
                        </mat-accordion>-->
                        <div *ngIf="selectedTask?.caseVariables?.userComment" class="m-2">
                            <h3>Previous Comment</h3>
                        </div>
                        <div *ngIf="selectedTask?.caseVariables?.userComment" class="alert alert-secondary p-2 m-2"
                            role="alert">
                            {{ selectedTask?.caseVariables?.userComment }}
                        </div>
                        <div class=" p-2 m-2">
                            <form [formGroup]="dynamicFormGroup" *ngIf="dynamicFormGroup != undefined">
                                <div *ngFor="let field of fields">
                                    <div class="row m-2 p-2" *ngIf="field.property.type.name == 'boolean'">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <select [formControlName]="field.property.id" class="form-select"
                                                aria-label="Default select example" required="true" [value]="false">
                                                <option value="true">Accept</option>
                                                <option value="false">Reject</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row m-2 p-2" *ngIf="
                                field.property.type.name == 'string' &&
                                field.property.id != 'fileUpload'
                              ">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <textarea class="form-control" required="true"
                                                [formControlName]="field.property.id"></textarea>
                                        </div>
                                    </div>

                                    <div class="row m-2 p-2" *ngIf="
                                field.property.type.name == 'string' &&
                                field.property.id == 'fileUpload'
                              ">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <label class="btn btn-default p-0">
                                                <input type="file" [formControlName]="field.property.id"
                                                    (change)="selectFile($event)" />
                                            </label>
                                            <ul class="list-group list-group-flush">
                                                <li *ngFor="let file of fileInfos | async" class="list-group-item">
                                                    <a href="{{ file.url }}">{{ file.name }}</a>
                                                </li>
                                            </ul>
                                            <div>
                                                <button class="btn btn-success btn-sm" [disabled]="
                                      selectedFiles == undefined ||
                                      selectedCategory == undefined
                                    " (click)="uploadHealthCloudFiles()">
                                                    Upload
                                                </button>
                                            </div>
                                            <div class="progress-bar progress-bar-info progress-bar-striped"
                                                role="progressbar" attr.aria-valuenow="{{ progress }}" aria-valuemin="0"
                                                aria-valuemax="100" [ngStyle]="{ width: progress + '%' }">
                                                {{ progress }}%
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-2 p-2" *ngIf="field.property.type.name == 'date'">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <input type="date" class="form-control" required="true"
                                                [formControlName]="field.property.id" [min]="minDate" />
                                        </div>
                                    </div>

                                    <div class="row m-2 p-2" *ngIf="field.property.type.name == 'enum'">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <select [formControlName]="field.property.id" class="form-select"
                                                aria-label="Default select example" required="true" (change)="
                                    selectCategory($event, field.property.name)
                                  ">
                                                <option value="" selected disabled>
                                                    Update status
                                                </option>
                                                <option *ngFor="let option of field.values" [value]="option.id">
                                                    {{ option.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-end">
                                    <button type="button" class="btn btn-danger" [disabled]="
                                dynamicFormGroup != undefined &&
                                dynamicFormGroup.invalid
                              " (click)="executeTask()">
                                        Execute
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div
                        *ngIf="selectedTask != undefined && selectedTask?.name =='Process eKYP' && getpolicyFiles!= undefined">
                        <div class="" *ngIf="diagram != undefined">
                            <img [src]="diagram" />
                        </div>
                        <mat-accordion class="p-4 m-2">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <strong>Task details</strong>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="card-body">
                                    <ul>
                                        <li>
                                            <b>SOP:</b>
                                            <div [innerHTML]="selectedTask?.description"></div>
                                        </li>
                                        <li *ngIf="selectedTask?.dueDate != null">
                                            <b>TAT:</b>
                                            <span style="color: red">
                                                {{ selectedTask?.dueDate | date : "long" }}</span>
                                        </li>
                                        <li style="color: #c00000"
                                            *ngIf="selectedTask?.caseVariables?.policyId == null">
                                            <b>Note:</b> The policy hasn't been uploaded!
                                        </li>
                                        <li style="color: #c00000" *ngIf="
                                  selectedTask?.caseVariables?.customerUserId == ''
                                ">
                                            <b>Note:</b> The member name has not been provided!
                                        </li>
                                    </ul>
                                    <hr />
                                    <div>
                                        <ul>
                                            <li>
                                                <b>CUSTOMER:</b>
                                                {{ selectedTask?.caseVariables?.customerUserId?.firstName }}
                                                {{ selectedTask?.caseVariables?.customerUserId?.lastName }}
                                            </li>
                                            <li>
                                                <b>Email:</b>
                                                {{ selectedTask?.caseVariables?.customerUserId?.emailId }}
                                            </li>
                                            <li>
                                                <b>Mobile:</b>
                                                {{
                                                selectedTask?.caseVariables?.customerUserId?.mobilePhone
                                                }}
                                            </li>
                                        </ul>



                                        <hr />
                                        <ul *ngIf="getpolicyFiles != null">
                                            <li>
                                                <b>POLICY:</b>
                                                {{ getpolicyFiles.policyNumber }}
                                            </li>
                                            <li>
                                                <b>Policy Link:</b>
                                                {{ getpolicyFiles.policyPath }}
                                            </li>
                                            <li>
                                                <b>eKYP:</b>

                                                <div class="row">
                                                    <div class="col">

                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">

                                                    </div>
                                                </div>


                                                <hr />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>


                        <div class=" p-2 m-2" *ngIf="getpolicyFiles.policyNumber == 'Processing Failed'">
                            <div class="card-body">
                                <h3 class="card-title">KYP</h3>
                                <div class="addbtn">
                                    <button type="button" *ngIf="selectedTask.caseVariables?.policy == null"
                                        class="btn btn-outline-primary" (click)="
                                openAddForm(selectedTask.caseVariables.customerUserId.id,getpolicyFiles)
                              ">
                                        Add
                                    </button>

                                    <button *ngIf="selectedTask.caseVariables.policy != null" type="button"
                                        class="btn btn-outline-primary" (click)="
                                openEditForm(
                                  selectedTask.caseVariables.policy,
                                  selectedTask.caseVariables?.order?.id,
                                  selectedTask.id
                                )
                              ">
                                        Update
                                    </button>

                                </div>

                            </div>
                        </div>



                        <div class=" p-2 m-2">
                            <form [formGroup]="dynamicFormGroup" *ngIf="dynamicFormGroup != undefined">
                                <div *ngFor="let field of fields">
                                    <div class="row m-2 p-2" *ngIf="field.property.type.name == 'boolean'">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <select [formControlName]="field.property.id" class="form-select"
                                                aria-label="Default select example" required="true" [value]="false">
                                                <option value="true">Accept</option>
                                                <option value="false">Reject</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row m-2 p-2" *ngIf="
                                field.property.type.name == 'string' &&
                                field.property.id != 'fileUpload'
                              ">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <textarea class="form-control" required="true"
                                                [formControlName]="field.property.id"></textarea>
                                        </div>
                                    </div>

                                    <div class="row m-2 p-2" *ngIf="
                                field.property.type.name == 'string' &&
                                field.property.id == 'fileUpload'
                              ">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <label class="btn btn-default p-0">
                                                <input type="file" [formControlName]="field.property.id"
                                                    (change)="selectFile($event)" />
                                            </label>
                                            <ul class="list-group list-group-flush">
                                                <li *ngFor="let file of fileInfos | async" class="list-group-item">
                                                    <a href="{{ file.url }}">{{ file.name }}</a>
                                                </li>
                                            </ul>
                                            <div>
                                                <button class="btn btn-success btn-sm" [disabled]="
                                      selectedFiles == undefined ||
                                      selectedCategory == undefined
                                    " (click)="uploadHealthCloudFiles()">
                                                    Upload
                                                </button>
                                            </div>
                                            <div class="progress-bar progress-bar-info progress-bar-striped"
                                                role="progressbar" attr.aria-valuenow="{{ progress }}" aria-valuemin="0"
                                                aria-valuemax="100" [ngStyle]="{ width: progress + '%' }">
                                                {{ progress }}%
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-2 p-2" *ngIf="field.property.type.name == 'date'">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <input type="date" class="form-control" required="true"
                                                [formControlName]="field.property.id" [min]="minDate" />
                                        </div>
                                    </div>

                                    <div class="row m-2 p-2" *ngIf="field.property.type.name == 'enum'">
                                        <div class="col">
                                            <b>{{ field.property.name }}</b>
                                        </div>
                                        <div class="col">
                                            <select [formControlName]="field.property.id" class="form-select"
                                                aria-label="Default select example" required="true" (change)="
                                    selectCategory($event, field.property.name)
                                  ">
                                                <option value="" selected disabled>
                                                    Update status
                                                </option>
                                                <option *ngFor="let option of field.values" [value]="option.id">
                                                    {{ option.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-end">
                                    <button type="button" class="btn btn-danger" [disabled]="
                                dynamicFormGroup != undefined &&
                                dynamicFormGroup.invalid
                              " (click)="executeTask()">
                                        Execute
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Process ekyp -->

            <!--<div *ngIf="dynamicFormGroup != undefined">{{dynamicFormGroup.value | json}}</div>-->
        </div>

    </div>
</section>
<!-- <div *ngIf="IsAssign">
              effwef
          </div> -->