<div class="container p-3">
  <div mat-dialog-title>
    <div class="row">
      <!-- <div class="col-8 d-flex justify-content-start">
        <h1>View Mail Conf</h1>
      </div> -->
      <div class="d-flex justify-content-end" >
        <button  style="color:white;background:#8e0101;" (click)="add(hospitalOrgId)" mat-mini-fab><mat-icon>add</mat-icon></button>
        
        &nbsp;<button  mat-dialog-close style="color:white;background:#8e0101;" mat-mini-fab><mat-icon>cancel</mat-icon></button>
        
      </div>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort style="text-align: center;"> 

    <!-- <ng-container matColumnDef="hospitalOrgId">
      <th mat-header-cell *matHeaderCellDef> HospitalOrgId </th>
      <td mat-cell *matCellDef="let element">
        {{ element.hospitalOrgId }}
      </td>
    </ng-container> -->

<!--     
    <ng-container matColumnDef="payorType">
      <th mat-header-cell *matHeaderCellDef> Payor Type </th>
      <td *matCellDef="let element"> {{ element.payorType }} </td>
    </ng-container> -->

    <ng-container matColumnDef="receivingEmailHost">
      <th mat-header-cell *matHeaderCellDef> Receiving EmailHost </th>
      <td *matCellDef="let element">
        {{ element.receivingEmailHost }}
      </td>
    </ng-container>

    <ng-container matColumnDef="receivingEmailId">
      <th mat-header-cell *matHeaderCellDef>Receiving EmailId </th>
      <td *matCellDef="let element">
        {{ element.receivingEmailId }}
      </td>
    </ng-container>

    
    <ng-container matColumnDef="receivingEmailStoreType">
      <th mat-header-cell *matHeaderCellDef>
        Receiving EmailStoreType
      </th>
      <td *matCellDef="let element">
        {{ element.receivingEmailStoreType }}
      </td>
    </ng-container>

    <ng-container matColumnDef="update">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        Update
    </th>
      <td *matCellDef="let row">
        <button mat-icon-button style="color: #8e0101" (click)="update(row)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="test">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        Test
    </th>
      <!-- <td *matCellDef="let row">
        <button
        type="button"
        mat-flat-button
        style="color: white; background: #8e0101"
        (click)="test(row)"
      >
        Test
        </button>
      </td> -->
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" style="text-align: center;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator #pagintor [length]="dataSource.data.length" [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>
  <br />

  <div
    class="alert alert-danger mt-2 pt-1"
    role="alert"
    *ngIf="message != undefined"
  >
    {{ message }}
  </div>
</div>
