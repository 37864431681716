import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/authService';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';

@Component({
  selector: 'app-manage-hospital-configurations',
  templateUrl: './manage-hospital-configurations.component.html',
  styleUrl: './manage-hospital-configurations.component.scss'
})
export class ManageHospitalConfigurationsComponent implements OnInit  {

  user:any;
  hospitalDetails= {
    data:{}
  }
  constructor(
    public authService: AuthService,
    public hospitalRegistrationService: HospitalRegistrationService
  ){
    this.authService.user.subscribe((x) => (this.user = x));
  }

  ngOnInit(): void {
    if(this.isPrivileged(['IS_HOSPITAL_USER'])){
      this.hospitalRegistrationService.getHospitalByOrgId(this.user.orgId)
      .subscribe({
        next:(data:any)=>{
          this.hospitalDetails.data = data;
          console.log(this.hospitalDetails);
        },
        error:(err:any)=>{
          console.log(err);
        }
      })
    }
  }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }
}
