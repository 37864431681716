import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';
import { AddMailParserConfsComponent } from '../../manage-insurance-company/view-mail-parser-confs/add-mail-parser-confs/add-mail-parser-confs.component';
import { UpdateMailParserConfsComponent } from '../../manage-insurance-company/view-mail-parser-confs/update-mail-parser-confs/update-mail-parser-confs.component';
import { ViewMailParserConfsComponent } from '../../manage-insurance-company/view-mail-parser-confs/view-mail-parser-confs.component';

@Component({
  selector: 'app-send-email-config',
  templateUrl: './send-email-config.component.html',
  styleUrl: './send-email-config.component.scss'
})
export class SendEmailConfigComponent {
  masterEmailConfig: any =[];
  roles: any = [];
  pageSize = 10;
  pageIndex = 0;
  length = 100;
  pageSizeOptions = [5, 10, 25];
  message: any;

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  dataSource = new MatTableDataSource<any>(this.masterEmailConfig);
  displayedColumns: string[] = [
    "hospitalOrgId",
    "payorType",
    "receivingEmailHost",
    "receivingEmailId",
    "receivingEmailStoreType",
    "update"

  ];
  loading: boolean = false;
  constructor(
    private adminService: AdminService,
    private hospitalRegistration: HospitalRegistrationService,
    private _dialog: MatDialog,
    private toastrService: ToastrService,
    private _dialogRef: MatDialogRef<ViewMailParserConfsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log("Data",JSON.stringify(this.data));
    this.getEmailConfig(this.data.hospitalOrgId);
  }


  getEmailConfig(id: any) {
    this.hospitalRegistration.getSenderEmailConfig(id).subscribe({
      next: (data: any) => {
        console.log(JSON.stringify(data));
        this.masterEmailConfig = data;
        this.dataSource.data = this.masterEmailConfig;

      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error));
      },
    });
  }

  update(data: any) {
    const dialogRef = this._dialog.open(UpdateMailParserConfsComponent, {
      data: { config:data , type:'sender'},
      width: "90%",
      height: "70%",
      disableClose:true,

    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getEmailConfig(this.data.hospitalOrgId);
        }
      },
    });
  }
  

  add(data: any) {
    const dialogRef = this._dialog.open(AddMailParserConfsComponent, {
      data: {
        insurance: data,
        type: 'sender'
      },
      width: "90%",
      height: "70%",
      disableClose:true,

    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getEmailConfig(this.data.hospitalOrgId);
        }
      },
    });
  }

  test(data:any){
    console.log(data);
    var response ={
  
      "id": data.id,
      "hospitalOrgId": data.hospitalOrgId,
      "senderEmailId": data.senderEmailId,
      "senderEmailPassword": data.senderEmailPassword,
      "senderEmailHost": data.senderEmailHost,
      "senderEmailPort":data.senderEmailPort,
      "senderEmailStoreType": data.senderEmailStoreType,
      "inactive": data.inactive,
      "insuranceTpaId": data.insuranceTpaId,
      "payorType": data.payorType
    
   }
    this.hospitalRegistration.testSenderConfig(response)
      .subscribe({
        next: (val: any) => {
          this.toastrService.info(val);
        },
        error: (err: any) => {
          console.error('Full error object:', err);
        
          let errorMessage = "Please chek your configuration details!";
        
          // Check if `err.error` is an object or a string
          if (typeof err.error === "string") {
            try {
              // Parse the string to JSON if possible
              const parsedError = JSON.parse(err.error);
              errorMessage = parsedError.error;
            } catch (parseError) {
              // If parsing fails, use the raw string
              errorMessage = err.error;
            }
          } else if (err.error?.error) {
            // Handle error message if `err.error` is already an object
            errorMessage = err.error.error;
          }
        
          // Display the error message in Toastr
          this.toastrService.error(errorMessage);
        }
        
      })
  }



}

