<div class="container pt-3 text-center mb-4">
  <h1 class="display-5 fw-bold">
    Bima<span style="color: #c00000"> Garage</span> HobNob - Claim Now!
  </h1>
  <div class="card mb-3" *ngIf="showStepper">
    <div
      class="card-header bg-transparent border-danger"
      *ngIf="selectedProduct.id != 4"
    >
      <h3>Please fill in the details for completing your order -</h3>
      <div class="alert alert-danger" role="alert">
        {{ selectedProduct.name }}
      </div>
    </div>
    <div class="card-body">
      <div class="row" *ngIf="selectedProduct.id == 4">
        <div class="col">
          <h3 *ngIf="selectedProduct.id != 4">You selected -</h3>
          <div class="alert alert-danger" role="alert">
            {{ selectedProduct.name }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col" *ngIf="selectedProduct.id != 4">
          <mat-stepper linear #stepper>
            <mat-step
              *ngIf="Step1"
              [stepControl]="firstFormGroup"
              [editable]="isEditable"
              [optional]="true"
            >
              <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel
                  >Select your policy (Optional)</ng-template
                >
                <mat-form-field>
                  <mat-label>Policy</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="policyNumber"
                    placeholder="Enter policy number"
                  />
                  
                  <!-- <mat-select
                    (selectionChange)="onChange($event)"
                    formControlName="policyNumber"
                  >
                    <mat-option
                      *ngFor="let policy of policies"
                      [value]="policy.policyNumber"
                    >
                      {{ policy.policyNumber }} {{ policy.insuranceProduct }}
                    </mat-option>
                  </mat-select> -->
                </mat-form-field>
                <div>
                  <button mat-button mat-raised-button matStepperNext>
                    Next
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step
              *ngIf="Step2"
              [stepControl]="secondFormGroup"
              [editable]="isEditable"
             
            >
              <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel
                  >Select a memerber </ng-template
                >
                <mat-form-field>
                  <mat-label>Member Name</mat-label>
                  <input
                  matInput
                  type="text"
                  formControlName="memberName"
                  placeholder="Enter member name"
                  required
                />
                  <!-- <mat-select
                    (selectionChange)="onMemberChange($event)"
                    formControlName="memberName"
                  >
                    <mat-option
                      *ngFor="let member of members"
                      [value]="member.memberName"
                    >
                      {{ member.memberName }}
                    </mat-option>
                  </mat-select> -->
                </mat-form-field>
                <div>
                  <button
                    mat-button
                    mat-raised-button
                    btn-custom
                    color="primary"
                    matStepperPrevious
                  >
                    Back
                  </button>
                  <button mat-button mat-raised-button matStepperNext [disabled]="secondFormGroup.invalid">
                    Next
                  </button>
                </div>
              </form>
            </mat-step>
            <mat-step
              *ngIf="
                availableDiscount != undefined &&
                availableDiscount.discountAvailable == true
              "
            >
              <ng-template matStepLabel>Apply coupon</ng-template>
              <div class="row">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    type="text"
                    [(ngModel)]="couponCode"
                    placeholder="Enter discount coupon if available , click next to proceed"
                  />
                </mat-form-field>
              </div>
              <button
                mat-button
                matStepperNext
                (click)="applyCoupon()"
               
              >
                Next to continue
              </button>
            </mat-step>
            <mat-step *ngIf="Step3">
              <ng-template matStepLabel>Done</ng-template>
              <p>Proceed to payment.</p>
              <div>
                <button
                  mat-button
                  mat-raised-button
                  color="primary"
                  matStepperPrevious
                >
                  Back
                </button>
                <button
                  mat-button
                  mat-raised-button
                  color="accent"
                  (click)="resetstepper()"
                >
                  Reset
                </button>
                <button
                  mat-button
                  mat-raised-button
                  color="warn"
                  (click)="initiateClaim()"
                >
                  Initiate
                </button>
                <a
                  mat-button
                  rel="nofollow,noopener,noreferrer"
                  [href]="payurl"
                  *ngIf="payurl != undefined && payurlbtn"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Paynow</a
                >
              </div>
            </mat-step>
          </mat-stepper>
        </div>
        <div *ngIf="Step4">
          <p *ngIf="selectedProduct.id != 4">Proceed to payment.</p>
          <div>
            <button
              mat-button
              *ngIf="selectedProduct.id == 4"
              mat-raised-button
              color="accent"
              (click)="initiateClaim()"
            >
              Initiate
            </button>
            <button
              mat-button
              *ngIf="selectedProduct.id != 4"
              mat-raised-button
              color="accent"
              (click)="initiateClaim()"
            >
              Initiate
            </button>
            <a
              mat-button
              rel="nofollow,noopener,noreferrer"
              [href]="payurl"
              *ngIf="payurl != undefined && payurlbtn"
              target="_blank"
              rel="noopener noreferrer"
              >Paynow</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row p-4">
    <div
      class="col card-deck"
      *ngFor="let product of products; index as i"
      (click)="selectProduct(product)"
    >
      <div class="card" style="height: 450px">
        <img
          class="card-img-top"
          src="../../../../../assets/img/claims-service-small-product-{{
            i + 1
          }}.jpg"
          alt="Card image cap"
        />
        <div
          class="card-header"
          [ngClass]="{
            'bg-danger text-white':
              selectedProduct !== undefined && product.id === selectedProduct.id
          }"
        >
          {{ product.name }} - {{ product.shortName }}
        </div>
        <div class="card-body" style="text-align: start">
          <h5 class="card-title text-left">
            Price: {{ product.price | currency : "INR" }}
          </h5>
          <p class="card-text text-left">{{ product.description }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center">
    *Standard Benifits: We will facilitate the pickup of required documents.
  </div>
</div>
