import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss']
})
export class AddCouponComponent {
  couponForm: FormGroup;
  //orgId:any;
  userorg: any
  roles: any = [];
  agentList: any = [];
  pageSize = 100;
  pageIndex = 0;
  filterValue:any;
  length = 100;
  message: any;
  selectedType: any;
  error: any;
  optionSelected:any;
  orgs: any;
  partnerTypes: any;
  selectedOrgsTypeId: any;
  selectedProductTypeId: any;
  selectedPartnerTypeId: any;
  todayDate:Date = new Date();
  filteredOrgs: any[]=[];

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<AddCouponComponent>,

  ) {
    this.couponForm = this._fb.group({
     // id: '',
     productName: ['', Validators.required],
      discountCode: ['', [Validators.required, Validators.maxLength(10)]],
      discountPercent: ['', [Validators.required,Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      discountNote: ['', [Validators.required,Validators.maxLength(300)]],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      maxCount: ['', Validators.required],
      partnerTypeId: ['', Validators.required],
      // orgId:'',
      orgId: ['', Validators.required],
      agentId: [''],
    });
  }

  ngOnInit(): void {
    this.getProducts();
    this.getPartnerTypes();
    this.getOrgs();
  }

  products: any;

  getProducts() {
    this.customerService.getProducts().pipe(first()).subscribe({
      next: (data: any) => {
        this.products = data;
      }, error: (err: any) => {
        console.log(err);

        if (err.error && err.error.error) {
          this.error = err.error.error;
        } else {
          this.error = 'No products available for!';
        }

      }
    })
  }


  getOrgs() {
    this.adminService.getOrgs(this.pageIndex * this.pageSize, this.pageSize).pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.loading = false;
          this.orgs = data;
          this.filteredOrgs = this.orgs;
        },
        error: error => {
          console.log("error " + JSON.stringify(error))
          this.message = error;

          //this.loading = false;
        }
      });
  }
  getPartnerTypes() {
    this.adminService.getPartnerTypes().subscribe((data: any) => {
      this.partnerTypes = data;
    })
  }
  onFormSubmit() {
    console.log(this.couponForm.get('orgId')?.value);
    // {
    //   "id": "string",
    //   "productId": "string",
    //   "discountCode": "string",
    //   "discountPercent": 0,
    //   "discountNote": "string",
    //   "startDate": "2023-11-01T10:04:54.794Z",
    //   "endDate": "2023-11-01T10:04:54.795Z",
    //   "maxCount": 0,
    //   "partnerTypeId": 0,
    //   "orgId": "string",
    //   "dateCreated": "2023-11-01T10:04:54.795Z",
    //   "createdBy": "string",
    //   "inactive": true,
    //   "currentCount": 0
    // }
    // var orgId: "741cdabe-4c9b-4892-9326-e12b424bafb5";
    //console.log(this.datePipe.transform(this.couponForm.value.startDate,"yyyy-MM-dd")); //output : 2018-02-13
    var data =

    {
      //"id": this.couponForm.value.id,
      "productId": this.selectedProductTypeId,
      "discountCode": this.couponForm.value.discountCode,
      "discountPercent": this.couponForm.value.discountPercent,
      "discountNote": this.couponForm.value.discountNote,
       "startDate": this.couponForm.value.startDate,
       "endDate": this.couponForm.value.endDate,
      //"startDate": "2023-11-01T10:04:54.794Z",
      //"endDate": "2023-11-01T10:04:54.795Z",
      // "startDate": this.datePipe.transform(this.couponForm.value.startDate, "yyyy-MM-dd"),
      // "endDate": this.datePipe.transform(this.couponForm.value.endDate, "yyyy-MM-dd"),
      //"startDate": this.datePipe.transform(this.couponForm.value.startDate, "yyyy-MM-dd"),
      //"endDate": this.datePipe.transform(this.couponForm.value.endDate, "yyyy-MM-dd"),
      "maxCount": this.couponForm.value.maxCount,
      "partnerTypeId": this.couponForm.get('partnerTypeId')?.value,
      "orgId": this.couponForm.get('orgId')?.value,
      "dateCreated": "2023-11-01T10:04:54.795Z",
      "createdBy": "",
      "inactive": true,
      "currentCount": 0,
      "agentId": this.couponForm.get('agentId')?.value,


    }
    this.adminService.addDiscountCoupon(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("Coupon has been added successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));


      },
    });


  }

  selectedRadioButton(event:any){
    console.log(event.value);
    const value = event.value;

    if(value == 'doctor'){
      this.couponForm.patchValue({
        orgId: '741cdabe-4c9b-4892-9326-e12b424bafb5',
      });
      this.couponForm.get('orgId')?.disable();
      this.couponForm.get('agentId')?.disable();
      this.couponForm.patchValue(
        {
          partnerTypeId: 6
        }
      );
      this.couponForm.get('partnerTypeId')?.disable();
    }

    if(value == 'agent'){
      this.couponForm.patchValue({
        orgId: '741cdabe-4c9b-4892-9326-e12b424bafb5',
      });
      this.couponForm.get('orgId')?.disable();
      this.couponForm.get('agentId')?.enable();
      this.couponForm.get('agentId')?.enable();
      this.couponForm.patchValue(
        {
          partnerTypeId: 7
        }
      );
      this.couponForm.get('partnerTypeId')?.disable();
      this.getUserByRoles();
    }
   
 
   if (value === 'hospital') {
    this.couponForm.get('orgId')?.enable();
    this.couponForm.get('agentId')?.disable();
    this.couponForm.patchValue(
      {
        partnerTypeId: ''
      }
    );
    this.couponForm.get('partnerTypeId')?.enable();
  }


  }

  getUserByRoles(){
    this.adminService.getUsersByRole(["HOBNOB_AGENT"]).subscribe({
      next: (val: any) => {
       console.log(val);
       this.agentList = val;
      },
      error: (err: any) => {
        console.error(err);
      },
    });
  }

  
  filterOrgs(event:any){
    this.filterValue= event.target.value.toLowerCase();
    //this.filterOrgs = this.orgs .filter((org:any)=>org.name.toLowerCase().includes(this.filterValue.toLoerCase()));
    if (this.filterValue != "") {
      this.filteredOrgs  = this.filterValue === ""?  this.filteredOrgs :  this.orgs.filter((item:any) =>
    item.name.toLowerCase().includes(this.filterValue.toLowerCase()));

    } else {
        this.getOrgs();
    }

  }
}
