import {  AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';
import { AddRecipientsComponent } from '../add-recipients/add-recipients.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrl: './recipients.component.scss'
})
export class RecipientsComponent implements OnInit,AfterViewInit{
  recipientsList:any =[];
  rowNum: number = 0;
  size :number =100;
  dataSource= new MatTableDataSource<any>(this.recipientsList);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns =['name', 'dateCreated' , 'contactNumber' ,'email','recipientGroup'];
  constructor(
    private hospitalRegistration: HospitalRegistrationService,
    private _dialog: MatDialog,
  ){

  }

  ngOnInit(): void {
    this.getRecipientsList(this.rowNum , this.size ,'');
  }

  ngAfterViewInit(): void {
    
  }

  getRecipientsList(rowNum:any,size:any,recipientGroup:any){
    this.hospitalRegistration.getRecipientsList(rowNum , size, recipientGroup)
    .subscribe({
      next: (data:any) => {
        console.log(data);
        this.recipientsList = data.result;
        this.dataSource.data = this.recipientsList;
        this.dataSource.paginator = this.paginator;
      },
      error: (error) => {
        console.log("error " + JSON.stringify(error));
      },
    })
  }

  addRecipients(){
    const addRecipients = this._dialog.open( AddRecipientsComponent,{
      disableClose: true,
      width: '100vw', 
      maxWidth: '100vw',  
      panelClass: 'full-width-dialog',  
      height: '80%'
    });
    addRecipients.afterClosed().subscribe(() => {
      this.getRecipientsList(this.rowNum, this.size, '');
    });
  }

  filterByRecipientGroup(event:any){
    console.log(event.target.value);
    console.log(event);
    
    this.getRecipientsList(this.rowNum, this.size, event.target.value);
  }

  

}

