import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileService } from '../services/file.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationMessageComponent } from './confirmation-message/confirmation-message.component';

@Component({
  selector: 'app-attachment-scroller',
  templateUrl: './attachment-scroller.component.html',
  styleUrl: './attachment-scroller.component.scss'
})
export class AttachmentScrollerComponent {
  @Input() data:any;
  @Output() fileSelected = new EventEmitter<any>();
  @Output() updateMediaList = new EventEmitter<any[]>();
  docList:any[] = [];
  constructor(
    private fileService:FileService,
    private toastrService : ToastrService,
    private dialog: MatDialog,
  ){
    console.log(this.data);
  }

  onFileSelected(item:any){
    console.log(item);
    this.fileSelected.emit(item);

  }

  deleteMedia(id:any){
    const dialogComponent = this.dialog.open(ConfirmationMessageComponent,{
      width: '400px',
      disableClose: true,
    });
    dialogComponent.afterClosed().subscribe((result: boolean) => {
      if(result == true){
        this.fileService.deleteExternalMedia(id)
    .subscribe({
      next: (data: any) => {
        this.toastrService.info('File deleted successfully');
        this.docList = this.data.filter((data:any)=>data.id != id);
        this.updateMediaList.emit(this.docList);
      },
      error: (error) => {
        console.error('Error deleting media', error);
      }
    })
    console.log(id);
  
      }
    });
  }
    
}
