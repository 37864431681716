<div mat-dialog-title>
    <div class="row">
        <div class="col-8 d-flex justify-content-start">
            <h1>Edit Hospital Details </h1>
        </div>
        <div class="col-4 d-flex justify-content-end" mat-dialog-close>
            <h4>
                <i class="px-3" style="color: lightgray">* marked fields are mandatory
                </i>
            </h4>
            <mat-icon color="accent">cancel</mat-icon>
        </div>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="card">
        <div class="card-body">
            <form class="overflow-card" [formGroup]="form">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Email ID</mat-label>
                        <input matInput type="email" class="form-control" id="email" formControlName="emailId">
                      </mat-form-field>
                    
                      <mat-form-field  appearance="outline">
                        <mat-label>Contact Details</mat-label>
                        <input matInput type="text" class="form-control" id="contact" formControlName="contactDetail">
                      </mat-form-field>
                      <mat-form-field  appearance="outline">
                        <mat-label>Zone</mat-label>
                        <mat-select formControlName="zone"  id="zone" >
                            <mat-option value="north">North</mat-option>
                            <mat-option value="south">South</mat-option> 
                            <mat-option value="east">East</mat-option>
                            <mat-option value="west">West</mat-option>                            
                        </mat-select>
                      </mat-form-field>
                </div>
              
                <div class="row">
                    <mat-form-field  appearance="outline">
                        <mat-label>PreAuth Filled</mat-label>
                        <mat-select formControlName="preAuthFilled"  id="preAuth" >
                            <mat-option [value]="true">True</mat-option>
                            <mat-option [value]="false">False</mat-option>                            
                        </mat-select>
                       
                      </mat-form-field>
                    
                      <mat-form-field  appearance="outline">
                        <mat-label>File Dispatch Verification</mat-label>
                        <mat-select id="fileDispatch" formControlName="fileDispatchVerification">
                            <mat-option [value]="true">True</mat-option>
                            <mat-option [value]="false">False</mat-option>
                        </mat-select>
                       
                      </mat-form-field>
                </div>
              
              <div class="row">
                <mat-form-field  appearance="outline">
                    <mat-label>Claim Through Email</mat-label>
                    <mat-select id="claimEmail" formControlName="claimThroughEmail">
                        <mat-option [value]="true">True</mat-option>
                        <mat-option [value]="false">False</mat-option>
                    </mat-select>
                   
                  </mat-form-field>
                
                  <mat-form-field  appearance="outline">
                    <mat-label>Medical Scrutiny Approval</mat-label>
                    <mat-select  id="medicalScrutiny" formControlName="medicalScrutinyApproval">
                        <mat-option [value]="true">True</mat-option>
                        <mat-option [value]="false">False</mat-option>
                    </mat-select>
                   
                  </mat-form-field>
              </div>
              <button type="submit" mat-flat-button style="color: white; background: #8e0101;" [disabled]="form.invalid" (click)="updateDetails()">Update</button>
              </form>
              
        </div>
    </div>
</mat-dialog-content>