<div class="container justify-content-center ">
    <div class="form-wrapper">
        
    <form [formGroup]="payorReport" class="form-class justify-content-center">
        <mat-form-field appearance="fill">
            <mat-label>Hospital Name</mat-label>
            <mat-select placeholder="Select hospital" formControlName="hospitalOrgId" (selectionChange)="onHospitalSelected($event)">
                <mat-option *ngFor="let hospital of hospitalList" [value]="hospital.hospitalOrgId">
                    {{ hospital.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>From date</mat-label>
            <input matInput [matDatepicker]="pickerFrom" formControlName="startDate" 
                (dateChange)="onFromDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>To date</mat-label>
            <input matInput [matDatepicker]="pickerTo" formControlName="endDate" (dateChange)="onToDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

    </form>
    </div>

    <div class="container py-3 px-2">
    <div class="card px-3 py-4 justify-content-md-center ">
    <mat-table #table [dataSource]="dataSource" *ngIf="dataSource.data.length>0">

        <ng-container matColumnDef="count">
            <mat-header-cell *matHeaderCellDef>Count</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.count }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="payorType">
            <mat-header-cell *matHeaderCellDef>Payor Type</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.payorType}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="payorName">
            <mat-header-cell *matHeaderCellDef>Payor Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.payorName}}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="caseIDs">
            <mat-header-cell *matHeaderCellDef>Case IDs</mat-header-cell>
            <mat-cell *matCellDef="let element"  class="wrap-text">{{element.caseIds}}</mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

       
    </mat-table>
    <mat-paginator 
    #paginator
    [hidden]="dataSource.data.length === 0"
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 20]"
    [length]="dataSource.data.length"
    [showFirstLastButtons]="true"
    aria-label="Select page of Tasks!">
</mat-paginator>



    <div class="alert alert-info mt-2 pt-1" role="alert" *ngIf="dataSource.data.length == 0">
       No report available
    </div>
    </div>

</div>
</div>