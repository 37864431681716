
  <div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Add EKYP</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="row pt-2">
      <div class="col-6" style="padding: 0">
        <ngx-extended-pdf-viewer
          *ngIf="pdfUrl != undefined"
          [src]="pdfUrl"
         
          [textLayer]="true"
          [showHandToolButton]="true"
        >
        </ngx-extended-pdf-viewer>
        <img class="w-100" *ngIf="imgData != undefined" [src]="imgData" />
      </div>
      <div class="col-6">
        <form [formGroup]="kypForm" (ngSubmit)="onFormSubmit()">
          <div class="container">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline" class="custom-form-field">
                  <mat-label>Start date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker"
                    placeholder="Start date"
                    formControlName="startdate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline" class="custom-form-field">
                  <mat-label>Policy number</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="policy_number"
                    formControlName="policynumber"
                  />
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline" class="custom-form-field">
                  <mat-label>End date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker1"
                    placeholder="End date"
                    formControlName="enddate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline" class="custom-form-field">
                  <mat-label>Insurance company</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="insurance_company"
                    formControlName="insurancecompany"
                  />
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline" class="custom-form-field">
                  <mat-label>Insurance name</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="insurance_name"
                    formControlName="insurancename"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline" class="custom-form-field">
                  <mat-label>Ported</mat-label>
                  <mat-select formControlName="ported" name="ported">
                    <mat-option value="true"> Yes </mat-option>
                    <mat-option value="false"> No </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline" class="custom-form-field">
                  <mat-label>Policy type</mat-label>
                  <input
                    matInput
                    type="email"
                    placeholder="policy_type"
                    formControlName="policytype"
                  />
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline" class="custom-form-field">
                  <mat-label>Policy name</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="policy_name"
                    formControlName="policyname"
                  />
                </mat-form-field>
              </div>
            </div>
            <hr />
            <h4>Coverage Information</h4>
            <form [formGroup]="memberForm">
              <div class="row">
                <div class="col">
                  <mat-form-field
                    appearance="outline"
                    class="custom-form-field"
                  >
                    <mat-label>Insured Name</mat-label>
                    <input matInput formControlName="insuredname" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field
                    appearance="outline"
                    class="custom-form-field"
                  >
                    <mat-label>Sum Insured</mat-label>
                    <input matInput formControlName="suminsured" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field
                    appearance="outline"
                    class="custom-form-field"
                  >
                    <mat-label>Super Bonus</mat-label>
                    <input matInput formControlName="superbonus" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field
                    appearance="outline"
                    class="custom-form-field"
                  >
                    <mat-label>Bonus</mat-label>
                    <input matInput formControlName="bonus" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field
                    appearance="outline"
                    class="custom-form-field"
                  >
                    <mat-label>Restore</mat-label>
                    <input matInput formControlName="restore" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field
                    appearance="outline"
                    class="custom-form-field"
                  >
                    <mat-label>Effective Coverage</mat-label>
                    <input matInput formControlName="effectivecoverage" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field
                    appearance="outline"
                    class="custom-form-field"
                  >
                    <mat-label>DOB</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker2"
                      formControlName="dob"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </form>
            <hr />
            <h4>Waiting period information</h4>
            <form [formGroup]="InsuredwaitingperiodForm">
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="insuredname" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline">
                    <mat-label>Pre Hospitalisation</mat-label>
                    <mat-select formControlName="prehospitalisation">
                      <mat-option value="No Waiting Period"
                        >No Waiting Period</mat-option
                      >
                      <mat-option value="30 days">30 days</mat-option>
                      <mat-option value="60 days">60 days</mat-option>
                      <mat-option value="180 days">180 days</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline">
                    <mat-label>Post Hospitalisation</mat-label>
                    <mat-select formControlName="posthospitalisation">
                      <mat-option value="No Waiting Period"
                        >No Waiting Period</mat-option
                      >
                      <mat-option value="30 days">30 days</mat-option>
                      <mat-option value="60 days">60 days</mat-option>
                      <mat-option value="180 days">180 days</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline">
                    <mat-label>Waiting Period</mat-label>
                    <mat-select formControlName="waitingperiod">
                      <mat-option value="No Waiting Period"
                        >No Waiting Period</mat-option
                      >
                      <mat-option value="30 days">30 days</mat-option>
                      <mat-option value="60 days">60 days</mat-option>
                      <mat-option value="180 days">180 days</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </form>
            <hr />
            <h4>Sublimit information</h4>
            <form [formGroup]="sublimitinformationform">
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline">
                    <mat-label>Insured name</mat-label>
                    <input matInput formControlName="insuredname" />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline">
                    <mat-label>Room rent</mat-label>
                    <input matInput formControlName="roomrent" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline">
                    <mat-label>Icu_ICCU</mat-label>
                    <input matInput formControlName="icuICCU" />
                  </mat-form-field>
                </div>
              </div>
            </form>
            <hr />
            <h4>Comments</h4>
            <form [formGroup]="commentsForm">
              <div>
                <table class="table-responsive" style="border-spacing: 10px">
                  <thead>
                    <tr class="pb-2 text-white" style="background-color: brown; height: 30px">
                      <th>Comment Category</th>
                      <th>Add Comment</th>
                    </tr>
                    <tr style="height: 30px">
                      <td>Submit Comment</td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input matInput formControlName="submitcomment" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td>Additional Comment</td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input matInput formControlName="additionalcomment" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Expert Comments</td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input matInput formControlName="expertcomments" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Coverage Comments</td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input matInput formControlName="coveragecomments" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td>Waiting Period Comments</td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            matInput
                            formControlName="waitingperiodcomments"
                          />
                        </mat-form-field>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </form>
            <div mat-dialog-actions class="action">
              <button mat-raised-button type="button" [mat-dialog-close]="false">
                Cancel
              </button>
  
              <button
                mat-raised-button
                color="primary"
                style="background-color: brown"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>

