import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  requestOptions = { headers: this.headers };
  
  getDashboard(type:any,orgType:any,fromDate:any,hospitalOrgId:any){
    return this.http.get<any>(`${environment.API_URL}/bpmn/dashboard/`+type+"?fromDate="+fromDate+"&hospitalOrgId="+hospitalOrgId)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  getHospitalDashboard(fromDate:any,hospitalOrgId:any){
    return this.http.get<any>(`${environment.API_URL}/bpmn/hospitalDashboard/`+"?fromDate="+fromDate+"&hospitalOrgId="+hospitalOrgId)
      .pipe(
        catchError(this.errorHandle)
      );
  }


  getUserPerformance(processName:any,fromDate:any){
    return this.http.get<any>(`${environment.API_URL}/bpmn/dashboard/userPerformance/`+processName+"?fromDate="+fromDate)
      .pipe(
        catchError(this.errorHandle)
      );
  }

  getWeeklyReport(month:number , year:number , hospitalOrgId:any){
    return this.http.get<any>(`${environment.API_URL}/bpmn/weeklyReport?hospitalOrgId=${hospitalOrgId}&month=${month}&year=${year}`)
    .pipe(
      catchError(this.errorHandle)
    );
  }


  

  errorHandle(error: any) {
    let errorMessage = '';
    if (error.error.error instanceof ErrorEvent) {
      errorMessage = error.error.error;
    } else {
      errorMessage = error.error;
    }

    return throwError(() => {
      return errorMessage;
    });
  }
}
