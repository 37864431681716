<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1 style="padding:10px;">Policy Details</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
</div>
<div class="row">
    <!-- <div class="col-6">
        <mat-dialog-content class="mat-typography">
            <div class="row pt-2">
                <div style="padding: 0">
                    <ngx-extended-pdf-viewer *ngIf="pdfUrl != undefined" [src]="pdfUrl" [textLayer]="true"
                        [showHandToolButton]="true">
                    </ngx-extended-pdf-viewer>
                    <img class="w-100" *ngIf="imgData != undefined" [src]="imgData" />
                </div>
            </div>
        </mat-dialog-content>
    </div> -->
   
    <div >
        <div class="container">
            <form [formGroup]="policyForm">
                <!-- <h2>Policy Details</h2> -->
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Policy Number</mat-label>
                            <input matInput placeholder="Policy Number" formControlName="policyNumber">
                        </mat-form-field>
                    </div>
        
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Policy Type</mat-label>
                            <input matInput placeholder="Policy Type" formControlName="policyType">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="startDate" placeholder="Start Date" formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                        </mat-form-field>
                    </div>
        
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="endDate" placeholder="Start Date" formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Insurance Company</mat-label>
                            <input matInput placeholder="Insurance Company" formControlName="insuranceCompany">
                        </mat-form-field>
                    </div>
        
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Processed</mat-label>
                            <input matInput placeholder="Ported" formControlName="ported">
                        </mat-form-field>
                    </div>
                </div>
                <div class="card " style="margin-bottom: 20px;" *ngIf="waiting_period_information.length>0">
                    <div class="card-header"  style="color: white; background: #8e0101">
                        <h3>Waiting Period Information</h3>
                    </div>
                    <div class="card-content">
                        <mat-table [dataSource]="waiting_period_information" class="table-border">
                            <ng-container matColumnDef="memberName">
                                <th mat-header-cell *matHeaderCellDef> Member Name</th>
                                <td mat-cell *matCellDef="let element"> {{element.memberName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="preHospitalisation">
                                <th mat-header-cell *matHeaderCellDef> Pre-Hospitalisation</th>
                                <td mat-cell *matCellDef="let element"> {{element.preHospitalisation}} </td>
                            </ng-container>
                            <ng-container matColumnDef="postHospitalisation">
                                <th mat-header-cell *matHeaderCellDef> Post-Hospitalisation</th>
                                <td mat-cell *matCellDef="let element"> {{element.postHospitalisation}} </td>
                            </ng-container>
                            <ng-container matColumnDef="waitingPeriod_Specific">
                                <th mat-header-cell *matHeaderCellDef> Waiting Period Specific</th>
                                <td mat-cell *matCellDef="let element"> {{element.waitingPeriod_Specific}} </td>
                            </ng-container>
                            <ng-container matColumnDef="waitingPeriod_PED3Years">
                                <th mat-header-cell *matHeaderCellDef> Waiting Period PED 3 Years</th>
                                <td mat-cell *matCellDef="let element"> {{element.waitingPeriod_PED3Years}} </td>
                            </ng-container>
                            <ng-container matColumnDef="waitingPeriod_30Days">
                                <th mat-header-cell *matHeaderCellDef> Waiting Period 30 Days</th>
                                <td mat-cell *matCellDef="let element"> {{element.waitingPeriod_30Days}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="waiting_period"></tr>
                            <tr mat-row *matRowDef="let row; columns: waiting_period"></tr>
                        </mat-table>
                      
                    </div>
                </div>

                <div class="card " style="margin-bottom: 20px;" *ngIf="coverage_information.length>0">
                    <div class="card-header" style="color: white; background: #8e0101">
                        <h3>Coverage Information</h3>
                    </div>
                    <div class="card-content">
                        <mat-table [dataSource]="coverage_information">
                            <ng-container matColumnDef="insuredName">
                                <th mat-header-cell *matHeaderCellDef>Insured Name</th>
                                <td mat-cell *matCellDef="let element"> {{element.insuredName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="sumInsured">
                                <th mat-header-cell *matHeaderCellDef>Sum Insured</th>
                                <td mat-cell *matCellDef="let element"> {{element.sumInsured}} </td>
                            </ng-container>
                            <ng-container matColumnDef="recharge">
                                <th mat-header-cell *matHeaderCellDef>Recharge</th>
                                <td mat-cell *matCellDef="let element"> {{element.recharge}} </td>
                            </ng-container>
                            <ng-container matColumnDef="dob">
                                <th mat-header-cell *matHeaderCellDef>Date of Birth</th>
                                <td mat-cell *matCellDef="let element"> {{element.dob}} </td>
                            </ng-container>
                            <ng-container matColumnDef="superBonus">
                                <th mat-header-cell *matHeaderCellDef>Super Bonus</th>
                                <td mat-cell *matCellDef="let element"> {{element.superBonus}} </td>
                            </ng-container>
                            <ng-container matColumnDef="effectiveCoverage">
                                <th mat-header-cell *matHeaderCellDef>Effective Coverage</th>
                                <td mat-cell *matCellDef="let element"> {{element.effectiveCoverage}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="coverage"></tr>
                            <tr mat-row *matRowDef="let row;columns :coverage"></tr>
                        </mat-table>
                    </div>
                </div>

                <div class="card " style="margin-bottom: 20px;" *ngIf="sublimit_information.length>0">
                    <div class="card-header"  style="color: white; background: #8e0101"> 
                        <h3>Sublimit Information</h3>
                    </div>
                    <div class="card-content">
                        <mat-table [dataSource]="sublimit_information">
                            <ng-container matColumnDef="insuredName">
                                <th mat-header-cell *matHeaderCellDef>Insured Name</th>
                                <td mat-cell *matCellDef="let element"> {{element.insuredName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="roomRent">
                                <th mat-header-cell *matHeaderCellDef>Room Rent</th>
                                <td mat-cell *matCellDef="let element"> {{element.roomRent}} </td>
                            </ng-container>
                            <ng-container matColumnDef="icuRent">
                                <th mat-header-cell *matHeaderCellDef>ICU Rent</th>
                                <td mat-cell *matCellDef="let element"> {{element.icuRent}} </td>
                            </ng-container>
                            <ng-container matColumnDef="ambulanceCover">
                                <th mat-header-cell *matHeaderCellDef>Ambulance Cover</th>
                                <td mat-cell *matCellDef="let element"> {{element.ambulanceCover}} </td>
                            </ng-container>
                            <ng-container matColumnDef="ayushTreatment">
                                <th mat-header-cell *matHeaderCellDef>Ayush Treatment</th>
                                <td mat-cell *matCellDef="let element"> {{element.ayushTreatment}} </td>
                            </ng-container>
                            <ng-container matColumnDef="hospitalDailyCash">
                                <th mat-header-cell *matHeaderCellDef>Hospital Daily Cash</th>
                                <td mat-cell *matCellDef="let element"> {{element.hospitalDailyCash}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="sublimit"></tr>
                            <tr mat-row *matRowDef="let row; columns : sublimit"></tr>
                        </mat-table>
                    </div>
                </div>


                <div class="card" *ngIf="remarks">
                    <div class="card-header"  style="color: white; background: #8e0101"> 
                        <h3>Benfits</h3>
                    </div>
                    <div class="card-content" style="margin-top:5px">
                        {{remarks}}
                    </div>
                </div>
             

               

                <!-- <div formArrayName="waiting_period_information">
                    <div *ngFor="let waiting of waiting_period_information.controls ; let i=index" [formGroupName]="i">
                        <div class="row">
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Member Name</mat-label>
                                    <input matInput formControlName="memberName">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Pre-Hospitalisation</mat-label>
                                    <input matInput formControlName="preHospitalisation">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Post-Hospitalisation</mat-label>
                                    <input matInput formControlName="postHospitalisation">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                           
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Waiting Period Specific</mat-label>
                                    <input matInput formControlName="waitingPeriod_Specific">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Waiting Period PED 3 Years</mat-label>
                                    <input matInput formControlName="waitingPeriod_PED3Years">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Waiting Period 30Days</mat-label>
                                    <input matInput formControlName="waitingPeriod_30Days">
                                </mat-form-field>
                            </div>
                            
                        </div>
        
                    </div>
        
                </div> -->


                <!-- <h3>Coverage Information</h3>
                <div formArrayName="coverage_information">
                    <div *ngFor="let coverage of coverage_information.controls ; let i=index" [formGroupName]="i">
                        <div class="row">
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Insured Name</mat-label>
                                    <input matInput formControlName="insuredName">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Effective Coverage</mat-label>
                                    <input matInput formControlName="effectiveCoverage">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Date of Birth</mat-label>
                                    <input matInput formControlName="dob">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Sum Insured</mat-label>
                                    <input matInput formControlName="sumInsured">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Recharge</mat-label>
                                    <input matInput formControlName="recharge">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Super Bonus</mat-label>
                                    <input matInput formControlName="superBonus">
                                </mat-form-field>
                            </div>
                            
                        </div>
        
                    </div>
        
                </div>

                <h3>Sublimit Information</h3> -->
                <!-- <div formArrayName="sublimit_information">
                    <div *ngFor="let sublimit of sublimit_information.controls ; let i=index" [formGroupName]="i">
                        <div class="row">
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Insured Name</mat-label>
                                    <input matInput formControlName="insuredName">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Ayush Treatment</mat-label>
                                    <input matInput formControlName="ayushTreatment">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Ambulance Cover</mat-label>
                                    <input matInput formControlName="ambulanceCover">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>ICU Rent</mat-label>
                                    <input matInput formControlName="icuRent">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Room Rent</mat-label>
                                    <input matInput formControlName="roomRent">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="full-width">
                                    <mat-label>Hospital Daily Cash</mat-label>
                                    <input matInput formControlName="hospitalDailyCash">
                                </mat-form-field>
                            </div>
                            
                        </div>
        
                    </div>
        
                </div> -->
                
            </form>
        </div>  
    </div>
</div>
