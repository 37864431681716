<div class="container p-3">
    <div
      class="addbtn"
      *ngIf="
        isPrivileged([
          'ADMIN',
          'SUPERADMIN',
          'CAN_VIEW_DISCOUNT_COUPON',
          'CAN_CREATE_DISCOUNT_COUPON',
          'CAN_UPDATE_DISCOUNT_COUPON'
        ])
      "
    >
    <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openAddForm()">
      Add 
    </button>
    </div>
  
    <div class="row px-3 py-4 justify-content-md-center">
      <input
        type="search"
        (input)="handleUInput($event)"
        class="form-control"
        placeholder="Filter"
        aria-label="Search"
      />
    </div>
     
      <mat-table class="" [dataSource]="dataSource" matSort>
        <!-- Position Column -->
        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef>Category</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.id.category }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="categoryName">
            <mat-header-cell *matHeaderCellDef>Category Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.id.categoryName }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="categoryDescription">
            <mat-header-cell *matHeaderCellDef>Category Description</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.categoryDescription }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="entityType">
            <mat-header-cell *matHeaderCellDef>Entity Type</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.id.entityType }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.id.type }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.inactive ? " In Active" : "Active" }}
            </mat-cell>
          </ng-container>
  
        <!-- <ng-container matColumnDef="ProdId">
          <mat-header-cell *matHeaderCellDef>Product Id</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ getProductName(element.productId) }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="DiscountCode">
          <mat-header-cell *matHeaderCellDef>Discount code</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.discountCode }}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="DiscountPercent">
          <mat-header-cell *matHeaderCellDef>Discount percent</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.discountPercent }}
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="DiscountNote">
          <mat-header-cell *matHeaderCellDef>Discount note</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.discountNote }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="StartDate">
          <mat-header-cell *matHeaderCellDef>Start date</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.startDate | date }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="EndDate">
          <mat-header-cell *matHeaderCellDef>End date</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.endDate | date }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="MaxCount">
          <mat-header-cell *matHeaderCellDef>Max count</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.maxCount }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="PartnerTypeId">
          <mat-header-cell *matHeaderCellDef>Partner type id</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.partnerTypeId }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="OrgId">
          <mat-header-cell *matHeaderCellDef>Org id</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.orgId }}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="Status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.inactive ? " In Active" : "Active" }}
          </mat-cell>
        </ng-container> -->
  
        <ng-container matColumnDef="action">
          <mat-header-cell style="background:rgb(241, 241, 241);" *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button style="color:#8e0101;" (click)="openEditForm(row)" matTooltip="Edit Form"
            matTooltipPosition="below">
            <mat-icon>edit</mat-icon>
          </button>
            <!-- <button mat-icon-button color="primary" (click)="openAddForm(row)">
                      <mat-icon>add</mat-icon>
                    </button> -->
          </mat-cell>
          <!-- <mat-cell *matCellDef="let row">
                      <button mat-icon-button color="primary" (click)="openAddForm()">
                        <mat-icon>add</mat-icon>
                      </button>
                    </mat-cell> -->
        </ng-container>
  
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator #paginator [length]="dataSource.data.length" [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
    
    <!-- <div class="demo-options p-4">
      <div class="demo-toggles">
        <mat-paginator
          #paginator
          class="demo-paginator"
          (page)="handlePageEvent($event)"
          [length]="length"
          [pageSize]="pageSize"
          [disabled]="disabled"
          [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
          [hidePageSize]="hidePageSize"
          [pageIndex]="pageIndex"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>  -->
    <div
      class="alert alert-danger mt-2 pt-1"
      role="alert"
      *ngIf="message != undefined"
    >
      {{ message }}
    </div>
  </div>
  