import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { CashflowService } from 'src/app/services/cashflow.service';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-weekly-dashboard',
  templateUrl: './weekly-dashboard.component.html',
  styleUrl: './weekly-dashboard.component.scss'
})
export class WeeklyDashboardComponent implements OnInit {

  hospitalList:any[]=[];
  loading: boolean =false;
  currentDate = new Date();
  month:number = this.currentDate.getMonth()+1;
  year:number  = this.currentDate.getFullYear();
  hospitalOrgId:any =null;
   months = Array.from({ length: 12 }, (_, index) => ({
    name: new Date(0, index).toLocaleString('default', { month: 'long' }), 
    number: index + 1 
  }));
  years = Array.from({ length: 3 }, (_, index) => this.year - index);
  columnsForTable1 = ['month','category','count','business','settlesWithTDS','partialPaymentByIC'];
  columnsForTable6 = ['month','falDeductions','reconsideration','grandTotal']
  dataSource1 = new MatTableDataSource();
  dataSource6 = new MatTableDataSource();
  monthlyDataMap:any[]=[] ;
  weeklyReport:any;
  totalCount: number = 0;
  totalBusiness: number = 0;
  totalSettledWithTDS: number = 0;
  totalRecoverable: number = 0;
  dataSource2: any=[];
  constructor(
    public dashboardService: DashboardService,
    private cashlflowService: CashflowService,
  ){}

  ngOnInit(): void {
    this.getOnboardedHospitals();
  }

  generateReport(){
    this.loading= true;
    this.getWeeklyReport(this.month, this.year, this.hospitalOrgId);
  }

  getWeeklyReport(month:number,year:number,hospitalOrgId:any){
    this.dashboardService.getWeeklyReport(month, year, hospitalOrgId)
    .subscribe(data => {
      this.weeklyReport = data;
      console.log(data);
      this.monthlyDataMap = data.monthlyDataMap;
      const transformData= this.transformClaimsData(this.monthlyDataMap);
      this.monthlyDataMap = transformData.transformedData;
      this.dataSource2 = transformData.overallTotals;
      console.log(this.monthlyDataMap);
      console.log(this.dataSource2);
      this.calculateTotals();
      this.loading = false;
      // this.dataSource1.data =  data.monthlyDataMap;
      this.dataSource6.data =  data.monthlyDataMap;

    });
  } 

  // transformClaimsData(data: any[]): any[] {
  //   let overallTotals = {
  //     fullyClaimSettled: { count: 0, business: 0, settledWithTDS: 0, recoverable: 0 },
  //     partiallyClaimSettled: { count: 0, business: 0, settledWithTDS: 0, recoverable: 0 },
  //     outstanding: { count: 0, business: 0, settledWithTDS: 0, recoverable: 0 }
  //   };
  //   return data.map((entry) => {
  //     return {
  //       monthYear: entry.monthYear,
  //       rows: [
  //         {
  //           category: 'Fully Claim Settled',
  //           count: entry.fullClaimSettledCount,
  //           business: entry.fullClaimRequestedAmount,
  //           settledWithTDS: entry.fullClaimSettledApprovedWithTDS,
  //           recoverable: entry.fullClaimRecoverable,
  //         },
  //         {
  //           category: 'Partially Claim Settled',
  //           count: entry.partialClaimSettledCount,
  //           business: entry.partialClaimAmount,
  //           settledWithTDS: entry.partialClaimSettledApprovedWithTDS,
  //           recoverable: entry.partialClaimRecoverable,
  //         },
  //         {
  //           category: 'Outstanding',
  //           count: entry.outstandingClaimCount,
  //           business: entry.outstandingClaimRequestedAmount,
  //           settledWithTDS: entry.outstandingUndispatchedClaimAmount,
  //           recoverable: entry.outstandingUndispatchedClaimAmount,
  //         }
  //       ]
        
  //     };
  //     row.forEach((row) => {
  //       if (row.category === 'Fully Claim Settled') {
  //         overallTotals.fullyClaimSettled.count += row.count;
  //         overallTotals.fullyClaimSettled.business += row.business;
  //         overallTotals.fullyClaimSettled.settledWithTDS += row.settledWithTDS;
  //         overallTotals.fullyClaimSettled.recoverable += row.recoverable;
  //       } else if (row.category === 'Partially Claim Settled') {
  //         overallTotals.partiallyClaimSettled.count += row.count;
  //         overallTotals.partiallyClaimSettled.business += row.business;
  //         overallTotals.partiallyClaimSettled.settledWithTDS += row.settledWithTDS;
  //         overallTotals.partiallyClaimSettled.recoverable += row.recoverable;
  //       } else if (row.category === 'Outstanding') {
  //         overallTotals.outstanding.count += row.count;
  //         overallTotals.outstanding.business += row.business;
  //         overallTotals.outstanding.settledWithTDS += row.settledWithTDS;
  //         overallTotals.outstanding.recoverable += row.recoverable;
  //       }
  //     });
  //   });
  // }


  transformClaimsData(data: any[]): { transformedData: any[], overallTotals: any } {
    let overallTotals = {
      fullyClaimSettled: { count: 0, business: 0, settledWithTDS: 0, recoverable: 0 },
      partiallyClaimSettled: { count: 0, business: 0, settledWithTDS: 0, recoverable: 0 },
      outstanding: { count: 0, business: 0, settledWithTDS: 0, recoverable: 0 }
    };
  
    const transformedData = data.map((entry) => {
      const rows = [
        {
          category: 'Fully Claim Settled',
          count: entry.fullClaimSettledCount || 0,
          business: entry.fullClaimRequestedAmount || 0,
          settledWithTDS: entry.fullClaimSettledApprovedWithTDS || 0,
          recoverable: entry.fullClaimRecoverable || 0,
        },
        {
          category: 'Partially Claim Settled',
          count: entry.partialClaimSettledCount || 0,
          business: entry.partialClaimAmount || 0,
          settledWithTDS: entry.partialClaimSettledApprovedWithTDS || 0,
          recoverable: entry.partialClaimRecoverable || 0,
        },
        {
          category: 'Outstanding',
          count: entry.outstandingClaimCount || 0,
          business: entry.outstandingClaimRequestedAmount || 0,
          settledWithTDS: entry.outstandingUndispatchedClaimAmount || 0,
          recoverable: entry.outstandingUndispatchedClaimAmount || 0,
        }
      ];
  
      // Update overall totals
      rows.forEach((row) => {
        if (row.category === 'Fully Claim Settled') {
          overallTotals.fullyClaimSettled.count += row.count;
          overallTotals.fullyClaimSettled.business += row.business;
          overallTotals.fullyClaimSettled.settledWithTDS += row.settledWithTDS;
          overallTotals.fullyClaimSettled.recoverable += row.recoverable;
        } else if (row.category === 'Partially Claim Settled') {
          overallTotals.partiallyClaimSettled.count += row.count;
          overallTotals.partiallyClaimSettled.business += row.business;
          overallTotals.partiallyClaimSettled.settledWithTDS += row.settledWithTDS;
          overallTotals.partiallyClaimSettled.recoverable += row.recoverable;
        } else if (row.category === 'Outstanding') {
          overallTotals.outstanding.count += row.count;
          overallTotals.outstanding.business += row.business;
          overallTotals.outstanding.settledWithTDS += row.settledWithTDS;
          overallTotals.outstanding.recoverable += row.recoverable;
        }
      });
  
      // Calculate totals for the current month
      const totalRow = {
        category: 'Total',
        count: rows.reduce((sum, row) => sum + row.count, 0),
        business: rows.reduce((sum, row) => sum + row.business, 0),
        settledWithTDS: rows.reduce((sum, row) => sum + row.settledWithTDS, 0),
        recoverable: rows.reduce((sum, row) => sum + row.recoverable, 0),
      };
  
      return {
        monthYear: entry.monthYear,
        rows: [...rows, totalRow], // Add total row for each month
      };
    });
  
    return { transformedData, overallTotals };
  }
  

  calculateTotals(): void {
    this.totalCount = 0;
    this.totalBusiness = 0;
    this.totalSettledWithTDS = 0;
    this.totalRecoverable = 0;

    this.monthlyDataMap.forEach(element => {
      element.rows.forEach((row:any) => {
        if(row.category == 'Total'){

        }else{
          this.totalCount += row.count || 0;
          this.totalBusiness += row.business || 0;
          this.totalSettledWithTDS += row.settledWithTDS || 0;
          this.totalRecoverable += row.recoverable || 0;
        }
      
      });
    });

    

  }

 
  getOnboardedHospitals(){
      this.cashlflowService.getOnboardedHospitals()
      .pipe(first())
      .subscribe({
        next: (hospitals:any) => {
          this.hospitalList = hospitals;
        },
        error: (error) => {
          console.log(error);
        }
      })
  }

}
