<div class="container py-1 ">

  <div class="row justify-content-md-center">
    <!-- <div class="col ">
          <div class="p-1 container text-center fw-bold">
              <p>
                  Manage Role!
              </p>
          </div>
      </div> -->
  </div>
  <div class="row  px-3 py-4 justify-content-md-center">
    <input type="search" (keyup)="applyFilter($event)" class="form-control" placeholder="Filter" aria-label="Search">
  </div>

  <!-- <div class="row  px-3 py-4 justify-content-md-center">
  <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
</div> -->
 
    <table mat-table [dataSource]="dataSource">
      <!-- Position Column -->
      <!-- {{selectedTask}} -->
      <ng-container matColumnDef="currenttask">
        <th mat-header-cell *matHeaderCellDef>Tasks</th>
        <td mat-cell *matCellDef="let element"
          [class]="element == selectedTask ? 'selected' : ''">
          {{ element.name }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="orderDate">
        <th mat-header-cell *matHeaderCellDef>Case Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdDate | date : "M/dd/yyyy hh:mm" }}
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="orderId">
        <th mat-header-cell *matHeaderCellDef>Case Id</th>
        <td mat-cell *matCellDef="let element">{{ element.processInstanceId }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef>Customer</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.caseVariables.firstname
          ? element.caseVariables.firstname + ' ' + element.caseVariables.lastname
          : "Na"
          }}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="hospital">
        <th mat-header-cell *matHeaderCellDef>Hospital Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.caseVariables.hospitalName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assignTo">
        <th mat-header-cell *matHeaderCellDef>Assign To</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.assignee != undefined">
            {{element.caseVariables?.assignedUser?.firstName + ' ' +
            element.caseVariables?.assignedUser?.lastName}}
          </span>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="center-header">Action</th>
        <td mat-cell *matCellDef="let element">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn" style="color:white;background:#8e0101" (click)="acceptTask(element)" [disabled]="element.assignee != undefined">
              Accept
            </button>
            <button type="button" class="btn" style="color:white;background:#8e0101"  (click)="assignTask(element)" [disabled]="!isPrivileged(['ADMIN','SUPERADMIN','CAN_ASSIGN_TASK'])">
              Assign
            </button>  
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of Tasks!">
    </mat-paginator>
  <hr />
  <div *ngIf="dataSource != undefined && dataSource.data.length <= 0" class="alert alert-primary" role="alert">
    You don't have any pending tasks at this moment. Check back later!
  </div>