import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';

@Component({
  selector: 'app-update-receiving-email',
  templateUrl: './update-receiving-email.component.html',
  styleUrl: './update-receiving-email.component.scss'
})
export class UpdateReceivingEmailComponent implements OnInit{

  updateForm!: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private hospitalRegistrationService: HospitalRegistrationService,
    private toastrService: ToastrService,
    private matDialogRef: MatDialogRef<UpdateReceivingEmailComponent>,
  ){

  }
  ngOnInit(): void {
    this.updateForm = this.formBuilder.group({
      info:[''],
      fieldType:[''],
      fieldValue:[''],
      fieldName:[''],
      fieldCategory:['']
    });
    this.updateForm.patchValue(this.data); 
  }

  onFormSubmit(){
    let payload = {
      id:this.data.id,
      info: this.updateForm.value.info,
      fieldType: this.updateForm.value.fieldType,
      fieldValue: this.updateForm.value.fieldValue,
      fieldName: this.updateForm.value.fieldName,
      fieldCategory: this.updateForm.value.fieldCategory
    }
    this.hospitalRegistrationService.updateDetail(this.data.id,payload)
    .subscribe({
      next:(data:any)=>{
        console.log(data);
        this.toastrService.success('Details updated successfully');
        this.close();
      }
    })
  }

  close(){
    this.matDialogRef.close();
  }

}
