<div class="grid pt-2 bg-white">

    <div class="row pt-3 text-start">
        <div class="col-3 p-3">
            <div class="card bg-light">
              <div class="card-body">
                <div class="row">
                  <div class="col-4"><span class="badge fs-2 " style="background:rgb(253, 207, 134)"><fa-icon class="fa-xl"
                        [icon]="faClock"></fa-icon></span></div>
                  <div class="col text-end">
                    <h3>Assigned</h3>
                    <div class="fs-1 text-secondary">{{assignedTasks.length}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        <div class="col-8  p-3">
            <div *ngIf="assignedTasks == undefined || assignedTasks.length <= 0" class="alert alert-primary"
                role="alert">
                There are no assigned tasks at this moment. Check back later!
            </div>
            <div *ngIf="assignedTasks == undefined || assignedTasks.length >= 0" class="alert alert-white" role="light">
                The following are the tasks that have already been assigned!
            </div>
        </div>
    </div>
    <div class="bg-light">
        <br>
    </div>
   
    <div class="p-3">
        <div class="row p-1">
            <div class="col h6">
               Task name
            </div>
            <div class="col h6">
               Due Date
            </div>
            <div class="col h6">
               Task ID
            </div>
            <div class="col h6">
                Assigned to
            </div>
            <div class="col h6">
                Hospital Name
            </div>
            <div class="col h6">
                Action
            </div>
        </div>
        <hr>
        <div class="row p-1" *ngFor="let task of assignedTasks" [class]="escalation(task.dueDate)?'h6 text-danger':''">
            
            <div class="col overflow-auto">
                {{task.name}}
            </div>
            <div class="col overflow-auto">
                {{task.dueDate | date}}
            </div>
            <div class="col overflow-auto">
               {{task.id}}
            </div>
            <div class="col overflow-auto" *ngIf="task?.localVariables?.assignedUser !=null">
                <!-- {{task.firstName}} {{task.lastName}} ({{task.mobilePhone}}) -->
                {{task?.localVariables?.assignedUser?.firstName}} {{task?.localVariables?.assignedUser?.lastName}}
                [{{task?.localVariables?.assignedUser?.mobilePhone}}]

            </div>
           
            <div class="col overflow-auto text-start text-warning" *ngIf="task?.localVariables?.assignedUser == null">
                Not assigned.
            </div>
            <div class="col overflow-auto">
              
                {{task.caseVariables.hospitalName}}
            </div>
            <div class="col overflow-auto">
                <div class="btn-group" role="group" aria-label="Basic example">    
                    <button [disabled]="!isPrivileged(['ADMIN','SUPERADMIN','CAN_ASSIGN_TASK'])" class="btn" type="button" style="color:white;background:#8e0101"
                    (click)="assignTask(task)">
                    Re Assign</button>
                    <button type="button" style="color:white;background:#8e0101;margin-left:1px"  class="btn" (click)="viewTask(task)">View </button>
                 
                  </div>
                
            </div>

        </div>
    </div>

</div>