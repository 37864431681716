<mat-tab-group>
    <mat-tab label="Consolidated Dashboard">
        <div class="container p-3">
            <div [formGroup]="dateForm">

                <mat-form-field *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN','CAN_VIEW_HOSPITAL'])" style="width:100%">
                    <mat-label>Select Hospital</mat-label>
                    <mat-select [(value)]="selectedHospital" (selectionChange)="hospitalSelected($event)"
                        required="true">
                        <mat-option value="ALL" *ngIf="!isPrivileged(['ADMIN', 'SUPERADMIN','IS_HOSPITAL_USER'])">ALL</mat-option>
                        @for (hospital of hospitals; track hospital) {
                        <mat-option [value]="hospital.hospitalOrgId">{{hospital.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!isPrivileged(['ADMIN', 'SUPERADMIN' ,'CAN_VIEW_HOSPITAL'])">
                    <mat-label>Hospital Name</mat-label>
                    <input matInput [value]="selectedHospital.name" disabled="true">
                </mat-form-field>
            </div>
            <div *ngIf="hospitalDashboardData.hospitalInceptionDate" class="">
                <div class="row  pb-2">
                    <div class="col h3">
                        <p>&nbsp;</p>
                        <p class="h3">Dashboard</p>
                        <p class="h4">Since Inception- {{hospitalDashboardData.hospitalInceptionDate | date }}</p>
                    </div>
                    <div class="col bg-white p-4">

                        <p> <span class="h4">{{ hospitalDashboardData.hospitalName }}, {{
                                hospitalDashboardData.hospitalCity }}</span></p>
                        <p> RohiniID : {{ hospitalDashboardData.rohiniId }}</p>
                        <p> Outstanding at Inception: {{ hospitalDashboardData.hospital.currentOutstanding |
                            currency:'INR':'symbol-narrow':'4.2-2' }}</p>

                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col bg-white p-3">
                    <div class="row">
                        <div class="col border-start-0 border-top-0 border-bottom-0 border-end-1 border ">
                            <div class="row">
                                <div class="col text-secondary">
                                    <h2>Admissions</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col display-6">
                                    {{hospitalDashboardData.totalAdmissions}}
                                </div>
                            </div>
                        </div>
                        <div class="col border-start-0 border-top-0 border-bottom-0 border-end-1 border ">
                            <div class="row">
                                <div class="col text-secondary">
                                    <h2>Discharges</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col display-6">
                                    {{hospitalDashboardData.totalDischarges}}
                                </div>
                            </div>
                        </div>
                        <div class="col border-start-0 border-top-0 border-bottom-0 border-end-1 border ">
                            <div class="row">
                                <div class="col text-secondary">
                                    <h2>Outstanding</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col display-6">
                                    {{hospitalDashboardData.pendingSettlement | currency:'INR':'symbol-narrow':'4.2-2'}}
                                </div>
                            </div>
                        </div>
                        <div class="col border-start-0 border-top-0 border-bottom-0 border-end-1 border ">
                            <div class="row">
                                <div class="col text-secondary">
                                    <h2>Settled</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col display-6">
                                    {{hospitalDashboardData.totalSettlement | currency:'INR':'symbol-narrow':'4.2-2'}}
                                </div>
                            </div>
                        </div>
                        <div class="col ">
                            <div class="row">
                                <div class="col text-secondary">
                                    <h2>Total</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col display-6">
                                    {{hospitalDashboardData.totalAmount | currency:'INR':'symbol-narrow':'4.2-2'}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <hr>
            <br>
            <div class="row p-3 bg-white" *ngIf="selectedHospital != undefined">

                <div class="col  border-start-0 border-top-0 border-bottom-0 border-end-1 border">
                    <div [formGroup]="dateForm">

                        <mat-form-field>
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="fromDate"
                                (dateChange)="onChangeEvent($event)">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                    </div>
                </div>
                <div class="col border-start-0 border-top-0 border-bottom-0 border-end-1 border ">
                                    
                    <div class="row">
                        <div class="col text-secondary">
                            <h2>Admissions</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col display-6">
                            {{hospitalDashboardData.dailyAdmissions?.length}}
                        </div>
                    </div>
                </div>
                <div class="col ">
                    <div class="row">
                        <div class="col text-secondary">
                            <h2>Discharges</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col display-6">
                            {{hospitalDashboardData.dailyDischarge?.length}}
                        </div>
                    </div>
                </div>

            </div>
            <hr>
            <div class="row bg-white " *ngIf="selectedHospital != undefined">

                <div class="col">
                    <h2> As of {{dt.toLocaleString('default', { month: 'long' })}}</h2>

                </div>
            </div>
            
            
            <div class="row bg-white  " *ngIf="selectedHospital != undefined">
                <div class="col">
                    <div class="row">
                        <div class="col ">

                            <div class="row">
                                
                                <div class="col  border-start-0 border-top-0 border-bottom-0 border-end-1 border ">
                                    <div class="row">
                                        <div class="col text-secondary">
                                            <h2>Outstanding</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col display-6">
                                            {{hospitalDashboardData.monthlyPendingSettlement |
                                            currency:'INR':'symbol-narrow':'4.2-2'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col  border-start-0 border-top-0 border-bottom-0 border-end-1 border ">
                                    <div class="row">
                                        <div class="col text-secondary">
                                            <h2>Settled</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col display-6">
                                            {{hospitalDashboardData.monthlySettlementCompleted |
                                            currency:'INR':'symbol-narrow':'4.2-2'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col ">
                                    <div class="row">
                                        <div class="col text-secondary">
                                            <h2>Total</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col display-6">
                                            {{hospitalDashboardData.monthlyTotalAmount |
                                            currency:'INR':'symbol-narrow':'4.2-2'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <br>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <h2>Admissions</h2>
                    </div>

                    <div class="row">
                        <mat-tab-group>

                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Daily
                                    <span class="count-box">{{ dailyAdmissions.length }}</span>
                                </ng-template>
                                <div [hidden]="dailyAdmissionsDataSource.data.length == 0">
                                    <table mat-table [dataSource]="dailyAdmissionsDataSource"
                                    *ngIf="dailyAdmissions.length >0" style="padding-top: 10px;">
                                    <ng-container matColumnDef="IPDNo">
                                        <th mat-header-cell *matHeaderCellDef>IPD No.</th>
                                        <td mat-cell *matCellDef="let element">{{element.ipdNo}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="caseId">
                                        <th mat-header-cell *matHeaderCellDef>Case ID</th>
                                        <td mat-cell *matCellDef="let element">{{element.caseId}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="patientName">
                                        <th mat-header-cell *matHeaderCellDef>Patient Name</th>
                                        <td mat-cell *matCellDef="let element">{{element.patientName}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="admissionDate">
                                        <th mat-header-cell *matHeaderCellDef>Admission Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfAdmission | date}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="dischargeDate">
                                        <th mat-header-cell *matHeaderCellDef>Discharge Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfDischarge | date}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="doctor">
                                        <th mat-header-cell *matHeaderCellDef>Doctor</th>
                                        <td mat-cell *matCellDef="let element">{{element.treatingDoctorName}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef>Status</th>
                                        <td mat-cell *matCellDef="let element">{{element.currentProcessStatus}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                                    <tr mat-row *matRowDef="let row;  columns:displayColumns"></tr>
                                </table>
                                <mat-paginator #dailyAdmissionsPaginator
                                    [length]="dailyAdmissionsDataSource.data.length" [pageSizeOptions]="[5, 10, 20]"
                                    showFirstLastButtons ></mat-paginator>
                                </div>
                                

                                <div class="alert alert-primary" role="alert" *ngIf="dailyAdmissions.length == 0"
                                    style="padding-top: 10px;text-align: center;">
                                    No data available
                                </div>
                            </mat-tab>
                            <mat-tab style="padding-top: 10px;">
                                <ng-template mat-tab-label>
                                    Weekly
                                    <span class="count-box">{{ weeklyAdmissions.length }}</span>
                                </ng-template>
                                <div [hidden]="weeklyAdmissionsDataSource.data.length == 0">
                                    <table mat-table [dataSource]="weeklyAdmissionsDataSource"
                                    *ngIf="weeklyAdmissions.length >0">
                                    <ng-container matColumnDef="IPDNo">
                                        <th mat-header-cell *matHeaderCellDef>IPD No.</th>
                                        <td mat-cell *matCellDef="let element">{{element.ipdNo}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="caseId">
                                        <th mat-header-cell *matHeaderCellDef>Case ID</th>
                                        <td mat-cell *matCellDef="let element">{{element.caseId}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="patientName">
                                        <th mat-header-cell *matHeaderCellDef>Patient Name</th>
                                        <td mat-cell *matCellDef="let element">{{element.patientName}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="admissionDate">
                                        <th mat-header-cell *matHeaderCellDef>Admission Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfAdmission | date}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="dischargeDate">
                                        <th mat-header-cell *matHeaderCellDef>Discharge Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfDischarge | date}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="doctor">
                                        <th mat-header-cell *matHeaderCellDef>Doctor</th>
                                        <td mat-cell *matCellDef="let element">{{element.treatingDoctorName}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef>Status</th>
                                        <td mat-cell *matCellDef="let element">{{element.currentProcessStatus}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                                    <tr mat-row *matRowDef="let row;  columns:displayColumns"></tr>
                                </table>
                                <mat-paginator #weeklyAdmissionsPaginator
                                    [length]="weeklyAdmissionsDataSource.data.length" [pageSizeOptions]="[5, 10, 20]"
                                    showFirstLastButtons></mat-paginator>
                                </div>
                               
                                <div class="alert alert-primary" role="alert" *ngIf="weeklyAdmissions.length == 0"
                                    style="padding-top: 10px;">
                                    No data available
                                </div>
                            </mat-tab>
                            <mat-tab style="padding-top: 10px;">
                                <ng-template mat-tab-label>
                                    Monthly
                                    <span class="count-box">{{ monthlyAdmissions.length }}</span>
                                </ng-template>
                                <div [hidden] = "monthlyAdmissionsDataSource.data.length ==  0">
                                    <table mat-table [dataSource]="monthlyAdmissionsDataSource"
                                    *ngIf="monthlyAdmissions.length >0">
                                    <ng-container matColumnDef="IPDNo">
                                        <th mat-header-cell *matHeaderCellDef>IPD No.</th>
                                        <td mat-cell *matCellDef="let element">{{element.ipdNo}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="caseId">
                                        <th mat-header-cell *matHeaderCellDef>Case ID</th>
                                        <td mat-cell *matCellDef="let element">{{element.caseId}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="patientName">
                                        <th mat-header-cell *matHeaderCellDef>Patient Name</th>
                                        <td mat-cell *matCellDef="let element">{{element.patientName}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="admissionDate">
                                        <th mat-header-cell *matHeaderCellDef>Admission Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfAdmission | date}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="dischargeDate">
                                        <th mat-header-cell *matHeaderCellDef>Discharge Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfDischarge | date}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="doctor">
                                        <th mat-header-cell *matHeaderCellDef>Doctor</th>
                                        <td mat-cell *matCellDef="let element">{{element.treatingDoctorName}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef>Status</th>
                                        <td mat-cell *matCellDef="let element">{{element.currentProcessStatus}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                                    <tr mat-row *matRowDef="let row;  columns:displayColumns"></tr>
                                </table>
                                <mat-paginator #monthlyAdmissionsPaginator
                                    [length]="monthlyAdmissionsDataSource.data.length" [pageSizeOptions]="[5, 10, 20]"
                                    showFirstLastButtons ></mat-paginator>
                                </div>
                              
                                <div class="alert alert-primary" role="alert" *ngIf="monthlyAdmissions.length == 0"
                                    style="padding-top: 10px;">
                                    No data available
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>

                </div>
            </div>
            <br>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <h2>Discharge</h2>
                    </div>
                    <div class="row">
                        <mat-tab-group>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Daily
                                    <span class="count-box">{{ dailyDischarge.length }}</span>
                                </ng-template>
                                <div [hidden]="dailyDischargeDataSource.data.length == 0">
                                    <table mat-table [dataSource]="dailyDischargeDataSource"
                                    *ngIf="dailyDischarge.length > 0">
                                    <ng-conatiner matColumnDef="IPDNo">
                                        <th mat-header-cell *matHeaderCellDef>IPD No</th>
                                        <td mat-cell *matCellDef="let element">{{element.ipdNo}}</td>
                                    </ng-conatiner>

                                    <ng-container matColumnDef="caseId">
                                        <th mat-header-cell *matHeaderCellDef>Case ID</th>
                                        <td mat-cell *matCellDef="let element">{{element.caseId}}</td>
                                    </ng-container>

                                    <ng-conatiner matColumnDef="patientName">
                                        <th mat-header-cell *matHeaderCellDef>Patinet Name</th>
                                        <td mat-cell *matCellDef="let element">{{element.patientName}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="admissionDate">
                                        <th mat-header-cell *matHeaderCellDef>Admission Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfAdmission | date}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="dischargeDate">
                                        <th mat-header-cell *matHeaderCellDef>Discharge Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfDischarge | date}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="doctor">
                                        <th mat-header-cell *matHeaderCellDef>Doctor</th>
                                        <td mat-cell *matCellDef="let element">{{element.treatingDoctorName}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef>Status</th>
                                        <td mat-cell *matCellDef="let element">{{element.currentProcessStatus}}</td>
                                    </ng-conatiner>

                                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                                    <tr mat-row *matRowDef="let row;  columns:displayColumns"></tr>
                                </table>
                                <mat-paginator #dailyDischargePaginator [length]="dailyDischargeDataSource.data.length"
                                    [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                                </div>
                               
                                <div class="alert alert-primary" role="alert" *ngIf="dailyDischarge.length == 0"
                                    style="padding-top: 10px;text-align: center;">
                                    No data available
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Weekly
                                    <span class="count-box">{{ weeklyDischarge.length }}</span>
                                </ng-template>
                                <div [hidden]="weeklyDischargeDataSource.data.length == 0">
                                    <table mat-table [dataSource]="weeklyDischargeDataSource"
                                    *ngIf="weeklyDischarge.length > 0">
                                    <ng-conatiner matColumnDef="IPDNo">
                                        <th mat-header-cell *matHeaderCellDef>IPD No</th>
                                        <td mat-cell *matCellDef="let element">{{element.ipdNo}}</td>
                                    </ng-conatiner>

                                    <ng-container matColumnDef="caseId">
                                        <th mat-header-cell *matHeaderCellDef>Case ID</th>
                                        <td mat-cell *matCellDef="let element">{{element.caseId}}</td>
                                    </ng-container>

                                    <ng-conatiner matColumnDef="patientName">
                                        <th mat-header-cell *matHeaderCellDef>Patinet Name</th>
                                        <td mat-cell *matCellDef="let element">{{element.patientName}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="admissionDate">
                                        <th mat-header-cell *matHeaderCellDef>Admission Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfAdmission | date}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="dischargeDate">
                                        <th mat-header-cell *matHeaderCellDef>Discharge Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfDischarge | date}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="doctor">
                                        <th mat-header-cell *matHeaderCellDef>Doctor</th>
                                        <td mat-cell *matCellDef="let element">{{element.treatingDoctorName}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef>Status</th>
                                        <td mat-cell *matCellDef="let element">{{element.currentProcessStatus}}</td>
                                    </ng-conatiner>

                                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                                    <tr mat-row *matRowDef="let row;  columns:displayColumns"></tr>
                                </table>
                                <mat-paginator #weeklyDischargePaginator
                                    [length]="weeklyDischargeDataSource.data.length" [pageSizeOptions]="[5, 10, 20]"
                                    showFirstLastButtons ></mat-paginator>
                                </div>
                              
                                <div class="alert alert-primary" role="alert" *ngIf="weeklyDischarge.length == 0"
                                    style="padding-top: 10px;text-align: center;">
                                    No data available
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    Monthly
                                    <span class="count-box">{{ monthlyDischarge.length }}</span>
                                </ng-template>
                                <div [hidden]="monthlyDischargeDataSource.data.length == 0">
                                    <table mat-table [dataSource]="monthlyDischargeDataSource"
                                    *ngIf="monthlyDischarge.length > 0">
                                    <ng-conatiner matColumnDef="IPDNo">
                                        <th mat-header-cell *matHeaderCellDef>IPD No</th>
                                        <td mat-cell *matCellDef="let element">{{element.ipdNo}}</td>
                                    </ng-conatiner>

                                    <ng-container matColumnDef="caseId">
                                        <th mat-header-cell *matHeaderCellDef>Case ID</th>
                                        <td mat-cell *matCellDef="let element">{{element.caseId}}</td>
                                    </ng-container>

                                    <ng-conatiner matColumnDef="patientName">
                                        <th mat-header-cell *matHeaderCellDef>Patinet Name</th>
                                        <td mat-cell *matCellDef="let element">{{element.patientName}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="admissionDate">
                                        <th mat-header-cell *matHeaderCellDef>Admission Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfAdmission | date}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="dischargeDate">
                                        <th mat-header-cell *matHeaderCellDef>Discharge Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.dateOfDischarge | date}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="doctor">
                                        <th mat-header-cell *matHeaderCellDef>Doctor</th>
                                        <td mat-cell *matCellDef="let element">{{element.treatingDoctorName}}</td>
                                    </ng-conatiner>

                                    <ng-conatiner matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef>Status</th>
                                        <td mat-cell *matCellDef="let element">{{element.currentProcessStatus}}</td>
                                    </ng-conatiner>

                                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
                                </table>
                                <mat-paginator #monthlyDischargePaginator
                                    [length]="monthlyDischargeDataSource.data.length" [pageSizeOptions]="[5, 10, 20]"
                                    showFirstLastButtons></mat-paginator>
                                </div>
                               
                                <div class="alert alert-primary" role="alert" *ngIf="monthlyDischarge.length == 0"
                                    style="padding-top: 10px;text-align: center;">
                                    No data available
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <h2>File Dispatch Pending</h2>
                            </div>
                            <div class="row">
                                <mat-tab-group>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            1-2 Days
                                            <span class="count-box">{{ dispatchPending1to2Days.length }}</span>
                                        </ng-template>
                                        <div [hidden]="dispatchPending1to2DayseDataSource.data.length == 0">
                                            <table mat-table [dataSource]="dispatchPending1to2DayseDataSource"
                                            *ngIf="dispatchPending1to2Days.length">
                                            <ng-container matColumnDef="IPDNo">
                                                <th mat-header-cell *matHeaderCellDef>IPD No</th>
                                                <td mat-cell *matCellDef="let element">{{ element.ipdNo }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="patientName">
                                                <th mat-header-cell *matHeaderCellDef>Patient Name</th>
                                                <td mat-cell *matCellDef="let element">{{ element.patientName }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="finalApprovalDate">
                                                <th mat-header-cell *matHeaderCellDef>Final Approval Date</th>
                                                <td mat-cell *matCellDef="let element">{{ element.dateOfAdmission | date
                                                    }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="dischargeDate">
                                                <th mat-header-cell *matHeaderCellDef>Discharge Date</th>
                                                <td mat-cell *matCellDef="let element">{{ element.dateOfDischarge | date
                                                    }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="doctor">
                                                <th mat-header-cell *matHeaderCellDef>Doctor</th>
                                                <td mat-cell *matCellDef="let element">{{ element.treatingDoctorName }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                                <td mat-cell *matCellDef="let element">{{ element.currentProcessStatus
                                                    }}</td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="fileDispatchColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: fileDispatchColumns"></tr>
                                        </table>

                                        <mat-paginator #dispatchPending1to2DaysPaginator
                                            [length]="dispatchPending1to2DayseDataSource.data.length"
                                            [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                        </div>
                                        
                                        <div class="alert alert-primary" role="alert"
                                            *ngIf="dispatchPending1to2Days.length == 0"
                                            style="padding-top: 10px;text-align: center;">
                                            No data available
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            3-7 Days
                                            <span class="count-box">{{ dispatchPending3to7Days.length }}</span>
                                        </ng-template>
                                        <div [hidden]="dispatchPending3to7DaysDataSource.data.length == 0">
                                            <table mat-table [dataSource]="dispatchPending3to7DaysDataSource"
                                            *ngIf="dispatchPending3to7Days.length > 0">
                                            <ng-container matColumnDef="IPDNo">
                                                <th mat-header-cell *matHeaderCellDef>IPD No</th>
                                                <td mat-cell *matCellDef="let element">{{element.ipdNo}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="patientName">
                                                <th mat-header-cell *matHeaderCellDef>Patient Name</th>
                                                <td mat-cell *matCellDef="let element">{{element.patientName}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="finalApprovalDate">
                                                <th mat-header-cell *matHeaderCellDef>Final Approval Date</th>
                                                <td mat-cell *matCellDef="let element">{{element.dateOfAdmission |
                                                    date}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="dischargeDate">
                                                <th mat-header-cell *matHeaderCellDef>Discharge Date</th>
                                                <td mat-cell *matCellDef="let element">{{element.dateOfDischarge |
                                                    date}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="doctor">
                                                <th mat-header-cell *matHeaderCellDef>Doctor</th>
                                                <td mat-cell *matCellDef="let element">{{element.treatingDoctorName}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                                <td mat-cell *matCellDef="let element">{{element.currentProcessStatus}}
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="fileDispatchColumns"></tr>
                                            <tr mat-row *matRowDef="let row;  columns:fileDispatchColumns"></tr>


                                        </table>
                                        <mat-paginator #dispatchPending3to7DaysPaginator
                                            [length]="dispatchPending3to7DaysDataSource.data.length"
                                            [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                        </div>
                                        
                                        <div class="alert alert-primary" role="alert"
                                            *ngIf="dispatchPending3to7Days.length == 0"
                                            style="padding-top: 10px;text-align: center;">
                                            No data available
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        
                                        <ng-template mat-tab-label>
                                            7 Days & Above
                                            <span class="count-box">{{ dispatchPending7DaysAndAbove.length }}</span>
                                        </ng-template>
                                        <div [hidden]="dispatchPending7DaysAndAboveDataSource.data.length == 0">
                                            <table mat-table [dataSource]="dispatchPending7DaysAndAboveDataSource"
                                            *ngIf="dispatchPending7DaysAndAbove.length >0">
                                            <ng-container matColumnDef="IPDNo">
                                                <th mat-header-cell *matHeaderCellDef>IPD No</th>
                                                <td mat-cell *matCellDef="let element">{{element.ipdNo}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="patientName">
                                                <th mat-header-cell *matHeaderCellDef>Patient Name</th>
                                                <td mat-cell *matCellDef="let element">{{element.patientName}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="finalApprovalDate">
                                                <th mat-header-cell *matHeaderCellDef>Final Approval Date</th>
                                                <td mat-cell *matCellDef="let element">{{element.dateOfAdmission |
                                                    date}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="dischargeDate">
                                                <th mat-header-cell *matHeaderCellDef>Discharge Date</th>
                                                <td mat-cell *matCellDef="let element">{{element.dateOfDischarge |
                                                    date}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="doctor">
                                                <th mat-header-cell *matHeaderCellDef>Doctor</th>
                                                <td mat-cell *matCellDef="let element">{{element.treatingDoctorName}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                                <td mat-cell *matCellDef="let element">{{element.currentProcessStatus}}
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="fileDispatchColumns"></tr>
                                            <tr mat-row *matRowDef="let row;  columns:fileDispatchColumns"></tr>


                                        </table>
                                        <mat-paginator #dispatchPending7DaysAndAbovePaginator
                                            [length]="dispatchPending7DaysAndAboveDataSource.data.length"
                                            [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                        </div>
                                       
                                        <div class="alert alert-primary" role="alert"
                                            *ngIf="dispatchPending7DaysAndAbove.length == 0"
                                            style="padding-top: 10px;text-align: center;">
                                            No data available
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <h2>Outstanding & Settled Amount</h2>
                            </div>
                            <div class="row">
                                <mat-tab-group>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            TPA
                                            <span class="count-box">{{ tpaAmounts.length }}</span>
                                        </ng-template>
                                        <table mat-table [dataSource]="tpaAmountsDataSource"
                                            *ngIf="tpaAmounts.length > 0">
                                            <ng-conatiner matColumnDef="tpa">
                                                <th mat-header-cell *matHeaderCellDef>TPA</th>
                                                <td mat-cell *matCellDef="let element">{{element.name}}</td>
                                                <td mat-footer-cell *matFooterCellDef><strong>Total</strong></td>
                                            </ng-conatiner>

                                            <ng-conatiner matColumnDef="outstandingAmount">
                                                <th mat-header-cell *matHeaderCellDef>Outstanding Amount</th>
                                                <td mat-cell *matCellDef="let element">{{element.pendingPayment}}</td>
                                                <td mat-footer-cell *matFooterCellDef><strong>{{
                                                        getTotal('pendingPayment') }}</strong></td>
                                            </ng-conatiner>

                                            <ng-conatiner matColumnDef="settledAmount">
                                                <th mat-header-cell *matHeaderCellDef>Settled Amount</th>
                                                <td mat-cell *matCellDef="let element">{{element.settledAmount}}</td>
                                                <td mat-footer-cell *matFooterCellDef><strong>{{
                                                        getTotal('settledAmount') }}</strong></td>
                                            </ng-conatiner>

                                            <tr mat-header-row *matHeaderRowDef="tpaDisplayColumns"></tr>
                                            <tr mat-row *matRowDef="let row;  columns:tpaDisplayColumns"></tr>
                                            <tr mat-footer-row *matFooterRowDef="tpaDisplayColumns"></tr>

                                            <!-- <tr>
                                                <td colspan="1" style="font-weight: bold;">Total</td>
                                                <td style="font-weight: bold;">{{ getTotal('pendingPayment') }}</td>
                                                <td style="font-weight: bold;">{{ getTotal('settledAmount') }}</td>
                                            </tr> -->
                                        </table>
                                        <mat-paginator #tpaAmountsPaginator [length]="tpaAmountsDataSource.data.length"
                                            [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                                           ></mat-paginator>

                                        <div class="alert alert-primary" role="alert" *ngIf="tpaAmounts.length == 0"
                                            style="padding-top: 10px;text-align: center;">
                                            No data available
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </mat-tab>
   
   <mat-tab label="Weekly Dashboard">
    <app-weekly-dashboard></app-weekly-dashboard>
   </mat-tab>
    <!-- <mat-tab label="Business Dashboard" *ngIf="isPrivileged(['ADMIN','SUPERADMIN'])">
        <div class="container p-3">

            <div class="h1 pb-2">Business Dashboard</div>
            <div [formGroup]="dateForm2">
                <mat-form-field>
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="fromDate"
                        (dateChange)="onChangeEvent_BusinessDashboard($event)">
                    <mat-hint>MM/DD/YYYY</mat-hint>

                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <mat-form-field *ngIf="isPrivileged(['CAN_VIEW_HOSPITAL'])">
                    <mat-label>Select Hospital</mat-label>
                    <mat-select [(value)]="selectedHospital_BusinessDashboard" (selectionChange)="hospitalSelected_BusinessDashboard($event)">
                        <mat-option value="ALL" *ngIf="!isPrivileged(['IS_HOSPITAL_USER'])">ALL</mat-option>
                        @for (hospital of hospitals; track hospital) {
                        <mat-option [value]="hospital.hospitalOrgId">{{hospital.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!isPrivileged(['CAN_VIEW_HOSPITAL'])">
                    <mat-label>Hospital Name</mat-label>
                    <input matInput [value]="selectedHospital_BusinessDashboard.name" disabled="true">
                </mat-form-field>
            </div>
            <hr>
            <div class="row" *ngIf="dashboardData.length >0">
                <strong>{{dashboardData[0].processName | uppercase}}</strong>
                <div class="col-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col text-secondary">
                                    <h3>Pending settlements</h3>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col"><span class="badge fs-2 " style="background:rgb(254, 215, 87)"><fa-icon
                                            class="fa-xl" [icon]="faClock"></fa-icon></span></div>
                                <div class="col text-end">

                                    <h2 class="text-secondary">{{dashboardData[0].header.pendingSettlement
                                        }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col text-secondary">
                                    <h3>Settlements completed</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col"><span class="badge fs-2 " style="background:rgb(87, 209, 254)"><fa-icon
                                            class="fa-xl" [icon]="faFlagCheckered"></fa-icon></span></div>
                                <div class="col text-end">
                                    <h2 class="text-secondary">{{dashboardData[0].header.settlementCompleted}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col text-secondary">
                                    <h3>Partial Settlements</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col"><span class="badge fs-2 " style="background:rgb(254, 154, 87)"><fa-icon
                                            class="fa-xl" [icon]="faClock"></fa-icon></span></div>
                                <div class="col text-end">
                                    <h2 class="text-secondary">{{dashboardData[0].header.
                                        partialSetlement}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="row">
                                    <div class="col text-secondary">
                                        <h3>Rejected Cases</h3>
                                    </div>
                                </div>
                                <div class="col"><span class="badge fs-2 " style="background:rgb(254, 87, 115)"><fa-icon
                                            class="fa-xl" [icon]="faFilterCircleXmark"></fa-icon></span></div>
                                <div class="col text-end">
                                    <h1 class="text-secondary">{{dashboardData[0].header.rejectedCases}}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h1 pt-5">Operational Dashboard</div>
            <hr>
            <div class="row" *ngIf="dashboardData.length >0">
                <div class="col-4 p-2 " *ngFor="let element of dashboardData">
                    <div class="card ">
                        <div class="card-header" style="background:white;">
                            <div class="row">
                                <div class="col">
                                    <h3 class="card-title text-secondary"><strong>{{element.processName |
                                            camelCaseToSpaces |
                                            uppercase}} </strong></h3>
                                </div>
                                <div class="col-3 text-light text-end" (click)="analyze(element.processName) "><span
                                        class="badge fs-6 " style="background:rgb(191, 191, 191)"><fa-icon class="fa-xl"
                                            [icon]="faMagnifyingGlassChart"></fa-icon></span></div>

                                                        </div>

                        </div>
                        <div class="card-body">

                            <div class="row">
                                <div class="col ">
                                    <div class="row">
                                        <div class="col ">
                                            <h5 class="card-title  text-center">Completed</h5>
                                        </div>

                                        <div class="col  text-center align-middle">
                                            <h1 class="text-light badge" style="background:rgb(159, 213, 114)"><span
                                                    class="badge">{{element.processCompleted?element.processCompleted:'0'}}</span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col ">
                                            <h5 class="card-title  text-center">Underway</h5>
                                        </div>
                                        <div class="col  text-center align-middle">
                                            <h1 class="text-light bg-warning badge"><span
                                                    class="badge">{{element.processInProgress?element.processInProgress:'0'}}</span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col ">
                                            <h5 class="card-title text-center">Avg Duration (Mins)</h5>
                                        </div>
                                        <div class="col  text-center align-middle">
                                            <h1 class="text-light badge" style="background:rgb(140, 210, 254)"><span
                                                    class="badge">{{calculateAvgDuration(element.completedInstances ) |
                                                    number:'4.0-0'}}</span></h1>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>


        </div>
    </mat-tab> -->
</mat-tab-group>