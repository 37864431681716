<!-- <div class="container py-1 p-5   p-4" > -->
<!-- <div class="container mh-800 card overflow-y-scroll" > -->
<section>

  <div class="container" style="overflow: none">
    <mat-tab-group>
      <mat-tab label="Dashboard">
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">dashboard</mat-icon>
          Dashboard
        </ng-template>
        <app-workflow-dashboard [dashboardParam]="getDashboardParam()"></app-workflow-dashboard>
      </mat-tab>
      <mat-tab label="Cases">
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">folder</mat-icon>
          Cases
        </ng-template>
     
         <app-manage-reimbursement-cases></app-manage-reimbursement-cases>
      </mat-tab>
      <mat-tab label="Inbox (New Tasks)">
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">inbox</mat-icon>
          Inbox 
        </ng-template>
        <div class="container p-3 card">
          <!-- Old -->

          <div class="">
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="pendingTask">
                <th mat-header-cell *matHeaderCellDef>Tasks</th>
                <td mat-cell *matCellDef="let element" (click)="selectTask(element)"
                  [class]="element == selectedTask ? 'selected' : ''">
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="productName">
                <th mat-header-cell *matHeaderCellDef>Product Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.caseVariables?.product?.name || '-' }}

                </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="orderDate">
                <th mat-header-cell *matHeaderCellDef>Order Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.caseVariables?.order?.transactionDate | date : "short" || '-' }}

                </td>
              </ng-container>


              <!-- Weight Column -->
              <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef>Case Id</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.processInstanceId}}
                </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef>Customer Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.caseVariables?.customer?.firstName +" "+element.caseVariables?.customer?.lastName ||
                  element.caseVariables?.customerUserId?.emailId || '-'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="customerMobileNumber">
                <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.caseVariables?.customer?.mobilePhone || element.caseVariables?.customerUserId?.mobilePhone
                  || '-'}}
                </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="assignedTo">
                <th mat-header-cell *matHeaderCellDef>Assigned to</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.assignee != undefined">{{ element.caseVariables.assignedUser.firstName }}
                    {{element.caseVariables.assignedUser.lastName }}</span>
                </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button"  class="btn" style="color:white;background:#8e0101;" (click)="acceptTask(element)" >
                      Accept
                    </button>
                  
                    <button type="button"  class="btn" style="color:white;background:#8e0101;"  (click)="assignTask(element)">
                      Assign
                    </button>

                    <button type="button"  class="btn" style="color:white;background:#8e0101;"  (click)="viewTask(element)">
                      View
                    </button>
                    
                  </div>

                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of Tasks!">
            </mat-paginator>
          </div>


          <div *ngIf="dataSource != undefined && dataSource.data.length <= 0" class="alert alert-primary" role="alert">
            You don't have any pending tasks at this moment. Check back later!
          </div>

        </div>
      </mat-tab>
      <!-- <mat-tab label="Assignment (Pending Tasks)">
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">assignment</mat-icon>
          Assignments (Pending Tasks)
        </ng-template>
        <app-assignment-reimbursement></app-assignment-reimbursement>
      </mat-tab> -->
    </mat-tab-group>
  </div>
</section>
<!-- <div *ngIf="IsAssign">
        effwef
    </div> -->