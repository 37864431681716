import { AfterViewInit, Component, OnInit , ViewChild , ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormGroup , Validators } from '@angular/forms';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { CashflowService } from 'src/app/services/cashflow.service';

@Component({
  selector: 'app-manage-hobnob-orders',
  templateUrl: './manage-hobnob-orders.component.html',
  styleUrl: './manage-hobnob-orders.component.scss'
})
export class ManageHobnobOrdersComponent implements OnInit , AfterViewInit , AfterViewChecked{
  ordersForm !:FormGroup;
  allOrders: any[] =[];
  ordersByPatner : any[] =[];
  ordersByHospital : any[] =[];
  activeHospitals: any[]=[];
  hospitalHeading = 'Hospital Name';
  userList:any[]=[];
  dataSource = new MatTableDataSource();
  displayedColumns =['transactionDate','couponCode','discountPercent','orderSource','amount', 'productName', 'memberName', 'hospitalName','processStatus' ,'transactionStatus','agentName']
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private toastrService:ToastrService,
    private cashflowService: CashflowService,
    private cdr: ChangeDetectorRef
  ) {
    this.ordersForm = this.formBuilder.group({
      fromDate: [new Date(), Validators.required],
      toDate : [new Date(), Validators.required],
      hasCoupon : [''],
      partnerType: [''],
      partnerId: [''],
      orgId: [''],

    });
    console.log(this.dataSource.paginator);

   }
  ngOnInit(): void {
    this.getAllOrders(this.ordersForm.value.fromDate, this.ordersForm.value.toDate, this.ordersForm.value.hasCoupon);
  }

  ngAfterViewInit(): void {
    
  }
  ngAfterViewChecked(): void {
    if (this.paginator && this.dataSource.paginator !== this.paginator) {
      this.dataSource.paginator = this.paginator;
     // console.log('Paginator Assigned:', this.paginator);
    }
  }

  onFromDateChange(event:any){
    console.log(event.value);
    const fromDate = new Date(event.value);
    const toDate = new Date(this.ordersForm.value.toDate);
    if(fromDate > toDate){
      this.toastrService.error("From Date can not be more than to date");
    }else{
        this.ordersForm.get('hasCoupon')?.reset();
        this.ordersForm.get('partnerType')?.reset();
        this.ordersForm.get('partnerId')?.reset();
        this.ordersForm.get('orgId')?.reset();
      this.getAllOrders(fromDate, toDate, '');
    }

  }

  onToDateChange(event:any){
    console.log(event.value);
    const toDate = event.value;
    const fromDate = new Date(this.ordersForm.value.fromDate);
    if(toDate < fromDate){
      this.toastrService.error("To Date can not be less than from date");
    }else{
      this.ordersForm.get('hasCoupon')?.reset();
      this.ordersForm.get('partnerType')?.reset();
      this.ordersForm.get('partnerId')?.reset();
      this.ordersForm.get('orgId')?.reset();
      this.getAllOrders( fromDate,toDate, '');
    }
  }

  onSelectionChange(event:any){
    console.log(event);
    const coupon = event;
    if(coupon == 'yes'){
      this.getAllOrders(this.ordersForm.value.fromDate,this.ordersForm.value.toDate,coupon);
    }else{
      this.getAllOrders(this.ordersForm.value.fromDate,this.ordersForm.value.toDate,'');
    }
   

  }

  onPartnerTypeChange(event:any){
    console.log(event);
    const value = event;
    this.ordersForm.get('hasCoupon')?.setValue('yes');
    this.ordersForm.get('hasCoupon')?.disable();
    if(value == 'agent'){
      this.ordersForm.get('orgId')?.disable();
      this.ordersForm.get('partnerId')?.enable();
      this.getAllPartnerOrders(this.ordersForm.value.fromDate, this.ordersForm.value.toDate,'agent','');
      this.getUsersByRole(["HOBNOB_AGENT"]);
    

    }else if(value == 'doctor'){
      this.ordersForm.get('orgId')?.disable();
      this.ordersForm.get('partnerId')?.enable();
      this.getAllPartnerOrders(this.ordersForm.value.fromDate, this.ordersForm.value.toDate,'doctor','');
      this.getUsersByRole(["DOCTOR"]);

    }else if(value == 'hospital'){
      this.getActiveHospitals();
      this.ordersForm.get('partnerId')?.disable();
      this.ordersForm.get('orgId')?.enable();
     // this.getAllHospitalOrders(this.ordersForm.value.fromDate,this.ordersForm.value.toDate,this.ordersForm.value.orgId);
    }
  }

  getActiveHospitals(){
    this.cashflowService.getActiveHospitals()
    .subscribe({
      next:(data:any)=>{
        console.log(data);
        this.activeHospitals = data;
      },
      error:(error:any)=>{
      console.log(error);
      }
    })
  }
  onHospitalSelected(event:any){
    console.log("hello");
    const orgId = event;
    console.log(event);
    this.getAllHospitalOrders(this.ordersForm.value.fromDate,this.ordersForm.value.toDate,orgId);
  }

  onUserSelected(event:any){
    const userId = event;
    this.getAllPartnerOrders(this.ordersForm.value.fromDate, this.ordersForm.value.toDate, this.ordersForm.value.partnerType, userId)
  }

  getUsersByRole(roles:any){
    this.adminService.getUsersByRole(roles).subscribe({
      next: (val: any) => {
       console.log(val);
       this.userList = val;
      },
      error: (err: any) => {
        console.error(err);
      },
    });
  }

  getAllOrders(fromDate:any,toDate:any,hasCoupon:any){
    this.adminService.getAllOrdersByDate(fromDate.toISOString(), toDate.toISOString(), hasCoupon)
    .subscribe({
      next:(data:any)=>{
        console.log(data);
        this.dataSource.data = data;
        const hasHospitalName = this.dataSource.data.some(
          (item:any) => item.couponOrg && item.couponOrg.name
        );
        this.hospitalHeading = hasHospitalName ? 'Hospital Name / BG' : 'Agent Name';
      },
      error:(error:any)=>{
        console.log(error);
      }
    })

  }

  getAllPartnerOrders(fromDate:any, toDate:any,partnerType:any,partnerId:any){
    this.adminService.getOrdersByPartner(
      fromDate.toISOString(),toDate.toISOString(),partnerType, partnerId)
      .subscribe({
        next:(data:any)=>{
          console.log(data);
          this.dataSource.data = data;
          const hasHospitalName = this.dataSource.data.some(
            (item:any) => item.couponOrg && item.couponOrg.name
          );
          this.hospitalHeading = hasHospitalName ? 'Hospital Name / BG' : 'Agent Name';
        },
        error:(error:any)=>{
          console.log(error);
        }
      })
  }

  getAllHospitalOrders(fromDate:any, toDate:any,orgId:any){
    this.adminService.getOrdersByHospital(fromDate.toISOString(),toDate.toISOString(),orgId)
    .subscribe({
      next:(data:any)=>{
        console.log(data);
        this.dataSource.data = data;
        const hasHospitalName = this.dataSource.data.some(
          (item:any) => item.couponOrg && item.couponOrg.name
        );
      },
      error:(error:any)=>{
        console.log(error);
      }
    })
  }



}
