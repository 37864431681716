import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs";
import { User } from "src/app/model/user";
import { AdminService } from "src/app/services/admin.service";
import { MatDialog } from "@angular/material/dialog";
import { EditUserComponent } from "./edit-user/edit-user.component";
import { AuthService } from "src/app/services/authService";
import { AddUserComponent } from "./add-user/add-user.component";
import { AddUserRoleComponent } from "./add-user-role/add-user-role.component";
import { ManageUserRoleComponent } from "./manage-user-role/manage-user-role.component";
import { PrivilegesComponent } from "./privileges/privileges.component";
import { ManageReporteeComponent } from "./manage-reportee/manage-reportee.component";
import { ManageUserOrgComponent } from "./manage-user-org/manage-user-org.component";
import { SearchService } from "src/app/services/search.service";
@Component({
  selector: "app-manage-user",
  templateUrl: "./manage-user.component.html",
  styleUrls: ["./manage-user.component.scss"],
})
export class ManageUserComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "FirstName",
    "LastName",
    "User Type",
    "EMail",
    "City",
    "Mobile",
    "action",
    "managerole",
    "checkPrivileges",
    "manageReportee",
    "manageOrg",
  ];

  rowNum: number = 0;
  size: number = 10;
  loading: boolean = false;
  message: any;
  users: any = [];
  user: any;
  form!: FormGroup;
  length = 100;
  pageSize = 200;
  pageIndex = 0;
  pageSizeOptions = [50, 100, 200];
  roles: any;
  priveleges: any;
  filteredArr: any;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  main: boolean = true;
  sub: boolean = false;

  pageEvent: PageEvent = new PageEvent();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private adminService: AdminService,
    private _dialog: MatDialog,
    private searchService:SearchService,
  ) {
    this.authService.user.subscribe((x) => (this.user = x));
  }
  dataSource = new MatTableDataSource<User>(this.users);
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  ngAfterViewInit(): void {}
  ngOnInit(): void {
    this.isPrivileged(this.user.privileges);
    this.form = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      emailId: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      addLine1: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      website: ["", Validators.required],
      country: ["", Validators.required],
      zipcode: ["", [Validators.required, Validators.minLength(6)]],
      inactive: ["", Validators.required],
      mobileNumber: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      landline: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      landingPage:"home"
    });

    console.log("User priviledges:" + JSON.stringify(this.user.roles));
    if (this.isRoles(["SUPERADMIN", "ADMIN"])) {
      this.getUsers();
    } else {
      this.getUsersByManager();
    }
  }

  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;
    var dd = roles.some((x: string) => role.includes(x));
    //console.log("roles "+ this.user.roles);
    return dd;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    if (this.isRoles(["SUPERADMIN", "ADMIN"])) {
      this.getUsers();
    } else {
      this.getUsersByManager();
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(",")
        .map((str) => +str);
    }
  }

  getUsers() {
    this.adminService
      .getOrgUsers(this.pageIndex * this.pageSize, this.pageSize)
      .pipe(first())
      .subscribe({
        next: async (data?: any) => {
          this.loading = false;
          this.users = await data;
          this.dataSource.data = this.users;
          this.dataSource.paginator = this.paginator;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          this.loading = false;
        },
      });
  }
  getUsersByManager() {
    console.log("manager id:" + JSON.stringify(this.user.id));
    //console.log("page Index " + (this.pageIndex * this.pageSize) + " Page Size " + this.pageSize);
    this.adminService
      .getUsersByManager(this.pageIndex * this.pageSize, this.pageSize)
      .pipe(first())
      .subscribe({
        next: async (data?: any) => {
          this.loading = false;
          //console.log(data);
          this.users = await data;
          this.dataSource.data = this.users;
          this.dataSource.paginator = this.paginator;
          console.log("Get Users by Manager Array:" + JSON.stringify(data));
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          this.loading = false;
        },
      });
  }

  getUserRoles(data: any) {
    //console.log("selected user id",data);
    const dialogRef = this._dialog.open(ManageUserRoleComponent, {
      disableClose:true,
      data,
      width: "90%",
      height: "70%",
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          if (this.isRoles(["SUPERADMIN", "ADMIN"])) {
            this.getUsers();
          } else {
            this.getUsersByManager();
          }
        }
      },
    });
  }

  handleInput(event: any) {
    const query = event.target.value.toLowerCase();
    if (query !== "") {
      this.dataSource.data = this.users.filter((item: any) => {
        return Object.values(item).some((val) =>
          String(val).toLowerCase().includes(query)
        );
      });
    } else {
      if (this.isRoles(["SUPERADMIN", "ADMIN"])) {
        this.getUsers();
      } else {
        this.getUsersByManager();
      }
    }
  }

  openEditForm(data: any) {
    const dialogRef = this._dialog.open(EditUserComponent, {
      disableClose:true,
      data,
      width: "90%",
      height: "70%",
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          if (this.isRoles(["SUPERADMIN", "ADMIN"])) {
            this.getUsers();
          } else {
            this.getUsersByManager();
          }
        }
      },
    });
  }

  openAddForm() {
    const dialogRef = this._dialog.open(AddUserComponent, {
      disableClose:true,
      width: "90%",
      height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          if (this.isRoles(["SUPERADMIN", "ADMIN"])) {
            this.getUsers();
          } else {
            this.getUsersByManager();
          }
        }
      },
    });
  }

  // openAddForm(data?:any) {
  //   const dialogRef = this._dialog.open(AddUserComponent,{
  //     data,
  //   });
  //   dialogRef.afterClosed().subscribe({
  //     next: (val) => {
  //       if (val) {
  //         this.getUsers();
  //       }
  //     },
  //   });
  // }

  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    // console.log("is "+ this.user);

    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privilegedvish " + this.user.privileges);
    return dd;
  }
  /*data:any = {
    "id":"0f3dfc2c-3056-4dfa-9db4-8e36bcc5eedf",
    "emailId":"vk@gmail.com",
    "firstName":"xyz",
    "lastName":"xyz",
    "userName":"vk@gmail.com",
    "passwd":"##########",
    "mobilePhone":"8876567565",
    "landline":null,
    "userType":"HOSPITAL_ADMIN",
    "color":null,
    "address":"abc",
    "city":"abc",
    "state":"abc",
    "country":"India",
    "zip":"401209",
    "inactive":true,
    "orgId":"7ab5a203-6491-4d7d-8394-70423729f6a9"
  }*/

  // openAddRolesForm() {
  //   // console.log("dta",data)

  //   const dialogRef = this._dialog.open(AddUserRoleComponent);
  //   dialogRef.afterClosed().subscribe({
  //     next: (val) => {
  //       if (val) {
  //         this.getUsers();
  //       }
  //     },
  //   });
  // }

  openCheckPrivileges(data: any) {
    const dialogRef = this._dialog.open(PrivilegesComponent, {
      disableClose:true,
      data,
      width: "90%",
      height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          if (this.isRoles(["SUPERADMIN", "ADMIN"])) {
            this.getUsers();
          } else {
            this.getUsersByManager();
          }
        }
      },
    });
  }

  openManageReportee(data: any) {
    const dialogRef = this._dialog.open(ManageReporteeComponent, {
      disableClose:true,
      data,
      width: "90%",
      height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          if (this.isRoles(["SUPERADMIN", "ADMIN"])) {
            this.getUsers();
          } else {
            this.getUsersByManager();
          }
        }
      },
    });
  }

  openManageUserOrg(data: any) {
    const dialogRef = this._dialog.open(ManageUserOrgComponent, {
      disableClose:true,
      data,
      width: "90%",
      height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          if (this.isRoles(["SUPERADMIN", "ADMIN"])) {
            this.getUsers();
          } else {
            this.getUsersByManager();
          }
        }
      },
    });
  }

  searchUsers(searchTerm:any) {
    this.searchService
      .searchUser(searchTerm)
      .pipe(first())
      .subscribe({
        next: async (data?: any) => {
          this.loading = false;
          this.users = await data;
          this.dataSource.data = data;
        },
        error: (error) => {
          console.log("error " + JSON.stringify(error));
          this.message = error;

          this.loading = false;
        },
      });
  }
}
