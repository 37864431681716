import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-add-ekyp-details',
  templateUrl: './add-ekyp-details.component.html',
  styleUrl: './add-ekyp-details.component.scss'
})
export class AddEkypDetailsComponent implements OnInit {
  policyForm !:FormGroup;
  policyMembers!: FormGroup;
  waitingPeriod!: FormGroup;
  sublimitInformation!: FormGroup;
  coverageInformation !:FormGroup;
  pdfUrl:any;
  imgData:any;
  constructor(
    public dialogRef: MatDialogRef<AddEkypDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private datePipe: DatePipe,
  
  ){

    this.policyMembers = this.formBuilder.group({
      members: this.formBuilder.array([this.createPolicyMemberGroup()]),
    });

    this.waitingPeriod = this.formBuilder.group({
      waitingPeriodMemberList: this.formBuilder.array([this.createWaitingPeriodGroup()]),
    });

    this.sublimitInformation = this.formBuilder.group({
      sublimitMemberInformation : this.formBuilder.array([this.createSublimitInformationGroup()])
    });

    this.coverageInformation = this.formBuilder.group({
      coverageInformationMemberList : this.formBuilder.array([this.createCoverageInformationGroup()])
    });

    this.policyForm = this.formBuilder.group({
      startDate: ['',Validators.required],
      endDate : ['',Validators.required],
      policyNumber :['',Validators.required],
      policyType :['',Validators.required],
      insuranceCompany : ['',Validators.required],
      insuranceName :['',Validators.required],
      ported :['',Validators.required]
    })

    console.log(data);
  }

  ngOnInit(): void {
    this.getPolicyFile(this.data.id);
  }

  async getPolicyFile(policyId:string){
    await this.customerService
      .getPolicyFile(policyId)
      .subscribe({
        next: (data: Uint8Array) => {

          let file = new Blob([data], { type: "application/pdf" });
          this.pdfUrl = URL.createObjectURL(file);
        },
        error: async (err: any) => {
          console.log("error");
          console.log(err);
        },
      });
  }


  get members(): FormArray {
    return this.policyMembers.get('members') as FormArray;
  }

  get waitingPeriodMemberList(): FormArray {
    return this.waitingPeriod.get('waitingPeriodMemberList') as FormArray;
  }

  get sublimitMemberInformation(): FormArray {
    return this.sublimitInformation.get('sublimitMemberInformation') as FormArray;
  }

  get coverageInformationMemberList(): FormArray {
    return this.coverageInformation.get('coverageInformationMemberList') as FormArray;
  }

  createPolicyMemberGroup(){
    return this.formBuilder.group({
      memberName:['',Validators.required],
      memberPhoneNumber : ['',Validators.required],
      isHolder:['',Validators.required]
    })
  }


  createWaitingPeriodGroup(){
    return this.formBuilder.group({
      memberName:['',Validators.required],
      preHospitalisation : ['',Validators.required],
      postHospitalisation:['',Validators.required],
      waitingPeriod:['',Validators.required]
    })
  }

  createSublimitInformationGroup(){
    return this.formBuilder.group({
      insuredName:['',Validators.required],
      roomRent:['',Validators.required],
      icuRent:['',Validators.required]
    })
  }

  createCoverageInformationGroup(){
    return this.formBuilder.group({
      insuredName :['',Validators.required],
      sumInsured :['',Validators.required],
      superBonus: ['',Validators.required],
      restore : ['',Validators.required],
      effectiveCoverage : ['',Validators.required],
      dob : ['',Validators.required]

    })
  }

  addMember(){
    this.members.push(this.createPolicyMemberGroup());
  }

  addWaitingPeriodMemberList(){
   // console.log(this.waitingPeriod.value.waitingPeriodMemberList);
    this.waitingPeriodMemberList.push(this.createWaitingPeriodGroup());
  }

  addSublimitInformation(){
    this.sublimitMemberInformation.push(this.createSublimitInformationGroup());
  }

  addCoverageInformation(){
    this.coverageInformationMemberList.push(this.createCoverageInformationGroup());
  }

  policy:any;
  onFormSubmit(){

    if(this.policyForm.invalid || this.policyMembers.invalid || this.waitingPeriod.invalid || this.sublimitInformation.invalid || this.coverageInformation.invalid){
      this.toastrService.error("Please fill in the required fields!")
    }else{
      const memeberList = this.coverageInformation.value.coverageInformationMemberList;
      const updatedMemberList = memeberList.map((member:any)=>({
        ...member, 
        dob: this.datePipe.transform(member.dob,'MMM dd yyyy')
      }))
      this.policy = {

        "id":this.data.id,
        user_id: this.data.userId,
        "policyPath":this.data.policyPath ,
        "processed":true,
        "ekypData": {
          "start_date": this.datePipe.transform(this.policyForm.value.startDate, 'MMM dd yyyy'),
          "end_date":this.datePipe.transform(this.policyForm.value.endDate , 'MMM dd yyyy'),
          "insurance_company": this.policyForm.value.insuranceCompany,
          "insurance_name": this.policyForm.value.insuranceName,
          "ported": this.policyForm.value.ported,
          "policy_type": this.policyForm.value.policyType,
          "policy_number": this.policyForm.value.policyNumber,
          "policy_members":this.policyMembers.value.members,
          "waiting_period_information":
            this.waitingPeriod.value.waitingPeriodMemberList
          ,
          "coverage_information":updatedMemberList,
          "sublimit_information":this.sublimitInformation.value.sublimitMemberInformation,
          "benefits":{
  
          }
  
        }
       
      }
      console.log(this.policy);
      this.customerService.addkyp(this.policy).subscribe({
        next: (val: any) => {
          console.log("Pplicy returned :"+JSON.stringify(val));
          this.toastrService.success("kyp has been added successfully");
          this.close(val);
        },
        error: (err: any) => {
          console.error(err);
          this.toastrService.error(JSON.stringify(err.error.error));
  
  
        },
      });
      
    }
  }

  close(data:any){
    this.dialogRef.close(data);
  }
  


}
