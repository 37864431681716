import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';
import { HospitalRegistrationService } from 'src/app/services/hospitalregistration.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-recipients',
  templateUrl: './add-recipients.component.html',
  styleUrl: './add-recipients.component.scss'
})
export class AddRecipientsComponent implements OnInit {
  selectedFiles: any;
  formData = new FormData();
  constructor(
    public MatDialogRef: MatDialogRef<AddRecipientsComponent>,
    private hospitalRegistrationService: HospitalRegistrationService,
    private toastrService: ToastrService
  ) {

  }

  ngOnInit(): void {

  }

  getRecipientTemplate() {
    this.hospitalRegistrationService.getTemplate().subscribe({
      next: (response: Blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const text = reader.result as string;
            console.log('Blob Content:', text);
            
           
            const headers = text.split(',');
            
          
           const csvContent = headers.join(',') + '\t';
            
          
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            
         
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', 'RecipientTemplate.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
          } catch (error) {
            console.error('Error processing the text:', error);
          }
        };
    
        reader.readAsText(response);
      },
      error: (error) => {
        console.error('Error fetching the template:', error);
      }
    });
    


  }

  selectFile(event: any) {
    this.selectedFiles = event.target.files[0];
    this.formData.append('file', this.selectedFiles);

  }

  uploadFile() {
    this.hospitalRegistrationService.addRecipient(this.formData)
    .subscribe({
      next:(data:any)=>{
     this.toastrService.success("Data added successfully");
     this.selectedFiles ='';
      },
      error:(error) => {
       this.toastrService.error(error);
      }
    })
  }

  exportexcel() {
    //   let element = document.getElementById('excel-table');
    //   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //   XLSX.writeFile(wb, this.fileName);
    // }
  }

  close() {
    this.MatDialogRef.close("");
  }


}
