<div class="container p-3">
  <div class="searchFields">
    <!-- <div class="row text-end">
      <div class="col">
        <button mat-flat-button style="color: white; background: #8e0101" (click)="addKYP()">
          Add KYP
        </button>
      </div>
    </div> -->
    <div class="row ">
      <div class="col-5">
        <input type="search" [(ngModel)]="policyNumber" class="form-control" placeholder="Enter policy number" aria-label="Search" />

      </div>
      <div class="col-5">
        <input type="search" [(ngModel)]="memberName" class="form-control" placeholder="Enter member name" aria-label="Search" />

      </div>
      <div class="col-2">
        <button mat-flat-button style="color: white; background: #8e0101" (click)="getDetailsByPolicyNumber(itemsFetched,batchSize)">
          Search
        </button>
      </div>

    </div>
  </div>
  <div class="container py-3 px-2">
    <div class="card px-3 py-4 justify-content-md-center">
      <div class="p-4">
        <mat-table  #table [dataSource]="dataSource" class="custom-table">
          <ng-container matColumnDef="policyNumber" >
            <mat-header-cell *matHeaderCellDef> Policy Number </mat-header-cell>
            <mat-cell *matCellDef="let element" >
              {{ element.policyNumber }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="startDate">
            <mat-header-cell *matHeaderCellDef>
              Start Date
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.startDate |date}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef>
              End Date</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.endDate  |date}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sumInsured">
            <mat-header-cell *matHeaderCellDef> Sum Insured </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.sumInsured }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="policyType">
            <mat-header-cell *matHeaderCellDef> Policy Type </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.policyType }}
            </mat-cell>
          </ng-container>

           <ng-container matColumnDef="policyMember">
            <mat-header-cell *matHeaderCellDef> Policy Member </mat-header-cell>
             <mat-cell *matCellDef="let element">
              <button style="color: white; background: #8e0101" mat-flat-button  (click)="viewMembers(element.policyMembers)">
                View Members
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ekyp">
            <mat-header-cell *matHeaderCellDef> Ekyp </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button style="color: white; background: #8e0101" mat-flat-button (click)="viewEkypData(element.ekypData)">
                View
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator  [length]="totalRecords"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20, 100]"
        aria-label="Select page"
        (page)="onPaginateChange($event)"></mat-paginator>
      </div>
    </div>
  </div>
</div>